import { useState } from "react";
import WorkOrdersTable from "../../updatedcomponents/tables/WorkOrdersTable";
import NewWorkOrder from "../../updatedcomponents/forms/WorkOrderForm";
import WorkOrderListPrint from "../../updatedcomponents/modals/WorkOrderListPrint";
import Pagination from "../../updatedcomponents/table-components/Pagination";
import PageHeader from "../../updatedcomponents/general-ui/PageHeader";
import FilterModal from "../../updatedcomponents/table-components/FilterModal";
import Button from "../../updatedcomponents/general/Button";
import FilterButton from "../../updatedcomponents/table-components/FilterButton";
import { useWorkOrder } from "../../hooks/useWorkOrder";
import { Kiosk } from "../../types/Kiosk";
import { User } from "../../types/User";
import Modal from "../../updatedcomponents/general-ui/Modal";
import { useAuth } from "../../hooks/useAuth";

const WorkOrders = () => {
  const {
    totalPages,
    page,
    pageSize,
    handlePageSizeChange,
    handlePageChange,
    filters,
    applyFilters,
    resetFilters,
    users,
    kiosks,
    modalShowState,
    updateModalShowState,
  } = useWorkOrder();
  const { currentUser } = useAuth();

  const filterOptions = [
    { name: "title", label: "Title", type: "text" },
    { name: "details", label: "Details", type: "text" },
    { name: "id", label: "ID", type: "text" },
    {
      name: "status",
      label: "Status",
      type: "selector",
      options: [
        { value: "", label: "All Statuses" },
        { value: "incomplete", label: "Incomplete" },
        { value: "unable", label: "Unable to complete" },
        { value: "complete", label: "Complete" },
      ],
    },
    { name: "requestedBy", label: "Requested By", type: "text" },
    {
      name: "requestOrigin",
      label: "Request Origin",
      type: "selector",
      options: [
        { value: "", label: "All Origins" },
        ...(kiosks && kiosks.length > 0
          ? kiosks.map((kiosk: Kiosk) => ({
              value: kiosk.location,
              label: kiosk.location,
            }))
          : []),
        { value: "user-login", label: "User-login" },
      ],
    },
    {
      name: "requestType",
      label: "Request Type",
      type: "selector",
      options: [
        { value: "", label: "All Types" },
        { value: "Electrical", label: "Electrical" },
        { value: "Plumbing", label: "Plumbing" },
        { value: "HVAC", label: "HVAC" },
        { value: "not-specified", label: "Not Specified" },
        { value: "Other", label: "Other" },
      ],
    },
    {
      name: "urgency",
      label: "Urgency",
      type: "selector",
      options: [
        { value: "", label: "All Urgencies" },
        { value: "standard", label: "Standard" },
        { value: "urgent", label: "Urgent" },
      ],
    },
  ];

  if (currentUser.access !== "worker") {
    filterOptions.push({
      name: "assignedTo",
      label: "Assigned To",
      type: "selector",
      options: [
        { value: "", label: "All Assignments" },
        { value: "unassigned", label: "Unassigned" },
        ...(users && users.length > 0
          ? users.map((user: User) => ({
              value: user._id,
              label: `${user.firstname} ${user.lastname}`,
            }))
          : []),
      ],
    });
  }

  return (
    <>
      <PageHeader
        title={"Work Orders"}
        subtitle={"Work orders that have not been closed out yet."}
        buttons={
          <div className="flex flex-col sm:flex-row w-full h-fit justify-evenly sm:gap-4 gap-2">
            <Button
              styleString="secondary"
              onClick={() => updateModalShowState("showPrintWorkOrders", true)}
              icon="PrintOutlined"
              children="Work Orders"
              overrideStyles="w-full"
            />
            <Button
              styleString="primary"
              onClick={() => updateModalShowState("showNewWorkOrder", true)}
              icon={"Add"}
              children="Work Order"
              overrideStyles="w-full"
            />
          </div>
        }
      />
      <div className="px-4 sm:px-6 sm:pb-2 ">
        <FilterButton
          setShowFilterModal={() =>
            updateModalShowState(
              "showFilterModal",
              !modalShowState.showFilterModal
            )
          }
        />

        <FilterModal
          show={modalShowState.showFilterModal}
          setShow={() =>
            updateModalShowState(
              "showFilterModal",
              !modalShowState.showFilterModal
            )
          }
          filterOptions={filterOptions}
          filters={filters}
          applyFilters={applyFilters}
          resetFilters={resetFilters}
        />

        <WorkOrdersTable />
        <Pagination
          itemsToShow={pageSize}
          handlePageSizeChange={handlePageSizeChange}
          currentPage={page}
          setCurrentPage={handlePageChange}
          totalPages={totalPages}
        />
      </div>
      <Modal
        open={modalShowState.showNewWorkOrder}
        setOpen={() => updateModalShowState("showNewWorkOrder", false)}
        title="New Work Order"
        subtitle="Get started by filling in the information below to create the new
            work order."
        content={
          <NewWorkOrder
            setShowPopup={() => updateModalShowState("showNewWorkOrder", false)}
          />
        }
      />
      <WorkOrderListPrint
        showPrintWorkOrders={modalShowState.showPrintWorkOrders}
        setShowWorkOrderPrint={() =>
          updateModalShowState("showPrintWorkOrders", false)
        }
      />
    </>
  );
};

export default WorkOrders;
