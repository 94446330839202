import { useState, FC } from "react";
import ToDoTable from "../../updatedcomponents/tables/ToDoTable";
import PageHeader from "../../updatedcomponents/general-ui/PageHeader";
import FilterModal from "../../updatedcomponents/table-components/FilterModal";
import Pagination from "../../updatedcomponents/table-components/Pagination";
import { useRecurring } from "../../hooks/useRecurring";
import FilterButton from "../../updatedcomponents/table-components/FilterButton";

interface FilterOption {
  label: string;
  type: string;
  name: string;
  options?: { label: string; value: string }[];
}

interface Response {
  show: boolean;
  title: string;
  body: string;
  icon: React.ReactNode | null;
}

const Tasks: FC = () => {
  const {
    recurringTasks,
    users,
    totalPages,
    showState: { showFilter },
    updateShowState,
    handleResetFilters,
    applyFilters,
    handlePageSizeChange,
    handlePageChange,
    handleLogInstanceView,
    filters,
    view,
    currentPage,
    tasksPerPage,
  } = useRecurring();

  const filterOptions: FilterOption[] = [
    {
      label: "Title",
      type: "text",
      name: "title",
    },
    {
      label: "Start Date",
      type: "date",
      name: "dueDateFrom",
    },
    {
      label: "End Date",
      type: "date",
      name: "dueDateTo",
    },
    {
      label: "Status",
      type: "selector",
      options: [
        { label: "All", value: "" },
        { label: "Incomplete", value: "incomplete" },
        { label: "In Progress", value: "in progress" },
        { label: "Overdue", value: "overdue" },
        { label: "Complete", value: "complete" },
      ],
      name: "status",
    },
    {
      label: "Task Type",
      type: "selector",
      options: [
        { label: "All", value: "" },
        { label: "Preventative Maintenance", value: "prev-maint" },
        { label: "Life Safety", value: "life-safety" },
      ],
      name: "taskType",
    },
    {
      label: "Task Frequency",
      type: "selector",
      options: [
        { label: "All", value: "" },
        { label: "Daily", value: "daily" },
        { label: "Weekly", value: "weekly" },
        { label: "Bi-weekly", value: "bi-weekly" },
        { label: "Monthly", value: "monthly" },
        { label: "Quarterly", value: "quarterly" },
        { label: "Semi-annually", value: "semi-annually" },
        { label: "Annually", value: "annually" },
        { label: "2 Year", value: "biennially" },
        { label: "3 Year", value: "3-year" },
        { label: "4 Year", value: "4-year" },
        { label: "5 Year", value: "5-year" },
      ],
      name: "frequency",
    },
    {
      label: "Ready to Complete",
      type: "checkbox",
      name: "readyToComplete",
    },
  ];

  /*   const viewButtons = () => {
    return (
      <div className="isolate inline-flex rounded-sm shadow-sm my-2 bg-secondary-1100 text-secondary-100 text-xs font-medium cursor-pointer">
        <button
          type="button"
          className={classNames(
            view === "list" ? "bg-primary my-0.5 mx-0.5 shadow" : "",
            "relative inline-flex items-center rounded-l-sm px-2 py-2"
          )}
          onClick={() => {
            setView("list");
          }}
        >
          <FormatListBulleted style={{ fontSize: "1rem" }} aria-hidden="true" />
          <label className="mx-2 cursor-pointer">List View</label>
        </button>
        <button
          type="button"
          className={classNames(
            view === "calendar" ? "bg-primary my-0.5 mx-0.5 shadow" : "",
            "relative inline-flex items-center rounded-r-sm px-2 py-2"
          )}
          onClick={() => {
            setView("calendar");
          }}
        >
          <CalendarTodayOutlined
            style={{ fontSize: "1rem" }}
            aria-hidden="true"
          />
          <label className="mx-2 cursor-pointer">Calendar View</label>
        </button>
      </div>
    );
  };
 */

  const [response, setResponse] = useState<Response>({
    show: false,
    title: "",
    body: "",
    icon: null,
  });

  return (
    <>
      <>
        <PageHeader
          title={"Recurring Tasks"}
          subtitle={"All tasks waiting to be closed out"}
        />
        {view === "list" ? (
          <div className=" px-4 sm:px-6 sm:pb-2 ">
            <FilterButton
              setShowFilterModal={() => updateShowState("showFilter", true)}
            />

            <FilterModal
              filters={JSON.stringify(filters)}
              filterOptions={filterOptions}
              show={showFilter}
              setShow={() => updateShowState("showFilter", false)}
              applyFilters={applyFilters}
              resetFilters={handleResetFilters}
            />
            {/* {viewButtons()} */}

            <ToDoTable
              data={recurringTasks}
              users={users}
              handleLogInstanceView={handleLogInstanceView}
            />
            <Pagination
              setCurrentPage={handlePageChange}
              totalPages={totalPages}
              currentPage={currentPage}
              itemsToShow={tasksPerPage}
              handlePageSizeChange={handlePageSizeChange}
            />
          </div>
        ) : (
          <></>
          /* <ToDoCalendar
            data={recurringTasks}
            dateParams={dateParams}
            setDateParams={setDateParams}
            /> */
        )}
      </>
    </>
  );
};

export default Tasks;
