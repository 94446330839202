import React, { useState, FormEvent, useEffect } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import {
  demoRequestSubmit,
  requestDoc,
} from "../../../api/services/LandingPageService";

interface FormData {
  firstName: string;
  lastName: string;
  company: string;
  email: string;
  phone: string;
  message: string;
  privacyPolicy: boolean;
}

const ContactSection: React.FC = () => {
  const requestSchema = yup.object().shape({
    firstName: yup.string().required("First name is required"),
    lastName: yup.string().required("Last name is required"),
    company: yup.string().required("Company is required"),
    email: yup.string().email().required("Email is required"),
    phone: yup.string().optional(),
    message: yup.string().required("Message is required"),
    termsAccepted: yup.boolean().oneOf([true], "You must accept the terms"),
  });

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting, isSubmitSuccessful },
    reset,
  } = useForm({
    resolver: yupResolver(requestSchema),
    defaultValues: {
      termsAccepted: false,
    },
  });

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset();
    }
  }, [isSubmitSuccessful, reset]);

  return (
    <div id="contact-form" className="contact-section">
      <div className="container">
        <div className="inner-container">
          <div className="section-heading fadeInUp">
            <h2>
              Request <strong>a Demo</strong>
            </h2>
            <p>
              Please enter your information below and we will reach out to you
              to schedule a demo.{" "}
            </p>
          </div>
          <form
            onSubmit={handleSubmit(demoRequestSubmit)}
            className="contact-form row fadeInUp"
          >
            <div className="col-md-6 form-group">
              <label>
                First Name <span className="required">*</span>
              </label>
              <input
                className="form-control"
                type="text"
                placeholder="Peter"
                {...register("firstName")}
                required
              />
            </div>
            <div className="col-md-6 form-group">
              <label>
                Last Name <span className="required">*</span>
              </label>
              <input
                className="form-control"
                type="text"
                placeholder="Lukas"
                {...register("lastName")}
                required
              />
            </div>
            <div className="col-md-12 form-group">
              <label>
                Company <span className="required">*</span>
              </label>
              <input
                className="form-control"
                type="text"
                placeholder="Sequra"
                {...register("company")}
                required
              />
            </div>
            <div className="col-md-12 form-group">
              <label>
                Email <span className="required">*</span>
              </label>
              <input
                className="form-control"
                type="email"
                placeholder="info@sequra.com"
                {...register("email")}
                required
              />
            </div>
            <div className="col-md-12 form-group">
              <label>
                Phone Number <span className="required">*</span>
              </label>
              <input
                className="form-control"
                type="tel"
                placeholder="+345 987 4567"
                {...register("phone")}
                required
              />
            </div>
            <div className="col-md-12 form-group">
              <label>
                Message<span className="required">*</span>
              </label>
              <textarea
                className="form-control"
                placeholder="I want to know more about Sequra..."
                {...register("message")}
                required
              ></textarea>
            </div>
            <div className="col-md form-group form-group-check">
              <label>
                <input
                  className="form-check-input"
                  type="checkbox"
                  {...register("termsAccepted")}
                  required
                />
                <div>
                  By selecting this, you agree to our{" "}
                  <a
                    onClick={() =>
                      requestDoc(
                        "https://sequraadmindocs.s3.us-east-1.amazonaws.com/Sequra+Privacy+Policy.pdf"
                      )
                    }
                  >
                    <u>Privacy Policy</u>
                  </a>
                </div>
              </label>
            </div>
            <div className="col-md-auto form-group">
              <input
                className="btn btn-gradient"
                type="submit"
                value="Let's Talk"
              />
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ContactSection;
