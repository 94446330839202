import React, {
  createContext,
  useState,
  ReactNode,
  useEffect,
  useMemo,
} from "react";
import {
  SetURLSearchParams,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { RecurringTask } from "../types/RecurringTask";
import { WorkOrder } from "../types/WorkOrder";
import { TaskInstance } from "../types/TaskInstance";
import { useFacility } from "../hooks/useFacility";
import {
  fetchAttachments,
  fetchLogInstance,
  fetchLogs,
  fetchWorkOrderLogs,
  printLog,
} from "../api/services/LogService";
import { updateSearchParams } from "../utils/ParamUtils";

export type TabType = "work-orders" | "life-safety" | "prev-maint";

interface LogContextType {
  selectedTab: TabType;
  filters: Record<string, any>;
  page: string;
  pageSize: string;
  totalPages: number;
  isLoading: boolean;
  showFilter: boolean;
  setShowFilter: (show: boolean) => void;
  setTotalPages: (pages: number) => void;
  updateTab: (tab: TabType) => void;
  applyFilters: (filters: Record<string, any>) => void;
  resetFilters: () => void;
  handlePageChange: (newPage: number) => void;
  handlePageSizeChange: ({
    pageSize,
    page,
  }: {
    pageSize: string;
    page: number;
  }) => void;
  updateSearchParams: (
    setSearchPArams: SetURLSearchParams,
    newParams: Record<string, string>
  ) => void;
  logs: RecurringTask[];
  workOrders: WorkOrder[];
  selectedLog: RecurringTask | null;
  setSelectedLog: (log: RecurringTask | null) => void;
  handleViewInstance: (log: string) => void;
  showState: {
    showPrintState: boolean;
    showLogState: boolean;
  };
  updateShowState: (modalName: string, show: boolean) => void;
  selectedLogInstance: TaskInstance | null;
  navigateBack: () => void;
  handleViewFile: (link: string) => void;
  handleViewLog: (log: RecurringTask) => void;
  submitPrint: (data: SubmitPrintData) => void;
}
interface SubmitPrintData {
  startDate: string;
  endDate: string | null;
  logs: { [key: string]: any };
  selected: { [key: string]: boolean };
  fileType: "pdf" | "excel";
}

interface LogProviderProps {
  children: ReactNode;
}

export const LogContext = createContext<LogContextType | undefined>(undefined);

export const LogProvider: React.FC<LogProviderProps> = ({ children }) => {
  const { selectedFacility } = useFacility();
  const [searchParams, setSearchParams] = useSearchParams();
  const [showFilter, setShowFilter] = useState(false);
  const [totalPages, setTotalPages] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const [showState, setShowState] = useState({
    showPrintState: false,
    showLogState: false,
  });

  const updateShowState = (modalName: string, show: boolean) => {
    setShowState((prevState) => ({
      ...prevState,
      [modalName]: show,
    }));
  };
  const navigate = useNavigate();

  // Get values from URL or use defaults
  const page = searchParams.get("page") || "1";
  const pageSize = searchParams.get("pageSize") || "10";
  const selectedTab = (searchParams.get("tab") as TabType) || "work-orders";

  // Parse filters from URL
  const filterObject = useMemo(() => {
    return searchParams.get("filters")
      ? JSON.parse(searchParams.get("filters")!)
      : {};
  }, [searchParams]);

  const updateTab = (tab: TabType) => {
    updateSearchParams(setSearchParams, {
      tab,
      page: "1",
      filters: "{}",
    });
  };

  const applyFilters = (newFilters: Record<string, any>) => {
    updateSearchParams(setSearchParams, {
      filters: JSON.stringify(newFilters),
      page: "1",
    });
  };

  const resetFilters = () => {
    updateSearchParams(setSearchParams, {
      filters: "{}",
      page: "1",
    });
  };

  const handlePageChange = (newPage: number) => {
    updateSearchParams(setSearchParams, { page: newPage.toString() });
  };

  const handlePageSizeChange = ({
    pageSize,
    page,
  }: {
    pageSize: string;
    page: number;
  }) => {
    updateSearchParams(setSearchParams, {
      pageSize,
      page: page.toString(),
    });
  };

  // State for data
  const [logs, setLogs] = useState<RecurringTask[]>([]);
  const [selectedLog, setSelectedLog] = useState<RecurringTask | null>(null);
  const [workOrders, setWorkOrders] = useState<WorkOrder[]>([]);
  const [selectedLogInstance, setSelectedLogInstance] =
    useState<TaskInstance | null>(null);

  const handleViewLog = (log: RecurringTask) => {
    setSelectedLog(log);
    updateShowState("showLogState", true);
  };
  const handleViewInstance = async (logId: string) => {
    navigate(`/logs/${logId}`);
  };

  const { id } = useParams();

  useEffect(() => {
    if (id) {
      const fetchLogInstanceData = async () => {
        try {
          const logInstance = await fetchLogInstance(id);
          setSelectedLogInstance(logInstance);
        } catch (error) {
          console.error("Error fetching log :", error);
        }
      };
      fetchLogInstanceData();
    }
  }, [id]);

  useEffect(() => {
    if (!selectedFacility) return;

    const fetchData = async () => {
      if (selectedTab === "work-orders") {
        try {
          const { workOrders, totalPages } = await fetchWorkOrderLogs(
            page,
            pageSize,
            filterObject,
            selectedFacility
          );
          setWorkOrders(workOrders);
          setTotalPages(totalPages);
        } catch (error) {
          console.error("Error loading logs", error);
        }
      } else {
        try {
          const logs = await fetchLogs(
            selectedTab,
            filterObject,
            selectedFacility
          );

          setLogs(logs);
        } catch (error) {
          console.error("Error loading logs", error);
        }
      }
    };
    fetchData();
  }, [selectedFacility, page, pageSize, filterObject]);

  const navigateBack = () => {
    setSelectedLogInstance(null);
    navigate(-1);
  };

  const handleViewFile = async (link: string) => {
    try {
      const encodedLink = encodeURIComponent(link);
      await fetchAttachments(encodedLink);
    } catch (error) {
      console.error("Error fetching file:", error);
    }
  };

  const submitPrint = async (data: SubmitPrintData) => {
    const { startDate, endDate, logs, fileType} = data;
    try {
      await printLog(startDate, endDate, logs, selectedTab, selectedFacility, fileType);
      updateShowState("showPrintState", false);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <LogContext.Provider
      value={{
        selectedTab,
        filters: filterObject,
        page,
        pageSize,
        totalPages,
        isLoading,
        showFilter,
        setShowFilter,
        setTotalPages,
        updateTab,
        applyFilters,
        resetFilters,
        handlePageChange,
        handlePageSizeChange,
        updateSearchParams,
        logs,
        workOrders,
        selectedLog,
        setSelectedLog,
        handleViewInstance,
        showState,
        updateShowState,
        selectedLogInstance,
        navigateBack,
        handleViewFile,
        handleViewLog,
        submitPrint,
      }}
    >
      {children}
    </LogContext.Provider>
  );
};
