import React from "react";
import { scrollToComponent } from "../utils/helpers";

const LittleDetailsSection: React.FC = () => {
  return (
    <div className="little-details-section">
      <div className="container-fluid">
        <div className="inner-container-fluid overflow-hidden">
          <div
            className="bgImg parallaxBgImg d-none d-md-block"
            style={{
              backgroundImage:
                "url('/assets/images/ze_casal_clean_and_modern_healthcare_building.png')",
            }}
          />
          <div
            className="bgImg parallaxBgImg d-md-none"
            style={{
              backgroundImage:
                "url('/assets/images/ze_casal_clean_and_modern_healthcare_building_mobile.png')",
            }}
          />

          <div className="container">
            <div className="section-heading fadeInUp">
              <h2>
                The Big Picture & <strong>The Little Details</strong>
              </h2>
            </div>
            <div className="row columns">
              <div className="col-md-4 column fadeInUp">
                <div className="column__box gutters-end-0">
                  <p>
                    Sequra is the brainchild of industry veterans who saw
                    firsthand the daily challenges of facility management and
                    were driven to create a better way forward.
                  </p>
                </div>
              </div>
              <div className="col-md-4 column fadeInUp">
                <div className="column__box gutters-end-0">
                  <p>
                    Built from real-world experience, Sequra is designed to
                    simplify compliance, improve safety, and bring efficiency to
                    every corner of facility operations.
                  </p>
                </div>
              </div>
              <div className="col-md-4 column fadeInUp">
                <div className="column__box gutters-end-0">
                  <p>
                    The new platform is a paradigm-shift in the industry,
                    empowering facilities to achieve flawless operations with
                    intuitive, cutting-edge tools that meet the needs of both
                    management and staff.
                  </p>
                </div>
              </div>
            </div>
            <div className="little-details-section__btm fadeInUp">
              <h3>
                Sequra Works. <strong>Let's Make It Work For You.</strong>
              </h3>
              <button
                className="btn btn-gradient"
                onClick={() => scrollToComponent()}
              >
                Join The Facility Management Revolution
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LittleDetailsSection;
