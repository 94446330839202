import { Tooltip } from "react-tooltip";
import {
  Area,
  AreaChart,
  CartesianGrid,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from "recharts";
import { useAnalytics } from "../../hooks/useAnalytics";

const AnalyticsChart = ({ data }: { data: any }) => {
  const { showState } = useAnalytics();
  return (
    <ResponsiveContainer width="100%" height="100%">
      <AreaChart
        data={data}
        margin={{ top: 30, right: 50, left: 0, bottom: 50 }}
      >
        <XAxis dataKey="date" />
        <YAxis />
        <CartesianGrid strokeDasharray="3 3" />
        <Tooltip />

        {showState.showCompleted && (
          <Area
            key="completed"
            type="monotone"
            dataKey="completed"
            name="Completed Tasks"
            stroke="#111010"
            fillOpacity={0}
          />
        )}
        {showState.showClosed && (
          <Area
            key="closed"
            type="monotone"
            dataKey="closed"
            name="Closed Tasks"
            stroke="#00B0A8"
            fillOpacity={0}
          />
        )}
        {showState.showCreated && (
          <Area
            key="created"
            type="monotone"
            dataKey="created"
            name="Created Tasks"
            stroke="#004643"
            fillOpacity={0}
          />
        )}
      </AreaChart>
    </ResponsiveContainer>
  );
};
export default AnalyticsChart;