import React, {
  createContext,
  useState,
  ReactNode,
  useCallback,
  useEffect,
} from "react";
import axios from "axios";
import { SetURLSearchParams, useSearchParams } from "react-router-dom";
import { Vendor } from "../types/Vendor";
import { updateSearchParams } from "../utils/ParamUtils";
import { debounce } from "../utils/Helpers";
import { useFacility } from "../hooks/useFacility";
import {
  createVendor,
  deleteVendor,
  fetchVendors,
} from "../api/services/VendorService";

interface PageSizeChangeProps {
  pageSize: string;
  page: number;
}

type FormDataType = {
  name: string;
  contact: string;
  phone: string;
  email: string;
  address: string;
  type: string;
};

// Define the type for the context values
interface VendorContextType {
  vendors: Vendor[];
  selectedVendor: Vendor | null;
  setSelectedVendor: (vendor: Vendor | null) => void;
  handleViewVendor: (vendor: Vendor | null) => void;
  onCreateVendor: (data: FormDataType) => void;
  totalPages: number;
  isLoading: boolean;
  updateSearchParams: (
    setSearchParams: SetURLSearchParams,
    params: object
  ) => void;
  pageSize: string;
  page: string;
  handlePageSizeChange: ({ pageSize, page }: PageSizeChangeProps) => void;
  handlePageChange: (newPage: number) => void;
  filters: string;
  applyFilters: (filters: Record<string, string>) => void;
  resetFilters: () => void;
  handleDelete: (vendor: Vendor) => void;
  showModalState: {
    showDelete: boolean;
    showVendor: boolean;
    showFilter: boolean;
  };
  updateShowModalState: (modal: string, value: boolean) => void;
}

// Define the type for the provider props
interface VendorProviderProps {
  children: ReactNode;
}

// Create the context
export const VendorContext = createContext<VendorContextType | undefined>(
  undefined
);

// Create the provider component
export const VendorProvider = ({ children }: VendorProviderProps) => {
  const { selectedFacility } = useFacility();
  const [searchParams, setSearchParams] = useSearchParams();

  const page = searchParams.get("page") || "1";
  const pageSize = searchParams.get("pageSize") || "10";
  const filters = searchParams.get("filters") || "";

  const [vendors, setVendors] = useState<Vendor[]>([]);
  const [selectedVendor, setSelectedVendor] = useState<Vendor | null>(null);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const [showModalState, setShowModalState] = useState({
    showDelete: false,
    showVendor: false,
    showFilter: false,
  });

  const updateShowModalState = (modal: string, value: boolean) => {
    setShowModalState((prev) => ({ ...prev, [modal]: value }));
  };

  const onCreateVendor = async (data: FormDataType) => {
    try {
      await createVendor(data, selectedFacility);

      loadVendors();
      updateShowModalState("showVendor", false);
    } catch (error) {
      console.log(error);
    }
  };

  const loadVendors = useCallback(
    debounce(async () => {
      try {
        setIsLoading(true);
        const filterObject = filters ? JSON.parse(filters) : {};

        if (!selectedFacility) {
          return;
        }

        const queryString = new URLSearchParams({
          ...filterObject,
          page,
          pageSize,
        }).toString();

        const result = await fetchVendors(selectedFacility, queryString);

        if (result) {
          const { vendors, totalPages } = result;
          setVendors(vendors);
          setTotalPages(totalPages);
        } else {
          setVendors([]);
          setTotalPages(1);
        }
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching vendors:", error);
        setIsLoading(false);
      }
    }, 300),
    [searchParams, selectedFacility]
  );

  const handlePageSizeChange = ({ pageSize, page }: PageSizeChangeProps) => {
    updateSearchParams(setSearchParams, { pageSize, page: page.toString() });
  };

  const handlePageChange = (newPage: number) => {
    updateSearchParams(setSearchParams, { page: newPage.toString() });
  };

  const handleViewVendor = (vendor: Vendor | null) => {
    setSelectedVendor(vendor);
    updateShowModalState("showVendor", true);
  };

  const applyFilters = (filters: Record<string, string>) => {
    updateSearchParams(setSearchParams, {
      filters: JSON.stringify(filters),
      page: "1",
    });
  };

  const resetFilters = () => {
    updateSearchParams(setSearchParams, {
      filters: "",
      page: "1",
    });
  };

  const handleDelete = async () => {
    try {
      if (!selectedVendor) return;
      await deleteVendor(selectedVendor._id);
      updateShowModalState("showDelete", false);
      loadVendors();
    } catch (error) {
      console.log("Error deleting vendor:", error);
    }
  };

  useEffect(() => {
    if (!selectedFacility) return;
    loadVendors();
  }, [searchParams, selectedFacility]);

  return (
    <VendorContext.Provider
      value={{
        vendors,
        selectedVendor,
        setSelectedVendor,
        onCreateVendor,
        handleViewVendor,
        totalPages,
        isLoading,
        updateSearchParams,
        pageSize,
        page,
        handlePageSizeChange,
        handlePageChange,
        filters,
        applyFilters,
        resetFilters,
        handleDelete,
        showModalState,
        updateShowModalState,
      }}
    >
      {children}
    </VendorContext.Provider>
  );
};
