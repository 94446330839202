import React, { Fragment, useState } from "react";
import axios from "axios";
import { DeleteOutline, SearchOffOutlined } from "@mui/icons-material";
import GenericModal from "../modals/GenericModal";
import { capitalizeString, formatDate } from "../../utils/FormatFunctions";
import { RecurringTask } from "../../types/RecurringTask";
import { useTaskScheduler } from "../../hooks/useTaskScheduler";
import Table from "../general/Table";

type PageType = "life-safety" | "prev-maint" | "administrative";
interface RecurringTasksProps {
  recurringTasks: RecurringTask[];
  type: PageType;
}

const RecurringTasks: React.FC<RecurringTasksProps> = ({
  recurringTasks,
  type,
}) => {
  const {
    showModalState,
    updateShowModalState,
    selectedTask,
    handleDeleteClick,
    handleViewClick,
  } = useTaskScheduler();

  const returnTitle = (type: PageType) => {
    switch (type) {
      case "life-safety":
        return "Life Safety";
      case "prev-maint":
        return "Preventative Maintenance";
      case "administrative":
        return "Administrative";
      default:
        return "Recurring Tasks";
    }
  };
  const columns = [
    {
      label: "Title",
      key: "title",
      sortable: false,
      render: (recurringTask: RecurringTask) => (
        <>{capitalizeString(recurringTask.title)}</>
      ),
      className: "w-full max-w-0 py-4 pr-3 sm:w-auto sm:max-w-none ",
    },
    {
      label: "Frequency",
      key: "frequency",
      sortable: false,
      render: (recurringTask: RecurringTask) => (
        <>{capitalizeString(recurringTask.recurrence.frequency)}</>
      ),
      className: "hidden sm:table-cell",
    },
    {
      label: "Category",
      key: "category",
      sortable: false,
      render: (recurringTask: RecurringTask) => (
        <>{capitalizeString(recurringTask.category)}</>
      ),
      className: "hidden sm:table-cell",
    },
    {
      label: "Start Date",
      key: "startDate",
      sortable: false,
      render: (recurringTask: RecurringTask) => (
        <>
          {recurringTask.startDate &&
            formatDate(recurringTask.startDate, "YYYY-MM-DD", "utc")}
        </>
      ),
      className: "hidden sm:table-cell",
    },
    {
      label: "Source",
      key: "source",
      sortable: false,
      render: (recurringTask: RecurringTask) => (
        <>{capitalizeString(recurringTask.source)}</>
      ),
      className: "hidden sm:table-cell",
    },
  ];

  return (
    <div className="px-4 overflow-auto border border-gray-200 rounded-sm mx-2 sm:mx-4 md:mx-8 ">
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <div className="flex gap-2 items-center my-2">
            <h1 className="text-base  font-semibold leading-6 text-gray-900 pr-2 border-r-2 border-gray-200 ">
              {returnTitle(type)}
            </h1>
            <p className="text-sm text-gray-700">
              List of all recurring tasks.
            </p>
          </div>
        </div>
        <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none"></div>
      </div>
      <div className="-mx-4 my-4 sm:-mx-0">
        {recurringTasks && recurringTasks.length > 0 ? (
          <Table
            columns={columns}
            data={recurringTasks}
            onRowClick={(task) => handleViewClick(task)}
          />
        ) : (
          <div className="flex flex-col w-full justify-center items-center bg-gray-100 text-gray-500 py-8">
            <SearchOffOutlined style={{ fontSize: "2rem" }} />
            <span className="text-sm">No items present</span>
          </div>
        )}
      </div>
    </div>
  );
};

export default RecurringTasks;
