import React, { useEffect, useState } from "react";
import { useRecurring } from "../../hooks/useRecurring";
import PageHeader from "../../updatedcomponents/general-ui/PageHeader";
import {
  ArrowBack,
  AttachmentOutlined,
  ChevronRightOutlined,
} from "@mui/icons-material";
import { capitalizeString, formatDate } from "../../utils/FormatFunctions";
import StatusBadge from "../../updatedcomponents/general-ui/StatusBadges";
import { File as FileType } from "../../types/File";
import TableFormRHF from "../../updatedcomponents/forms/TableFormRHF";
import Modal from "../../updatedcomponents/general-ui/Modal";
import useMultiForm from "../../hooks/useMultiForm";
import TaskInstanceForm from "../../updatedcomponents/forms/TaskInstanceForm";
import Button from "../../updatedcomponents/general/Button";
import AssignmentSelector from "../../updatedcomponents/general-ui/AssignmentSelector";

const RecurringTaskInstance = () => {
  const {
    instanceToEdit,
    navigateBack,
    closeOutValidation,
    canCloseOut,
    handleCloseOut,
    showState,
    updateShowState,
    users,
    handleTaskAssignment,
  } = useRecurring();

  const {
    currentIndex,
    goToSection,
    goToNextSection,
    goToPreviousSection,
    isFirstSection,
    isLastSection,
  } = useMultiForm(instanceToEdit?.parentTask?.map?.fields ?? []);

  useEffect(() => {
    closeOutValidation();
  }, [closeOutValidation, currentIndex]);

  return (
    <>
      <PageHeader
        title={
          <button
            type="button"
            className="flex gap-2 items-center text-md font-normal text-secondary-300 hover:text-secondary-200 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-secondary-100 "
            onClick={() => navigateBack()}
          >
            <ArrowBack style={{ fontSize: "1rem" }} />
            {instanceToEdit ? instanceToEdit.parentTask.title : "Back to Tasks"}
          </button>
        }
        buttons={
          <Button
            children="Close Out"
            disabled={!canCloseOut}
            onClick={() => handleCloseOut()}
          />
        }
      />
      <div className="flex justify-between my-2 px-4 py-2 text-secondary-100 border-b border-secondary-1000">
        <span className=" font-medium py-2">Details</span>
        <button
          className="px-12 py-2 font-medium text-sm bg-secondary-1100 rounded-sm"
          onClick={() => updateShowState("showSpecs", !showState.showSpecs)}
        >
          {showState.showSpecs ? "Hide" : "Show"} Details
        </button>
      </div>
      {showState.showSpecs && instanceToEdit && (
        <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-y-2 text-sm m-4">
          <label className="text-secondary-100">Due Date:</label>
          <span className="text-gray-800 sm:col-span-2 md:col-span-3">
            {formatDate(instanceToEdit.date, "MMM DD, yyyy", "utc")}
          </span>

          <label className="text-secondary-100">Status:</label>
          <span className="text-gray-800 sm:col-span-2 md:col-span-3">
            <StatusBadge status={instanceToEdit.status} />
          </span>

          <label className="text-secondary-100">Source:</label>
          <span className="text-gray-800 sm:col-span-2 md:col-span-3">
            {capitalizeString(instanceToEdit.parentTask?.source)}
          </span>

          <label className="text-secondary-100">Details:</label>
          <span className="text-gray-800 sm:col-span-2 md:col-span-3 border border-secondary-1000 mb-4 px-2 rounded-md">
            {instanceToEdit.parentTask?.details}
          </span>
          <label className="text-secondary-100">Assigned To:</label>
          <span className="text-gray-800 sm:col-span-2 md:col-span-3">
            <AssignmentSelector
              users={users}
              selectedUser={
                instanceToEdit.assignedTo ? instanceToEdit.assignedTo : null
              }
              task={instanceToEdit}
              handleChange={handleTaskAssignment}
            />
          </span>
          <label className="text-secondary-100">Attachments:</label>
          <span className="text-gray-800 sm:col-span-2 md:col-span-3">
            {instanceToEdit.parentTask?.attachments?.length ? (
              instanceToEdit.parentTask.attachments.map(
                (attachment: FileType) => (
                  <div key={attachment._id} className="flex gap-2">
                    <button
                      className="px-2 py-0.5 bg-secondary-1100 rounded-sm font-semibold text-sm cursor-pointer my-1"
                      onClick={() => console.log(attachment.url ?? "")}
                    >
                      <AttachmentOutlined fontSize="small" /> {attachment.name}
                    </button>
                  </div>
                )
              )
            ) : (
              <p className="text-sm italic">No attachments for this task</p>
            )}
          </span>
        </div>
      )}
      {instanceToEdit &&
        instanceToEdit.parentTask?.map?.fields.map((mapField, index) => (
          // Display the map fields
          <div key={index} className="px-4 mt-2">
            <div
              className="flex justify-between border rounded p-4 items-center hover:cursor-pointer hover:shadow-sm"
              onClick={() => goToSection(index)}
            >
              <div className="flex flex-col">
                <h3 className="text-sm">
                  {Object.keys(mapField)[1]}:{" "}
                  {String(mapField[Object.keys(mapField)[1]])}
                </h3>
                <h4 className="text-sm">
                  {Object.keys(mapField)[0]}:{" "}
                  {String(mapField[Object.keys(mapField)[0]])}
                </h4>
              </div>
              <div>
                {instanceToEdit.sectionStatus &&
                instanceToEdit.sectionStatus[index] ? (
                  <StatusBadge status={instanceToEdit.sectionStatus[index]} />
                ) : (
                  <StatusBadge status="not started" />
                )}
                <ChevronRightOutlined fontSize="medium" />
              </div>
            </div>
          </div>
        ))}
      <Modal
        open={currentIndex > -1}
        setOpen={() => {
          goToSection(-1);
        }}
        title={
          instanceToEdit ? instanceToEdit.parentTask.title : "Task Details"
        }
        subtitle={
          <div className="flex flex-col">
            <h2 className="font-normal">
              {formatDate(instanceToEdit?.date, "MMM DD, yyyy", "utc")}
            </h2>
            {currentIndex > -1 && instanceToEdit
              ? instanceToEdit.parentTask.map &&
                Object.entries(
                  instanceToEdit.parentTask.map.fields[currentIndex]
                ).map(([key, value]) => (
                  <span key={key}>{`${key}: ${String(value)}`}</span>
                ))
              : ""}
          </div>
        }
        content={
          <TaskInstanceForm
            key={currentIndex}
            currentIndex={currentIndex}
            goToSection={goToSection}
            goToNextSection={goToNextSection}
            goToPreviousSection={goToPreviousSection}
            isFirstSection={isFirstSection}
            isLastSection={isLastSection}
          />
        }
      />
    </>
  );
};

export default RecurringTaskInstance;
