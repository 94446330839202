import React from "react";

const Welcome = () => {
  return (
    <div className="flex flex-col items-center min-h-48 justify-center">
      <h2 className="text-2xl font-light animate-fade-in-left">
        Welcome to Sequra
      </h2>
      <p className="text-sm pt-4 font-light animate-fade-in-left text-center leading-6">
        Let's get started
      </p>
    </div>
  );
};

export default Welcome;
