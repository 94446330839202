import React, { useState } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import Home from "./pages/ApplicationPages/Home";
import WorkOrders from "./pages/ApplicationPages/WorkOrders";
import Tasks from "./pages/ApplicationPages/RecurringTasks";
import Logs from "./pages/ApplicationPages/Logs";
import Vendors from "./pages/ApplicationPages/Vendors";
import Login from "./pages/Authentication/Login";
import Team from "./pages/ApplicationPages/Team";
import Registration from "./pages/Authentication/Registration";
import TaskScheduler from "./pages/ApplicationPages/TaskScheduler";
import MyBuilding from "./pages/ApplicationPages/Building";
import SettingsPage from "./pages/ApplicationPages/Settings";
import KioskLogin from "./pages/KioskPages/KioskLogin";
import KioskForm from "./pages/KioskPages/KioskForm";
import NotFound from "./pages/ApplicationPages/NotFound";
import ForgotPassword from "./pages/Authentication/ForgotPassword";
import ApplicationShell from "./updatedcomponents/general-ui/ApplicationShell";
import AdminHome from "./pages/AdminPages/AdminHome";
import Facilities from "./pages/AdminPages/Facilities";
import Users from "./pages/AdminPages/Users";
import ScheduledTasks from "./pages/AdminPages/ScheduledTasks";
import Analytics from "./pages/ApplicationPages/Analytics";
import { jwtDecode } from "jwt-decode";
import SessionExpiredModal from "./updatedcomponents/general-ui/SessionExpiredModal";
import LogInstance from "./pages/ApplicationPages/LogInstance";
import WorkOrderInstance from "./pages/ApplicationPages/WorkOrderInstance";
import LandingPage from "./pages/LandingPage/LandingPage";
import { VendorProvider } from "./context/VendorContext";
import UserPage from "./pages/ApplicationPages/User";
import { useAuth } from "./hooks/useAuth";
import { WorkOrderProvider } from "./context/WorkOrderContext";
import { TeamProvider } from "./context/TeamContext";
import { TaskSchedulerProvider } from "./context/TaskSchedulerContext";
import { SettingsProvider } from "./context/SettingContext";
import { AnalyticsProvider } from "./context/AnalyticsContext";
import { BuildingProvider } from "./context/BuildingContext";
import { HomeProvider } from "./context/HomeContext";
import { LogProvider } from "./context/LogContext";
import { RecurringTaskProvider } from "./context/RecurringTaskContext";
import RecurringTaskInstance from "./pages/ApplicationPages/RecurringTaskInstance";
import OnboardingForm from "./OnboardingForm/OnboardingForm";
import SupportHome from "./pages/AdminPages/SupportHome";
import ManageOnboarding from "./pages/AdminPages/ManageOnboarding";
import ManageOnboardingInstance from "./pages/AdminPages/ManageOnboardingInstance";
import { ManageOnboardingProvider } from "./context/ManageOnboardingContext";

function App() {
  const { currentUser } = useAuth();
  const role = currentUser?.access;

  const [isSessionExpired, setIsSessionExpired] = useState(false);

  const isAuthenticated = () => {
    const token = localStorage.getItem("token");
    if (!token) {
      setIsSessionExpired(true);
      return false;
    }

    try {
      const decodedToken = jwtDecode(token);
      const currentTime = Date.now() / 1000; // Convert to seconds

      if (decodedToken.exp && decodedToken.exp > currentTime) {
        return true;
      } else {
        setIsSessionExpired(true);
        return false;
      }
    } catch (error) {
      // Handle decoding errors
      console.error("Error decoding token:", error);
      return false;
    }
  };

  const ProtectedRoute: React.FC<{ children: React.ReactNode }> = ({
    children,
  }) => {
    if (!isAuthenticated()) {
      return <Navigate to="/login" replace />;
    }
    return (
      <>
        {children}
        {!isAuthenticated() && (
          <SessionExpiredModal
            isSessionExpired={isSessionExpired}
            setIsSessionExpired={setIsSessionExpired}
          />
        )}
      </>
    );
  };

  return (
    <div className="font-body">
      <Routes>
        {" "}
        <Route path="/" element={<LandingPage />} />
        <Route path="/login" element={<Login />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/kiosks/login" element={<KioskLogin />} />
        <Route
          path="*"
          element={
            <ProtectedRoute>
              <ApplicationShell />
            </ProtectedRoute>
          }
        >
          <Route
            path="home"
            element={
              role === "system-admin" ? (
                <AdminHome />
              ) : role === "system-support" ? (
                <SupportHome />
              ) : (
                <HomeProvider>
                  <Home />
                </HomeProvider>
              )
            }
          />
          <Route
            path="work-orders"
            element={
              <WorkOrderProvider>
                <WorkOrders />
              </WorkOrderProvider>
            }
          />
          <Route
            path="work-orders/instance/:id"
            element={
              <WorkOrderProvider>
                <WorkOrderInstance />
              </WorkOrderProvider>
            }
          />
          <Route
            path="tasks"
            element={
              <RecurringTaskProvider>
                <Tasks />
              </RecurringTaskProvider>
            }
          />
          <Route
            path="tasks/:id"
            element={
              <RecurringTaskProvider>
                <RecurringTaskInstance />
              </RecurringTaskProvider>
            }
          />
          <Route
            path="logs"
            element={
              <LogProvider>
                <Logs />
              </LogProvider>
            }
          />
          <Route
            path="logs/:id"
            element={
              <LogProvider>
                <LogInstance />
              </LogProvider>
            }
          />
          <Route
            path="vendors"
            element={
              <VendorProvider>
                <Vendors />
              </VendorProvider>
            }
          />
          <Route
            path="task-scheduler"
            element={
              <TaskSchedulerProvider>
                <TaskScheduler />
              </TaskSchedulerProvider>
            }
          />
          <Route
            path="team"
            element={
              <TeamProvider>
                <Team />
              </TeamProvider>
            }
          />
          <Route
            path="team/:id"
            element={
              <TeamProvider>
                <UserPage />
              </TeamProvider>
            }
          />
          <Route
            path="my-building"
            element={
              <BuildingProvider>
                <MyBuilding />
              </BuildingProvider>
            }
          />
          <Route
            path="settings"
            element={
              <SettingsProvider>
                <SettingsPage />
              </SettingsProvider>
            }
          />
          <Route
            path="analytics"
            element={
              <AnalyticsProvider>
                <Analytics />
              </AnalyticsProvider>
            }
          />
          {/* Admin routes */}
          <Route path="facilities" element={<Facilities />} />
          <Route path="users" element={<Users />} />
          <Route path="task-catalog" element={<ScheduledTasks />} />
          <Route
            path="support/onboarding"
            element={
              <ManageOnboardingProvider>
                <ManageOnboarding />
              </ManageOnboardingProvider>
            }
          />
          <Route
            path="support/onboarding/:id"
            element={
              <ManageOnboardingProvider>
                <ManageOnboardingInstance />
              </ManageOnboardingProvider>
            }
          />
          <Route path="*" element={<NotFound />} />
        </Route>
        {/* Kiosk routes */}
        <Route path="/kiosks/request" element={<KioskForm />} />
        <Route path="/kiosk/:kioskId?" element={<KioskForm />} />
        {/* General routes */}
        <Route path="/register/:token" element={<Registration />} />
        <Route path="/onboarding/:sessionId?" element={<OnboardingForm />} />
      </Routes>
    </div>
  );
}

export default App;
