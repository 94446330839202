import React, { Fragment } from "react";
import { capitalizeString } from "../../utils/FormatFunctions";
import { RecurringTask } from "../../types/RecurringTask";
import Table from "../general/Table";
import {  RepeatOutlined } from "@mui/icons-material";
import { useLog } from "../../hooks/useLog";


const LogsList: React.FC = () => {
  const {logs, handleViewLog} = useLog();
  const columns = [
    {
      label: "Log Title",
      key: "name",
      sortable: false,
      render: (recurringTask: RecurringTask) => (
        <>
          <span className="flex flex-col py-1 pr-2 text-sm font-medium text-secondary-100 ">
            {capitalizeString(recurringTask.title)}{" "}
            <span className="font-light text-xs py-1">
              ({recurringTask.logEntries.length} Items)
            </span>
            <dl className="font-normal sm:hidden">
              <dt className="sr-only">Frequency</dt>
              <div className="mt-1 text-secondary-400 gap-2 flex items-center">
                {recurringTask.recurrence && (
                  <span className="flex items-center gap-1 text-xs">
                    <RepeatOutlined style={{ fontSize: "1rem" }} />
                    {capitalizeString(recurringTask.recurrence.frequency)}
                  </span>
                )}
              </div>
              <dt className="sr-only sm:hidden">Type</dt>
              <dd className="mt-1 truncate text-gray-500 sm:hidden">
                {capitalizeString(recurringTask.category)}
              </dd>
            </dl>
          </span>
        </>
      ),
      className: "w-full max-w-0 py-4 pr-3 sm:w-auto sm:max-w-none ",
    },
    {
      label: "Frequency",
      key: "frequency",
      sortable: false,
      render: (recurringTask: RecurringTask) => (
        <>
          <span className="hidden px-3 py-4 text-sm text-gray-500 lg:table-cell">
            {capitalizeString(recurringTask.recurrence.frequency)}
          </span>
        </>
      ),
      className: "hidden sm:table-cell",
    },
    {
      label: "Log Type",
      key: "type",
      sortable: false,
      render: (recurringTask: RecurringTask) => (
        <>
          <span className="hidden px-3 py-4 text-sm text-gray-500 sm:table-cell">
            {capitalizeString(recurringTask.category)}
          </span>
        </>
      ),
      className: "hidden sm:table-cell",
    },
    {
      label: "Data Source",
      key: "source",
      sortable: false,
      render: (recurringTask: RecurringTask) => (
        <>
          <span className="px-3 py-4 text-sm text-gray-500">
            {capitalizeString(recurringTask.source)}
          </span>
        </>
      ),
      className: "hidden sm:table-cell",
    },
  ];
  return (
    <Table
      data={logs}
      columns={columns}
      onRowClick={(log)=>handleViewLog(log)}
      height="max-h-[calc(100vh-400px)] sm:max-h-[calc(100vh-335px)]"
    />
  );
};

export default LogsList;
