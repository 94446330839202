import React from "react";
import { capitalizeString } from "../../utils/FormatFunctions";

interface StatusBadgeProps {
  status: string;
}

const StatusBadge: React.FC<StatusBadgeProps> = ({ status }) => {
  switch (status) {
    case "standard":
    case "not started":
    case "incomplete":
      return (
        <span className="inline-flex items-center justify-center rounded-sm bg-secondary-1100 px-4 py-1 text-xs font-medium text-secondary-100 ring-1 ring-inset ring-secondary-100/10">
          {capitalizeString(status)}
        </span>
      );
    case "unable":
    case "failed":
    case "overdue":
    case "urgent":
      return (
        <span className="inline-flex items-center rounded-sm bg-reds-1100 px-4 py-1 text-xs font-medium text-reds-500 ring-1 ring-inset ring-reds-400/10">
          {capitalizeString(status)}
        </span>
      );
    case "in progress":
      return (
        <span className="inline-flex items-center rounded-sm bg-secondary-1200 px-4 py-1 text-xs font-medium text-secondary-400 ring-1 ring-inset ring-secondary-400/10">
          <span className="bg-secondary-400 h-1 w-1 rounded-full mr-2" />{" "}
          {capitalizeString(status)}
        </span>
      );
    case "complete":
      return (
        <span className="inline-flex items-center rounded-sm bg-accent-1000 px-4 py-1 text-xs font-medium text-accent-200 ring-1 ring-inset ring-accent-300/20">
          {capitalizeString(status)}
        </span>
      );
    case "closed":
      return (
        <span className="inline-flex items-center rounded-sm bg-accent-500 px-4 py-1 text-xs font-medium text-primary ring-1 ring-inset ring-accent-300/20">
          {capitalizeString(status)}
        </span>
      );
    default:
      return <span>{capitalizeString(status)}</span>;
  }
};

export default StatusBadge;
