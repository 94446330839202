import React from "react";
import axios from "axios";
import { DateRangeOutlined, RepeatOutlined } from "@mui/icons-material";
import {
  capitalizeString,
  formatDate,
  classNames,
} from "../../utils/FormatFunctions";
import Table from "../general/Table";
import { TaskInstance } from "../../types/TaskInstance";
import { useFacility } from "../../hooks/useFacility";
import { User } from "../../types/User";
import StatusBadge from "../general-ui/StatusBadges";
import AssignmentSelector from "../general-ui/AssignmentSelector";
import { useAuth } from "../../hooks/useAuth";

interface ToDoTableProps {
  data: TaskInstance[];
  users: any[];
  handleLogInstanceView: (task: TaskInstance) => void;
}

const ToDoTable: React.FC<ToDoTableProps> = ({
  data,
  handleLogInstanceView,
}) => {
  const { currentUser } = useAuth();
  
  const columns = [
    {
      label: "Task",
      key: "title",
      render: (taskInstance: TaskInstance) => (
        <>
          <div className="inline-flex sm:w-52 w-auto overflow-hidden text-secondary">
            <span className="truncate">
              {capitalizeString(taskInstance.title)}
            </span>
          </div>
          <div className="whitespace-nowrap text-sm">
            <div className="flex flex-col">
              <div className="sm:hidden mt-1 text-secondary-300 gap-2 flex items-center">
                {taskInstance.recurrence && (
                  <span
                    className={classNames(
                      "flex items-center gap-1 text-xs",
                      taskInstance.status === "overdue"
                        ? "text-reds-600"
                        : "text-secondary-400"
                    )}
                  >
                    <DateRangeOutlined style={{ fontSize: "1rem" }} />
                    {formatDate(taskInstance.date, "MMM DD, YYYY", "utc")}
                  </span>
                )}
              </div>
              <span className="my-1 sm:hidden">{<StatusBadge status={taskInstance.status} />}</span>
              <div className=" text-secondary-400 gap-2 flex items-center">
                {taskInstance.recurrence && (
                  <span className="flex items-center gap-1 text-xs">
                    <RepeatOutlined style={{ fontSize: "1rem" }} />
                    {capitalizeString(taskInstance.recurrence.frequency)}
                  </span>
                )}
              </div>
            </div>
          </div>
        </>
      ),
      className: "w-full max-w-0 py-4 pr-3 sm:w-auto sm:max-w-none ",
    },

    {
      label: "Due Date",
      key: "date",
      render: (taskInstance: TaskInstance) => (
        <span
          className={classNames(
            "flex items-center gap-1",
            taskInstance.status === "overdue"
              ? "text-reds-600"
              : "text-secondary-400"
          )}
        >
          <DateRangeOutlined style={{ fontSize: "1rem" }} />
          {formatDate(taskInstance.date, "MMM DD, YYYY", "utc")}
        </span>
      ),
      className: "hidden sm:table-cell",
    },
    {
      label: "Status",
      key: "status",
      render: (taskInstance: TaskInstance) => (
        <span>{<StatusBadge status={taskInstance.status} />}</span>
      ),
      className: "hidden sm:table-cell",
    },

    {
      label: "Source",
      key: "source",
      render: (taskInstance: TaskInstance) => (
        <span>{capitalizeString(taskInstance.source)}</span>
      ),
      className: "hidden sm:table-cell",
    },
   /*  {
      label: "Assigned To",
      key: "assignedTo",
      render: (taskInstance: TaskInstance) => (
        <div onClick={(e) => e.stopPropagation()}>
          <AssignmentSelector
            users={users}
            selectedUser={
              taskInstance.assignedTo ? taskInstance.assignedTo : null
            }
            task={taskInstance}
            handleChange={handleAssignment}
          />
        </div>
      ),
      className: `{ hidden ${
        permissions !== "worker" ? "sm:table-cell" : "hidden"
      }`,
    }, */
  ];
  return (
    <Table
      columns={columns}
      data={data}
      onRowClick={handleLogInstanceView}
      height="max-h-[calc(100vh-220px)] sm:max-h-[calc(100vh-250px)]"
    />
  );
};

export default ToDoTable;
