import React, { useState, useContext } from "react";
import axios from "axios";
import { Form, useNavigate } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";
import { FacilityContext } from "../../context/FacilityContext";
import { Link } from "react-router-dom";
import { NotificationContext } from "../../context/PushNotificationContext";
import { DeviceTabletIcon, UserIcon } from "@heroicons/react/24/outline";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useAuth } from "../../hooks/useAuth";

interface FormValues {
  email: string;
  password: string;
  general?: string;
}

const schema = Yup.object().shape({
  email: Yup.string().required("Email is required").email("Email is invalid"),
  password: Yup.string().required("Password is required"),
  general: Yup.string().optional(),
});

const Login: React.FC = () => {
  const navigate = useNavigate();

  const { handleFacilityChange } = useContext(FacilityContext);
  const { dispatch } = useAuth();
  // const { subscription } = useContext(NotificationContext);

  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm<FormValues>({
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data: FormValues) => {
    const user = { email: data.email, password: data.password };

    try {
      const response = await axios.post("/api/authenticate/user", user);

      const data = response.data;

      if (data.token) {
        localStorage.setItem("token", data.token);
        const userWithoutPassword = { ...data.user };
        delete userWithoutPassword.password;

        const userWithOrganization = {
          ...userWithoutPassword,
        };
        localStorage.setItem("user", JSON.stringify(userWithOrganization));
        const defaultFacility = userWithOrganization.lastLoggedFacility
          ? userWithOrganization.lastLoggedFacility
          : userWithOrganization.facility.length > 0
          ? userWithOrganization.facility[0]._id
          : "";
        handleFacilityChange(defaultFacility, userWithOrganization._id);
        dispatch({ type: "LOGIN", payload: userWithOrganization });
        const path =
          userWithOrganization.access === "worker" ? "/work-orders" : "/home";
        navigate(path);
      }
    } catch (error) {
      if (axios.isAxiosError(error) && error.response?.status === 401) {
        setError("general", { message: "Invalid email or password" });
      } else {
        setError("general", {
          message:
            "Oops, looks like we are having difficulty g you in. Try again later",
        });
      }
      console.log(error);
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === "Enter") {
      handleSubmit(onSubmit)();
    }
  };

  return (
    <div className="LoginWithAccount relative flex h-screen flex-1 flex-col items-center justify-center bg-secondary-1100 overflow-clip">
      <div className="absolute -top-80 -left-0">
        <img
          src="/sequra_pattern.svg"
          className="scale-[2]"
          alt="SVG Clipping"
        />
      </div>
      <div className="absolute -bottom-[500px] right-0">
        <img
          src="/sequra_pattern.svg"
          className="scale-[3]"
          alt="SVG Clipping"
        />
      </div>
      <div className="Content z-20 my-4 overflow-y-auto h-fit max-h-screen w-screen max-w-xs sm:max-w-sm lg:max-w-lg px-2 sm:px-8 py-6 relative bg-primary rounded shadow flex-col justify-start items-center gap-6 inline-flex">
        <div className="Logogram w-full h-10 py-0.5 justify-center items-center inline-flex">
          <img
            src="\sequra_logo__primary_black.png"
            alt="SEQURA logo"
            className="w-40 cursor-pointer"
            onClick={() => navigate("/")}
          />
        </div>
        <div className="Header self-stretch h-24 flex-col justify-start items-center gap-2 sm:gap-6 flex">
          <div className="HiWelcomeBack text-secondary-100 text-3xl sm:text-4xl font-medium font-header leading-10">
            Hi, welcome back!
          </div>
          <div className="PleaseLoginToContinue text-secondary-100 text-base sm:text-2xl font-normal leading-loose">
            Please Login to Continue
          </div>
        </div>
        <div className="Content self-stretch h-full max-h-80 flex-col justify-start items-start gap-4 sm:gap-8 flex">
          <div className="SegmentedTabs self-stretch p-1 bg-secondary-1100 rounded justify-start items-center inline-flex flex-col sm:flex-row">
            <button className="SegmentedItem w-full grow shrink basis-0 h-10 px-3 py-2.5 bg-secondary-100 rounded shadow justify-center items-center gap-2.5 flex">
              <UserIcon className="User w-5 h-5 relative text-primary" />
              <div className="Label text-center text-primary text-sm font-medium leading-tight">
                Login with Account
              </div>
            </button>
            <button
              className="SegmentedItem w-full grow shrink basis-0 h-10 px-3 py-2.5 rounded justify-center items-center gap-2.5 flex"
              onClick={() => navigate("/kiosks/login")}
            >
              <DeviceTabletIcon className="Devicetabletspeaker w-5 h-5 relative" />
              <div className="Label text-center text-secondary-100 text-sm font-normal leading-tight">
                Login with Kiosk
              </div>
            </button>
          </div>
          <form
            className="Forms self-stretch flex-col justify-start items-start gap-4 sm:gap-8 flex"
            onSubmit={handleSubmit(onSubmit)}
            onKeyDown={handleKeyDown}
          >
            <div className="Form self-stretch flex-col justify-start items-start gap-6 flex">
              <div className="TextField self-stretch h-20 flex-col justify-start items-start gap-2 flex">
                <div className="Label self-stretch h-6 flex-col justify-center items-start gap-1 flex">
                  <label className="InputLabel text-secondary-100 text-base font-normal leading-normal">
                    Email address
                  </label>
                </div>
                <input
                  id="email"
                  type="email"
                  placeholder="Enter your email here"
                  {...register("email")}
                  className={`
                      ${
                        errors.email
                          ? "border-reds-500 focus:ring-reds-500"
                          : "border-secondary-900 focus:ring-accent-500"
                      } "InputBase self-stretch p-3 bg-primary rounded border  justify-start items-center gap-3 inline-flex focus:ring-2 focus:border-none focus:ring-inset "`}
                />
                {errors.email && (
                  <p className="Emailerror text-reds-500 text-xs font-normal leading-none">
                    {errors.email.message}
                  </p>
                )}
              </div>
              <div className="TextField self-stretch h-20 flex-col justify-start items-start gap-2 flex">
                <div className="Label self-stretch h-6 flex-col justify-center items-start gap-1 flex">
                  <div className="flex w-full justify-between">
                    <label className="InputLabel text-secondary-100 text-base font-normal leading-normal">
                      Password
                    </label>
                    <Link
                      to="/forgot-password"
                      className="font-semibold text-sm text-accent-100 hover:text-accent-200"
                    >
                      Forgot password?
                    </Link>
                  </div>
                </div>
                <input
                  id="password"
                  type="password"
                  placeholder="Enter your password here"
                  {...register("password")}
                  className={`
                      ${
                        errors.password
                          ? "border-reds-500 focus:ring-reds-500"
                          : "border-secondary-900 focus:ring-accent-500"
                      } "InputBase self-stretch p-3 bg-primary rounded border  justify-start items-center gap-3 inline-flex focus:ring-2 focus:border-none focus:ring-inset "`}
                />
                {errors.password && (
                  <p className="PasswordError  text-reds-500 text-xs font-normal leading-none">
                    {errors.password.message}
                  </p>
                )}
              </div>
            </div>
          </form>
        </div>
        <button
          className="Button w-full px-16 py-2 bg-accent-500 rounded shadow-inner border border-primary justify-center items-center gap-2 inline-flex"
          type="submit"
          onClick={handleSubmit(onSubmit)}
        >
          <span className="Placeholder cursor-pointer text-primary text-base font-medium leading-normal">
            Login
          </span>
        </button>
        {errors.general && (
          <p className="text-reds-500 text-xs">{errors.general.message}</p>
        )}
      </div>
      {/* <div className="2024SnfsafeAllRightsReserved z-10 bottom-2 sm:bottom-6 absolute text-secondary-300 sm:text-base text-sm font-normal leading-normal">
        ©2024 SNFSafe. All rights reserved
      </div> */}
    </div>
  );
};
export default Login;
