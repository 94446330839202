import React from "react";

interface ProgressIndicatorProps {
  steps: { title: string; time: string; key: string; completed: boolean }[]; // List of step names
  currentStep: number; // Index of the current step
  onStepClick: (stepIndex: number) => void; // Callback for when a step is clicked
  errorSteps?: number[] | null;
}

const ProgressIndicator: React.FC<ProgressIndicatorProps> = ({
  steps,
  currentStep,
  onStepClick,
  errorSteps,
}) => {
  return (
    <div className="flex justify-start sm:justify-center sm:items-center gap-4 p-4">
      {steps.map((step, index) => {
        const isActive = index + 1 === currentStep;
        const isClickable = true;
        const hasError = errorSteps?.includes(index + 1);

        return (
          <div key={index} className="flex flex-col items-center">
            <button
              onClick={() => isClickable && onStepClick(index + 1)}
              type="button"
              className={`w-8 h-8 rounded-full font-header border-2 ${
                hasError
                  ? "border-reds-500 bg-reds-1000 text-reds-500"
                  : isActive
                  ? "border-secondary-100 bg-secondary-1100 text-secondary-100"
                  : step.completed
                  ? "border-accent-500 bg-accent-1000 text-accent-500"
                  : "border-gray-300 bg-gray-100 text-gray-500"
              }`}
              disabled={!isClickable}
            >
              0{index + 1}
            </button>
            <span
              className={`hidden text-xs mt-2 font-header ${
                hasError
                  ? "text-reds-500"
                  : isActive
                  ? "text-secondary-100"
                  : step.completed
                  ? "text-accent-500"
                  : "text-gray-500"
              }`}
            >
              {step.title}
            </span>
          </div>
        );
      })}
    </div>
  );
};

export default ProgressIndicator;