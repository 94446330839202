import { useState } from "react";
import {
  AttachmentOutlined,
  ListAltOutlined,
  TimelineOutlined,
  UnarchiveOutlined,
} from "@mui/icons-material";
import WorkOrderHistory from "../../updatedcomponents/data-display/WorkOrderHistory";
import Attachments from "../../updatedcomponents/data-display/Attachments";
import Details from "../../updatedcomponents/data-display/WorkOrderInfo";
import GenericModal from "../../updatedcomponents/modals/GenericModal";
import IncompleteNotes from "../../updatedcomponents/forms/UnableNotesForm";
import { useWorkOrder } from "../../hooks/useWorkOrder";
import { classNames } from "../../utils/FormatFunctions";
import Modal from "../../updatedcomponents/general-ui/Modal";
import { useAuth } from "../../hooks/useAuth";

const WorkOrderDetails: React.FC = ({}) => {
  const {
    selectedWorkOrder,
    handleWorkOrderStatusChange,
    handleDelete,
    handleReopen,
    modalShowState,
    updateModalShowState,
  } = useWorkOrder();

  const { currentUser } = useAuth();

  const dataToRender = [
    { title: "Title", dataTag: "title", dataType: "text" },
    {
      title: "Status",
      dataTag: "status",
      dataType: "selector",
      options: ["closed", "incomplete", "unable"],
    },
    {
      title: "Request Type",
      dataTag: "requestType",
      dataType: "selector",
      options: ["not-specified", "Electrical", "Plumbing", "HVAC", "Other"],
    },
    {
      title: "Date Reported",
      dataTag: "dateReported",
      dataType: "datetime-local",
    },
    { title: "Location", dataTag: "location", dataType: "text" },
    {
      title: "Urgency",
      dataTag: "urgency",
      dataType: "selector",
      options: ["urgent", "standard"],
    },
    { title: "Details", dataTag: "details", dataType: "textarea" },
    { title: "Requested By", dataTag: "requestedBy", dataType: "text" },
    { title: "Request Origin", dataTag: "requestOrigin", dataType: "text" },
  ];

  const [navItems, setNavItems] = useState([
    {
      name: "Details",
      icon: <ListAltOutlined style={{ fontSize: "1rem" }} />,
      component: Details,
      current: true,
      access: [
        "worker",
        "regional",
        "director",
        "administrator",
        "organization-admin",
        "system-admin",
      ],
    },
    {
      name: "Activity",
      component: WorkOrderHistory,
      icon: <TimelineOutlined style={{ fontSize: "1rem" }} />,
      current: false,
      access: [
        "regional",
        "director",
        "administrator",
        "organization-admin",
        "system-admin",
      ],
    },
    {
      name: `Attachments (${selectedWorkOrder?.attachments?.length || 0})`,
      component: Attachments,
      icon: <AttachmentOutlined style={{ fontSize: "1rem" }} />,
      current: false,
      access: [
        "worker",
        "regional",
        "director",
        "administrator",
        "organization-admin",
        "system-admin",
      ],
    },
  ]);

  const permissions = currentUser.access;

  const viewBar = () => {
    return (
      <div className="bg-primary py-1 flex sticky items-center justify-evenly w-full">
        {navItems.map((item, index) => {
          if (item.access.includes(permissions)) {
            return (
              <button
                key={index}
                onClick={() => {
                  setNavItems((prev) =>
                    prev.map((navItem) => {
                      return {
                        ...navItem,
                        current: navItem.name === item.name ? true : false,
                      };
                    })
                  );
                }}
                className={classNames(
                  item.current
                    ? "text-secondary-100 border-b-2 border-secondary-100"
                    : "text-secondary-600 hover:text-secondary-400 border-b border-secondary-1000 hover:border-secondary-700",
                  "px-3 py-4 text-sm font-medium w-full flex justify-center items-center gap-2"
                )}
              >
                {item.icon} {item.name}
              </button>
            );
          }
          return null;
        })}
      </div>
    );
  };

  return (
    <div className="relative px-4 sm:px-6 flex flex-col h-[calc(100vh-205px)] md:h-[calc(100vh-126px)] overflow-y-auto thin-scrollbar">
      {viewBar()}

      {navItems
        .filter((item) => item.current)
        .map((item, index) => (
          <div key={index} className="py-4">
            <item.component
              dataToRender={dataToRender}
              handleDeleteClick={() => updateModalShowState("showDelete", true)}
              handleReopenClick={() => updateModalShowState("showReopen", true)}
              handleWorkOrderStatusChange={handleWorkOrderStatusChange}
              handleClickClosed={() => {
                updateModalShowState("showCloseout", true);
              }}
            />
          </div>
        ))}

      <GenericModal
        title={"Delete this work order."}
        item={selectedWorkOrder}
        handleFunction={handleDelete}
        showModal={modalShowState.showDelete}
        setShowModal={() => updateModalShowState("showDelete", false)}
      />
      <GenericModal
        title={"Reopen this work order."}
        item={selectedWorkOrder}
        handleFunction={handleReopen}
        showModal={modalShowState.showReopen}
        setShowModal={() => updateModalShowState("showReopen", false)}
        color="secondary-100"
        secondaryColor="primary"
        buttonText="Reopen"
        ModalIcon={UnarchiveOutlined}
      />
      <Modal
        title="Mark as Unable to Complete"
        subtitle={selectedWorkOrder?.title}
        open={modalShowState.showUnable}
        setOpen={() =>
          updateModalShowState("showUnable", !modalShowState.showUnable)
        }
        content={
          <IncompleteNotes
            setShowNotes={() =>
              updateModalShowState("showUnable", !modalShowState.showUnable)
            }
          />
        }
      />
      <GenericModal
        title={"Close out this work order."}
        item={selectedWorkOrder}
        handleFunction={() =>
          handleWorkOrderStatusChange(null, selectedWorkOrder, "closed")
        }
        showModal={modalShowState.showCloseout}
        setShowModal={() =>
          updateModalShowState("showCloseout", !modalShowState.showCloseout)
        }
        color="secondary-100"
        secondaryColor={"secondary-1000"}
        buttonText="Close Out"
      />
    </div>
  );
};

export default WorkOrderDetails;
