import React from "react";

const Header: React.FC = () => {
  return (
    <header className="site-header">
      <div className="container-fluid fadeInUpInitial">
        <div className="row align-items-center">
          <div className="col">
            <a className="site-logo">
              <img
                className="w-100"
                src="/assets/images/site-logo.svg"
                alt=""
              />
            </a>
          </div>
          <div className="col-auto">
            <a className="btn btn-white btn-sm header-login-btn" href="/login">
              Login <span className="icon-arrow-right"></span>
            </a>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
