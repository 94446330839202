import apiClient from "../apiClient";

interface DateParams {
  displayText: string;
  startDate: Date | null;
  endDate: Date | null;
  selectedRange: string;
}
export const fetchRecurringTableData = async (
  facilityId: string,
  dateParams: DateParams
) => {
  try {
    const response = await apiClient.get(
      `/home/recurring-table/${facilityId}`,
      {
        params: {
          startDate: dateParams.startDate,
          endDate: dateParams.endDate,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching recurring table data:", error);
  }
};

export const fetchWorkOrderData = async (
  facilityId: string,
  dateParams: DateParams
) => {
  try {
    const response = await apiClient.get(`/home/work-orders/${facilityId}`, {
      params: {
        startDate: dateParams.startDate,
        endDate: dateParams.endDate,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching work order data:", error);
  }
};
