import apiClient from "../apiClient";

export const fetchTableData = async (facilityId: string, params: any) => {
  try {
    const response = await apiClient.get(`/analytics/table/${facilityId}`, {
      params,
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching table data: ", error);
  }
};

export const fetchChartData = async (facilityId: string, params: any) => {
  try {
    const response = await apiClient.get(`/analytics/chart/${facilityId}`, {
      params,
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching chart data: ", error);
    return { workOrders: [], recurringTasks: [] };
  }
};
