import React from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useDropzone } from "react-dropzone";
import Button from "../general/Button";
import { PencilIcon, PhotoIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { useSettings } from "../../hooks/useSettings";


export interface ImageFormData {
  photo?: File;
}

const validationSchema = Yup.object().shape({});

const UploadImage: React.FC = () => {
  const { onSubmitAvatar, imageState, updateImageState } = useSettings();

  const {
    handleSubmit,
    setValue,
    formState: { errors, isSubmitting },
  } = useForm<ImageFormData>({
    resolver: yupResolver(validationSchema),
  });

  const onDrop = (acceptedFiles: File[]) => {
    const file = acceptedFiles[0];
    setValue("photo", file);
    updateImageState("selectedImage", URL.createObjectURL(file));
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: { "image/jpeg": [], "image/png": [] },
  });

  return (
    <form onSubmit={handleSubmit(onSubmitAvatar)}>
      {imageState.selectedImage ? (
        <div className="group relative flex items-center justify-center rounded-full w-36 h-36 mx-auto cursor-pointer">
          <img
            src={imageState.selectedImage}
            alt="avatar"
            className="w-36 h-36 rounded-full object-cover transition-opacity duration-300 group-hover:opacity-30"
          />
          <div className="absolute inset-0 flex items-center divide-x-2 divide-secondary justify-center gap-2 opacity-0 transition-opacity duration-300 group-hover:opacity-100">
            <button
              type="button"
              onClick={() => {
                updateImageState("selectedImage", null);
                updateImageState("isImageRemoved", true);
              }}
            >
              <XMarkIcon className="w-12 h-12 text-reds-500  p-2" />
            </button>
            <div {...getRootProps()}>
              <input {...getInputProps()} />
              <button type="button">
                <PencilIcon className="w-12 h-12 text-secondary p-2" />
              </button>
            </div>
          </div>
        </div>
      ) : (
        <div
          {...getRootProps()}
          className="cursor-pointer group w-36 h-36 rounded-full mx-auto"
        >
          <input {...getInputProps()} />
          <div className="flex items-center justify-center w-36 h-36 mx-auto bg-gray-100 rounded-full ">
            <PhotoIcon className="w-12 h-12 transition-colors duration-300 text-secondary group-hover:text-secondary-300" />
          </div>
        </div>
      )}
      {errors.photo && (
        <p className="text-reds-500 text-sm text-center mt-1">
          {errors.photo.message}
        </p>
      )}
      <Button
        type="submit"
        styleString="primary"
        children="Save Changes"
        overrideStyles="w-full mt-4"
        disabled={isSubmitting}
      />
    </form>
  );
};

export default UploadImage;
