import React from "react";
import { DeleteOutline, EditOutlined, MapOutlined } from "@mui/icons-material";
import { Map } from "../../types/Map";
import { capitalizeString } from "../../utils/FormatFunctions";
import { useBuilding } from "../../hooks/useBuilding";

interface MapListProps {}

const MapList: React.FC<MapListProps> = ({}) => {
  const { maps, setSelectedMap, handleShowMap, updateShowState } =
    useBuilding();

  return (
    <div className="w-full">
      <div className="mt-4 flex gap-3">
        <div className="flex flex-row gap-4">
          <MapOutlined
            style={{ fontSize: "3.5rem" }}
            className="text-secondary-100 bg-secondary-1100 rounded-full p-2"
          />
          <div className="flex flex-col">
            <h2 className="text-xl font-semibold text-secondary-100">Maps</h2>
            <p className="text-sm text-secondary-500 font-light">
              View and manage your buildings assets
            </p>
          </div>
        </div>
      </div>
      <ul
        role="list"
        className="divide-y divide-secondary-1000  pt-4 pb-2 px-2 border-b border-secondary-1000"
      >
        {maps.length > 0 ? (
          maps.map((map: Map) => (
            <li
              key={map._id}
              className="flex items-center justify-between gap-x-6 py-5"
            >
              <div className="min-w-0">
                <div className="flex items-start gap-x-3">
                  <p className="text-sm font-semibold leading-6 text-secondary-100">
                    {capitalizeString(map.name)}
                  </p>
                </div>
              </div>
              <div className="flex flex-none items-center gap-x-4">
                <button
                  className="bg-secondary-1000 px-2.5 py-0.5 rounded-sm flex justify-center items-center gap-x-1 text-sm font-normal text-secondary-100 shadow-sm hover:bg-secondary-1100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-accent-500"
                  onClick={() => handleShowMap(map)}
                >
                  <EditOutlined style={{ fontSize: "1rem" }} />
                  Edit
                </button>
                
                <button
                  className="bg-secondary-1100 text-reds-500 px-0.5 py-0.5 rounded-sm flex justify-center items-center gap-x-1 text-sm font-semibold border border-secondary-1100  shadow-sm hover:bg-gray-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-reds-500"
                  onClick={() => {
                    setSelectedMap(map);
                    updateShowState("showDelete", true);
                  }}
                >
                  <DeleteOutline style={{ fontSize: "1rem" }} />
                </button>
              </div>
            </li>
          ))
        ) : (
          <div className="p-4">
            <h2 className="text-md text-secondary-500 italic">
              No maps present
            </h2>
          </div>
        )}
      </ul>
     
    </div>
  );
};

export default MapList;
