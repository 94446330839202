import { Switch } from "@headlessui/react";
import { Add, Remove } from "@mui/icons-material";
import React from "react";
import { Control, Controller, UseFormRegister } from "react-hook-form";
import { extractFilenameFromUrl } from "../../utils/FormatFunctions";
import Button from "../general/Button";

interface Option {
  label: string;
  value: string;
}
export type InputType =
  | "text"
  | "number"
  | "email"
  | "password"
  | "date"
  | "textarea"
  | "select"
  | "radio"
  | "radio-na"
  | "checkbox"
  | "switch"
  | "file";

interface InputProps {
  name: string;
  type: InputType;
  label: string;
  options?: string[] | Option[];
  register?: UseFormRegister<any>;
  control?: Control<any>;
  errors?: any;
  placeholder?: string;
  showLabel?: boolean;
  disabled?: boolean;
  onBlur?: () => void;
  fileProps?: {
    files: File[];
    setFiles: React.Dispatch<React.SetStateAction<File[]>>;
    handleViewFile: (file: string) => void;
  };
}

const Input: React.FC<InputProps> = ({
  name,
  type,
  label,
  options = [],
  register,
  errors,
  placeholder = "",
  showLabel = true,
  control,
  disabled = false,
  fileProps: { files, setFiles, handleViewFile } = {
    files: [],
    setFiles: () => {},
    handleViewFile: () => {},
  },
}) => {
  let inputElement;
  switch (type) {
    case "textarea":
      inputElement = register ? (
        <textarea
          placeholder={placeholder}
          className={`block w-full focus:ring-2 focus:ring-inset focus:ring-accent-500 rounded border-0 bg-transparent py-1.5 shadow-sm ring-1 ring-inset sm:text-sm sm:leading-6 ${
            errors
              ? "ring-reds-600 ring-2 text-reds-600"
              : "ring-secondary-800 text-secondary-100"
          }`}
          {...register(name)}
        />
      ) : null;
      break;
    case "select":
      inputElement = register ? (
        <select
          className={`block w-full rounded border-0 bg-transparent py-1.5 shadow-sm ring-1 ring-inset focus:ring-2 focus:ring-inset focus:ring-accent-500 sm:text-sm sm:leading-6 ${
            errors
              ? "ring-reds-600 ring-2 text-reds-600"
              : "ring-secondary-800 text-secondary-100"
          }`}
          {...register(name)}        >
            <option value="" disabled>
              Select an option
            </option>
          {options.map((option, index) =>
            typeof option === "string" ? (
              <option className="text-secondary" key={index} value={option}>
                {option}
              </option>
            ) : (
              <option
                className="text-secondary"
                key={index}
                value={option.value}
              >
                {option.label}
              </option>
            )
          )}
        </select>
      ) : null;
      break;
    case "radio":
    case "radio-na":
      inputElement = register ? (
        <div className="flex  justify-end gap-4 ">
          {options.map((option, index) =>
            typeof option === "string" ? (
              <label key={index} className="inline-flex items-center">
                <input
                  type="radio"
                  value={option}
                  className="form-radio text-secondary-100 focus:ring-secondary-100 bg-transparent"
                  {...register(name)}
                />
                <span className="ml-2">{option}</span>
              </label>
            ) : (
              <label key={index} className="inline-flex items-center">
                <input
                  type="radio"
                  value={option.value}
                  className="form-radio text-secondary-100 focus:ring-secondary-100 bg-transparent"
                  {...register(name)}
                />
                <span className="ml-2">{option.label}</span>
              </label>
            )
          )}
        </div>
      ) : null;
      break;
    case "checkbox":
      inputElement = register ? (
        <div className="flex flex-col space-y-2">
          {options.map((option, index) =>
            typeof option === "string" ? (
              <label key={index} className="inline-flex items-center">
                <input
                  type="checkbox"
                  className="form-checkbox rounded text-secondary-100 focus:ring-secondary-100 bg-transparent"
                  {...register(name)}
                />
                <span className="ml-2">{option}</span>
              </label>
            ) : (
              <label key={index} className="inline-flex items-center">
                <input
                  type="checkbox"
                  className="form-checkbox rounded text-secondary-100 focus:ring-secondary-100 bg-transparent"
                  {...register(name)}
                />
                <span className="ml-2">{option.label}</span>
              </label>
            )
          )}
        </div>
      ) : null;
      break;
    case "switch":
      inputElement = control ? (
        <Controller
          name={name}
          control={control}
          render={({ field: { onChange, value } }) => (
            <Switch
              checked={value}
              onChange={onChange}
              className={`group flex w-8 flex-none cursor-pointer ui-checked:bg-accent-500 ui-not-checked:bg-secondary-1000 rounded-full p-px ring-1 ring-inset ring-secondary-100/5 transition-colors duration-200 ease-in-out focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-accent-500`}
            >
              <span className="sr-only">{label}</span>
              <span
                aria-hidden="true"
                className="h-4 w-4 transform rounded-full bg-white shadow-sm ring-1 ring-secondary-100/5 transition duration-200 ease-in-out ui-checked:translate-x-3.5"
              />
            </Switch>
          )}
        />
      ) : null;
      break;
    case "number":
      inputElement = control ? (
        <Controller
          name={name}
          control={control}
          render={({ field }) => {
            const currentValue = Number(field.value || 0);
            return (
              <div className="flex justify-between items-center rounded border border-secondary-800">
                <button
                  type="button"
                  onClick={() => {
                    const newValue = currentValue - 1;
                    field.onChange(newValue);
                  }}
                  className="px-2"
                >
                  <Remove fontSize="small" />
                </button>
                <input
                  type="number"
                  {...field}
                  onChange={(e) => {
                    const value = e.target.value;
                    field.onChange(value === "" ? "" : Number(value));
                  }}
                  className="max-w-24 [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none p-2 border-none focus:ring-0 text-center"
                  value={field.value ?? ""}
                />
                <button
                  type="button"
                  onClick={() => {
                    const newValue = currentValue + 1;
                    field.onChange(newValue);
                  }}
                  className="px-2"
                >
                  <Add fontSize="small" />
                </button>
              </div>
            );
          }}
        />
      ) : null;
      break;
    case "file":
      inputElement = control ? (
        <Controller
          name={name}
          control={control}
          render={({ field }) => {
            const filesFields = Array.isArray(field.value)
              ? field.value
              : field.value
              ? [field.value]
              : [];
            return (
              <>
                <div className="flex flex-col max-h-48 gap-y-2 overflow-y-auto thin-scrollbar">
                  {filesFields.map((file: string, fileIndex: number) => (
                    <div
                      key={fileIndex}
                      className="flex items-center justify-between gap-2 bg-secondary-1100 rounded-sm w-full divide-x divide-secondary-800"
                    >
                      <span
                        onClick={() => handleViewFile(file)}
                        className="cursor-pointer px-2 text-xs text-secondary-100 truncate "
                      >
                        {extractFilenameFromUrl(file)}
                      </span>
                      <Button
                        icon="Close"
                        onClick={() => {
                          const newFiles = filesFields.filter(
                            (_, i) => i !== fileIndex
                          );
                          field.onChange(newFiles);
                          setFiles(files.filter((f) => f.name !== file));
                        }}
                        styleString="minimal"
                        children=""
                        overrideStyles="text-reds-400"
                      />
                    </div>
                  ))}
                </div>
                <input
                  type="file"
                  onChange={(e) => {
                    if (e.target.files && e.target.files.length > 0) {
                      const newFile = e.target.files[0];
                      setFiles((prevFiles) => [...prevFiles, newFile]);

                      const currentFiles = field.value || [];
                      const newValue = [
                        ...(Array.isArray(currentFiles)
                          ? currentFiles
                          : [currentFiles]),
                        newFile.name,
                      ];
                      field.onChange(newValue);
                    }
                    e.target.value = "";
                  }}
                  className="hidden"
                  id={name}
                />
                <label className="cursor-pointer flex w-full justify-center">
                  <Button
                    onClick={() => {
                      const fileInput = document.getElementById(name);
                      if (fileInput) {
                        fileInput.click();
                      }
                    }}
                    styleString="bold"
                    children="Upload File"
                    icon="CloudUpload"
                    overrideStyles="w-full"
                  />
                </label>
              </>
            );
          }}
        />
      ) : null;
      break;
    default:
      inputElement = register ? (
        <input
          type={type}
          placeholder={placeholder}
          disabled={disabled}
          className={`block w-full rounded border-0 py-1.5 shadow-sm ring-1 ring-inset focus:ring-2 focus:ring-inset focus:ring-accent-500 sm:text-sm sm:leading-6 ${
            errors
              ? "ring-reds-600 ring-2 text-reds-600"
              : "text-secondary-100 ring-secondary-800"
          } ${
            disabled
              ? "bg-secondary-1100 text-secondary-500 cursor-not-allowed"
              : "bg-transparent "
          }`}
          {...register(name)}
        />
      ) : null;
      break;
  }

  return (
    <div className="bg-primary">
      {showLabel && (
        <label
          htmlFor={name}
          className="block text-sm font-medium leading-6 mb-2"
        >
          {label}
        </label>
      )}
      <div className="bg-transparent">{inputElement}</div>
      {errors && (
        <span className="text-reds-600 text-sm">{errors.message}</span>
      )}
    </div>
  );
};

export default Input;
{
  /* <Controller
            name={`${name}`}
            control={control}
            render={({ field }) => (
              <div className="flex w-1/2 justify-end gap-2">
                <label
                  className={classNames(
                    "flex max-w-36 flex-1 items-center gap-4 rounded py-1.5 px-3",
                    {
                      "bg-accent-1100 border-accent-500 border text-secondary-100":
                        field.value === "Pass",
                    }
                  )}
                >
                  <input
                    type="radio"
                    value="Pass"
                    className={classNames({
                      "text-accent-300 h-4 w-4 border-secondary-1000  focus:ring-accent-300":
                        field.value === "Pass",
                    })}
                    checked={field.value === "Pass"}
                    onChange={() => field.onChange("Pass")}
                  />
                  <span>Pass</span>
                </label>
                <label
                  className={classNames(
                    "flex max-w-36 flex-1 items-center gap-4 rounded py-1.5 px-3",
                    {
                      "bg-reds-1100 border-reds-500 border text-secondary-100":
                        field.value === "Fail",
                    }
                  )}
                >
                  <input
                    type="radio"
                    value="Fail"
                    className={classNames({
                      "text-reds-300 h-4 w-4 border-secondary-1000  focus:ring-reds-300":
                        field.value === "Fail",
                    })}
                    checked={field.value === "Fail"}
                    onChange={() => field.onChange("Fail")}
                  />
                  <span>Fail</span>
                </label>
                {dataType === "radio-na" && (
                  <label
                    className={classNames(
                      "flex max-w-36 flex-1 items-center gap-4 rounded py-1.5 px-3",
                      {
                        "bg-secondary-1100 border-secondary-500 border text-secondary-100":
                          field.value === "N/A",
                      }
                    )}
                  >
                    <input
                      type="radio"
                      value="N/A"
                      className={classNames({
                        "text-secondary-300 h-4 w-4 border-secondary-1000  focus:ring-secondary-300":
                          field.value === "N/A",
                      })}
                      checked={field.value === "N/A"}
                      onChange={() => field.onChange("N/A")}
                    />
                    <span>N/A</span>
                  </label>
                )}
              </div>
            )}
          /> */
}
