import React from "react";
import { Link } from "react-router-dom";

const Complete = () => {
  return (
    <div className="flex flex-col items-center min-h-48 justify-center">
      <h2 className="text-2xl font-light animate-fade-in-left">
        Congratulations! You're all set.
      </h2>
      <p className="text-sm pt-4 font-light animate-fade-in-left text-center leading-6">
        Please give our team some time to review and get you setup.
      </p>
      <Link to="/" className="text-accent-500 mt-8 animate-fade-in-left">
        Go to Home
      </Link>
    </div>
  );
};

export default Complete;
