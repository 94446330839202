import React, { useState, useContext, useRef } from "react";
import axios from "axios";
import { useForm, Controller } from "react-hook-form";
import { FacilityContext } from "../../context/FacilityContext";
import { Close } from "@mui/icons-material";
import { useFacility } from "../../hooks/useFacility";
import { useBuilding } from "../../hooks/useBuilding";

interface FileUploadProps {}

const FileUpload: React.FC<FileUploadProps> = ({}) => {
  const { updateShowState, fileType, handleCreateFile } = useBuilding();
  
  const {
    handleSubmit,
    control,
    watch,
    formState: { isSubmitting },
  } = useForm<{ file: FileList }>();

  const fileInputRef = useRef<HTMLInputElement>(null);

  const files = watch("file");

  const selectedFile = files && files.length > 0 ? files[0] : null;

 

  return (
    <form onSubmit={handleSubmit(handleCreateFile)}>
      <Controller
        name="file"
        control={control}
        defaultValue={undefined}
        render={({ field }) => (
          <div
            className="cursor-pointer relative block w-full rounded-lg border-2 border-dashed border-gray-300 p-12 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-accent-100 focus:ring-offset-2"
            onDragOver={(e) => e.preventDefault()}
            onDrop={(e) => {
              e.preventDefault();
              field.onChange(e.dataTransfer.files);
            }}
            onClick={() => fileInputRef.current && fileInputRef.current.click()}
          >
            <input
              type="file"
              ref={fileInputRef}
              onChange={(e) => field.onChange(e.target.files)}
              style={{ display: "none" }}
            />
            <svg
              className="mx-auto h-12 w-12 text-gray-400"
              stroke="currentColor"
              fill="none"
              viewBox="0 0 48 48"
              aria-hidden="true"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M8 14v20c0 4.418 7.163 8 16 8 1.381 0 2.721-.087 4-.252M8 14c0 4.418 7.163 8 16 8s16-3.582 16-8M8 14c0-4.418 7.163-8 16-8s16 3.582 16 8m0 0v14m0-4c0 4.418-7.163 8-16 8S8 28.418 8 24m32 10v6m0 0v6m0-6h6m-6 0h-6"
              />
            </svg>
            {selectedFile ? (
              <p className="w-52 mt-2 block text-sm font-semibold text-gray-900">
                Selected File: {selectedFile.name}
              </p>
            ) : (
              <p className="w-52 mt-2 block text-sm font-semibold text-gray-900">
                Drag and drop a {fileType} here or click to select
              </p>
            )}
          </div>
        )}
      />
      <div className="flex gap-2 w-full">
        <button
          type="button"
          className="flex-1 rounded-sm bg-gray-200 mt-6 px-5 py-1 text-sm font-semibold text-gray-900 shadow-sm hover:bg-gray-300 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-900"
          onClick={() => updateShowState("showFileAdd", false)}
          disabled={isSubmitting}
        >
          Cancel
        </button>
        <button
          type="submit"
          className="flex-1 rounded-sm bg-secondary mt-6 px-5 py-1 text-sm font-semibold text-white shadow-sm hover:bg-secondary focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-secondary"
          disabled={isSubmitting}
        >
          Upload
        </button>
      </div>
    </form>
  );
};

export default FileUpload;
