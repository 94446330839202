import React, { Fragment, useState, useEffect, useContext } from "react";
import { Dialog, Transition } from "@headlessui/react";
import axios from "axios";
import { FacilityContext } from "../../context/FacilityContext";
import * as XLSX from "xlsx";
import { Add, Close, UploadFile } from "@mui/icons-material";
import { useBuilding } from "../../hooks/useBuilding";
import Button from "../general/Button";
import { useForm, useFieldArray, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Input from "../general-ui/Input";

interface CreateMapFormProps {}

interface RowData {
  ID: string;
  [key: string]: string;
}

const schema = yup.object().shape({
  collectionTitle: yup.string().required("Map title is required"),
  rows: yup.array().of(
    yup
      .object()
      .shape({
        ID: yup.string().required("ID is required"),
        // Add other fields validation here if needed
      })
      .test(
        "no-empty-strings",
        "Fields cannot contain empty strings",
        function (row, context) {
          const rowIndex = context.parent.indexOf(row);
          return Object.entries(row).every(([key, value]) => {
            if (typeof value !== "string" || value.trim() === "") {
              throw new yup.ValidationError(
                `Field '${key}' in row ${
                  rowIndex + 1
                } cannot contain empty strings`,
                value,
                `rows[${rowIndex}].${key}`
              );
            }
            return true;
          });
        }
      )
  ),
  selectedSheet: yup.string(),
});

const CreateMapForm: React.FC<CreateMapFormProps> = ({}) => {
  const { showState, updateShowState, selectedMap, onSubmitMap } =
    useBuilding();

  const [headerData, setHeaderData] = useState<string[]>(["ID", ""]);
  const [sheetOptions, setSheetOptions] = useState<string[]>([]);
  const [workbook, setWorkbook] = useState<XLSX.WorkBook | null>(null);

  interface FormValues {
    collectionTitle: string;
    rows?: RowData[];
    selectedSheet?: string;
  }

  const {
    control,
    handleSubmit,
    setValue,
    watch,
    register,
    formState: { errors, isSubmitSuccessful, isSubmitting },
    reset,
  } = useForm<FormValues>({
    resolver: yupResolver(schema),
    defaultValues: {
      collectionTitle: "",
      rows: [{ ID: "1" }],
    },
  });
  const selectedSheet = watch("selectedSheet");

  const { fields, append, remove } = useFieldArray({
    control,
    name: "rows",
  });

  useEffect(() => {
    if (typeof selectedMap === "undefined" || !selectedMap) {
      return;
    }
    const { fields, name } = selectedMap;

    setValue("collectionTitle", name);
    if (fields && fields.length > 0) {
      const headers = Object.keys(fields[0]);
      const rows = fields;
      setHeaderData(headers);
      setValue(
        "rows",
        rows.map((row) => ({ ID: row.ID || "", ...row }))
      );
    }
  }, [selectedMap, setValue]);

  const handleHeaderChange = (index: number, newValue: string) => {
    const oldHeader = headerData[index];
    const newHeaders = [...headerData];
    newHeaders[index] = newValue;
    setHeaderData(newHeaders);

    const rows = watch("rows") || [];
    const newRows = rows.map((row: RowData) => {
      if (oldHeader in row) {
        const value = row[oldHeader];
        delete row[oldHeader];
        return { ...row, [newValue]: value, ID: row.ID || "" };
      }
      return { ...row, ID: row.ID || "" };
    });
    setValue("rows", newRows);
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (files && files.length > 0) {
      const file = files[0];
      readExcel(file);
    }
  };

  const readExcel = (file: File) => {
    const reader = new FileReader();

    reader.onload = (e) => {
      const arrayBuffer = e.target?.result as ArrayBuffer;
      const data = new Uint8Array(arrayBuffer);
      const workbook = XLSX.read(data, { type: "array" });
      setWorkbook(workbook);

      const sheetNames = workbook.SheetNames;
      setSheetOptions(sheetNames);

      if (!selectedSheet || !sheetNames.includes(selectedSheet)) {
        setHeaderData(["ID", ""]);
        setValue("rows", [{ ID: "1" }]);
        console.log("Invalid sheet selection or canceled.");
        return;
      }

      const sheet = workbook.Sheets[selectedSheet];
      processSheetData(sheet);
    };

    reader.readAsArrayBuffer(file);
  };

  useEffect(() => {
    if (workbook && selectedSheet) {
      const sheet = workbook.Sheets[selectedSheet];
      processSheetData(sheet);
    }
  }, [selectedSheet, workbook]);

  const processSheetData = (sheet: XLSX.WorkSheet | undefined) => {
    let headers: string[];
    let rows: RowData[];

    if (sheet) {
      const excelRows = XLSX.utils.sheet_to_json<string[]>(sheet, {
        header: 1,
      });

      // Filter out empty rows
      const nonEmptyRows = excelRows.filter((row) =>
        row.some((cell) => cell !== null && cell !== "")
      );

      headers = nonEmptyRows[0] as string[];
      rows = nonEmptyRows.slice(1).map((row) => {
        const rowObj: RowData = { ID: "" };
        headers.forEach((header, index) => {
          rowObj[header] = String(row[index]);
        });
        return rowObj;
      });
    } else {
      headers = ["ID", ""];
      rows = [{ ID: "1" }];
    }
    setHeaderData(headers);
    setValue("rows", rows);

    // Set the title to the selected sheet's name
    setValue("collectionTitle", selectedSheet || "");
  };

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset();
    }
  }, [isSubmitSuccessful]);

  return (
    <form onSubmit={handleSubmit(onSubmitMap)}>
      <div>
        <Input
          label="Map Title"
          name="collectionTitle"
          register={register}
          placeholder="Enter a map title"
          type="text"
          errors={errors.collectionTitle}
        />

        <div className="mt-4 flex justify-center items-start align-middle max-h-96 overflow-auto thin-scrollbar">
          <table className="table-auto w-full">
            <thead className="bg-secondary-1100">
              <tr>
                {headerData.map((headerCell, colIndex) => (
                  <th
                    key={colIndex}
                    className={`w-1/2 border-secondary-1000 py-2 ${
                      colIndex > 0 ? "border-y" : "border"
                    }`}
                  >
                    <input
                      type="text"
                      className="bg-inherit border-none focus:ring-0 p-1 w-full font-medium text-xs"
                      value={headerCell}
                      disabled={headerCell === "ID"}
                      onChange={(e) =>
                        handleHeaderChange(colIndex, e.target.value)
                      }
                    />
                  </th>
                ))}
                <th className="border-secondary-1000 border-t border-r border-b" />
              </tr>
            </thead>
            <tbody>
              {fields.map((row, rowIndex) => (
                <Fragment key={row.id}>
                  <tr key={`${row.id}-gap`} className="h-2"></tr>
                  <tr key={rowIndex}>
                    {headerData.map((headerCell) => (
                      <td key={headerCell} className="w-1/2 border">
                        <Controller
                          name={`rows.${rowIndex}.${headerCell}`}
                          control={control}
                          render={({ field }) => (
                            <>
                              <input
                                className={` focus:border-secondary-1000 w-full ${
                                  errors.rows?.[rowIndex]?.[headerCell]
                                    ? "border-2 border-reds-500"
                                    : "border-none"
                                } focus:ring-accent-500 ring-0 text-xs`}
                                type="text"
                                {...field}
                                value={
                                  typeof field.value === "string"
                                    ? field.value
                                    : ""
                                }
                              />
                            </>
                          )}
                        />
                      </td>
                    ))}
                    <td className="flex items-center">
                      <button
                        type="button"
                        onClick={() =>
                          append({ ID: String(fields.length + 1) })
                        }
                        className="p-1 border-none outline-none focus:outline-none focus:border-none focus:ring-accent-400 text-accent-500"
                      >
                        <Add style={{ fontSize: "1rem" }} />
                      </button>
                      <button
                        type="button"
                        onClick={() => remove(rowIndex)}
                        className="p-1 border-none outline-none focus:outline-none focus:border-none focus:ring-reds-600 text-reds-500"
                      >
                        <Close style={{ fontSize: "1rem" }} />
                      </button>
                    </td>
                  </tr>
                </Fragment>
              ))}
            </tbody>
          </table>
        </div>
        <button
          type="button"
          onClick={() => append({ ID: String(fields.length + 1) })}
          className="my-4 w-full bg-primary px-2 py-1 text-sm font-semibold text-secondary-100 shadow-sm ring-1 ring-inset ring-secondary-500 hover:bg-secondary-1200"
        >
          + Add Row
        </button>
        <div className="relative flex items-center justify-center w-full">
          <div className="absolute left-0 right-0 h-0.5 bg-secondary-1000" />
          <span className="relative text-secondary-700 px-2 bg-primary text-sm">
            Or
          </span>
        </div>
        <label
          htmlFor="file-input"
          className="bg-secondary-1100 mx-auto flex align-middle items-center text-sm gap-2 text-center justify-center w-full text-secondary-100 cursor-pointer px-2 py-0.5 m-2 border border-secondary-1000"
        >
          <UploadFile style={{ fontSize: "1rem" }} />
          Import from excel file
          <input
            type="file"
            id="file-input"
            accept=".xls, .xlsx"
            onChange={handleFileChange}
            className="hidden"
          />
        </label>
        {sheetOptions.length > 0 && (
          <div className="mb-2">
            <Input
              type="select"
              label="Sheet"
              name="selectedSheet"
              register={register}
              options={sheetOptions}
            />
          </div>
        )}
        <div className="flex gap-2">
          <Button
            onClick={() => updateShowState("showMapCreate", false)}
            styleString="secondary"
            children="Cancel"
          />
          <Button
            type="submit"
            styleString="primary"
            children="Save"
            disabled={isSubmitting}
          />
        </div>
      </div>
    </form>
  );
};

export default CreateMapForm;
