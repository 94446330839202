import React from "react";
import {
  Add,
  PersonOutline,
  CheckOutlined,
  CloseOutlined,
  WarningAmberOutlined,
  DoneAllOutlined,
  QuestionMarkOutlined,
} from "@mui/icons-material";
import { formatDate, classNames } from "../../utils/FormatFunctions";
import { useWorkOrder } from "../../hooks/useWorkOrder";

interface WorkOrder {
  history: {
    action: string;
    timestamp: string;
    assignedTo: { firstname: string; lastname: string } | null;
    completedBy: { firstname: string } | null;
  }[];
}

interface History {
  action: string;
  timestamp: string;
  assignedTo: { firstname: string; lastname: string } | null;
  completedBy: { firstname: string } | null;
  id: number;
  text: string;
  icon: React.ElementType;
  iconColor: string;
  iconBackground: string;
}

const WorkOrderHistory: React.FC<{}> = ({}) => {
  const { selectedWorkOrder } = useWorkOrder();
  function appendIconToHistory(history: WorkOrder["history"]): History[] {
    if (!history) {
      return [];
    }

    return history.map((item, index) => {
      let icon: React.ElementType = QuestionMarkOutlined;
      let iconColor = "bg-secondary-100";
      let iconBackground = "bg-primary";
      let text = "default action";
      let id = index;

      switch (item.action) {
        case "created":
          text = "Work order created.";
          icon = Add;
          iconColor = "bg-secondary-100";
          iconBackground = "bg-secondary-1000";
          break;
        case "assigned":
          text = "assigned work order to ";
          icon = PersonOutline;
          iconColor = "bg-accent-300";
          iconBackground = "bg-accent-1100";
          break;
        case "complete":
          text = " marked as complete";
          icon = CheckOutlined;
          iconColor = "bg-accent-500";
          iconBackground = "bg-accent-900";
          break;
        case "incomplete":
          text = " marked as incomplete";
          icon = CloseOutlined;
          iconColor = "bg-reds-700";
          iconBackground = "bg-reds-1000";
          break;
        case "unable":
          text = " marked as unable to complete";
          icon = WarningAmberOutlined;
          iconColor = "bg-reds-200";
          iconBackground = "bg-reds-1100";
          break;
        case "closed":
          text = " marked as closed";
          icon = DoneAllOutlined;
          iconColor = "bg-accent-300";
          iconBackground = "bg-accent-1000";
          break;
      }
      return { ...item, id, text, icon, iconColor, iconBackground };
    });
  }

  const updatedHistory = selectedWorkOrder
    ? appendIconToHistory(
        selectedWorkOrder.history.map((item) => ({
          ...item,
          timestamp:
            item.timestamp instanceof Date
              ? item.timestamp.toISOString()
              : item.timestamp,
          assignedTo:
            item.assignedTo && typeof item.assignedTo === "object"
              ? item.assignedTo
              : null,
          completedBy:
            item.completedBy && typeof item.completedBy === "object"
              ? item.completedBy
              : null,
        }))
      )
    : [];

  return (
    <ul role="list" className="w-full">
      {updatedHistory.map((history, historyIdx: number) => (
        <li key={history.id}>
          <div className="relative pb-8">
            {historyIdx !== updatedHistory.length - 1 ? (
              <span
                className="absolute left-2 top-4 -ml-px h-full w-0.5 bg-secondary-1000"
                aria-hidden="true"
              />
            ) : null}
            <div className="relative flex space-x-3">
              <div className="flex align-middle items-center">
                <span
                  className={classNames(
                    history.iconColor,
                    "rounded-full flex align-middle justify-center ring-8 ring-white"
                  )}
                >
                  <history.icon
                    style={{ fontSize: "1.1rem" }}
                    className={classNames(
                      history.iconBackground,
                      "rounded-full"
                    )}
                    aria-hidden="true"
                  />
                </span>
              </div>
              <div className="flex min-w-0 flex-1 justify-between space-x-4">
                <div>
                  <p className="text-sm text-gray-500">
                    {history.completedBy?.firstname} {history.text}{" "}
                    {history.assignedTo !== null && (
                      <span className="font-medium text-gray-900">
                        {history.assignedTo?.firstname}{" "}
                        {history.assignedTo?.lastname}
                      </span>
                    )}
                  </p>
                </div>
                <div className="whitespace-nowrap text-right text-sm text-gray-500">
                  <time dateTime={history.timestamp}>
                    {formatDate(history.timestamp, "MMM D, YYYY h:mm a")}
                  </time>
                </div>
              </div>
            </div>
          </div>
        </li>
      ))}
    </ul>
  );
};

export default WorkOrderHistory;
