import React, { Fragment, useState, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { Close } from "@mui/icons-material";
import { useWorkOrder } from "../../hooks/useWorkOrder";

interface FilterOption {
  name: string;
  label: string;
  type: string;
  joinId?: string;
  options?: { value: string; label: string }[];
}

interface FilterModalProps {
  show: boolean;
  setShow: (show: boolean) => void;
  filterOptions: FilterOption[];
  filters: string;
  applyFilters: (filters: { [key: string]: any }) => void;
  resetFilters: () => void;
}

const FilterModal: React.FC<FilterModalProps> = ({
  show,
  setShow,
  filterOptions,
  filters,
  applyFilters,
  resetFilters,
}) => {
  const filtersString =
    typeof filters === "string" ? filters : JSON.stringify(filters);
  const parsedFilters = JSON.parse(filtersString || "{}");
  const [localFilters, setLocalFilters] = useState<{ [key: string]: any }>(
    parsedFilters
  );
  const [dateError, setDateError] = useState<string>("");

  // useEffect to update localFilters when filters prop changes
  useEffect(() => {
    try {
      const newParsedFilters = JSON.parse(filtersString || "{}");
      setLocalFilters(newParsedFilters);
    } catch (error) {
      setLocalFilters({});
    }
  }, [filtersString]); // Watch filtersString for changes

  const validateDates = (startDate: string, endDate: string): string => {
    if (startDate && endDate) {
      const start = new Date(startDate);
      const end = new Date(endDate);
      if (start > end) {
        return "End date cannot be before start date";
      }
    }
    return "";
  };

  const handleLocalFilterChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value, type } = event.target as HTMLInputElement;
    const checked = (event.target as HTMLInputElement).checked;

    setLocalFilters((prevFilters) => {
      const updatedFilters = { ...prevFilters };

      if (type === "checkbox") {
        if (checked) {
          updatedFilters[name] = checked;
        } else {
          delete updatedFilters[name];
        }
      } else {
        updatedFilters[name] = value;
      }

      // Validate dates when either date field changes
      if (name === "dueDateFrom" || name === "dueDateTo") {
        const startDate =
          name === "dueDateFrom" ? value : updatedFilters.dueDateFrom;
        const endDate = name === "dueDateTo" ? value : updatedFilters.dueDateTo;

        const error = validateDates(startDate, endDate);
        setDateError(error);
      }

      return updatedFilters;
    });
  };

  const handleApplyFilters = () => {
    // Only apply filters if there's no date error
    if (!dateError) {
      applyFilters(localFilters);
      setShow(false);
    }
  };

  const handleResetFilters = () => {
    resetFilters();
    setLocalFilters({});
    setShow(false);
  };

  const renderDateFields = (
    option: FilterOption,
    pairedField: FilterOption
  ) => (
    <div key={option.joinId} className="flex-1">
      <div className="flex gap-4">
        {/* Start Date Field */}
        <div className="flex-1">
          <label
            htmlFor={option.name}
            className="block truncate text-sm font-medium leading-6 text-gray-900"
          >
            {option.label}
          </label>
          <input
            type="date"
            name={option.name}
            id={option.name}
            className={`mt-2 block w-full rounded-sm border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ${
              dateError ? "ring-red-500" : "ring-gray-300"
            } focus:ring-2 focus:ring-accent-100 sm:text-sm sm:leading-6`}
            value={localFilters[option.name] || ""}
            onChange={handleLocalFilterChange}
          />
        </div>

        {/* End Date Field */}
        <div className="flex-1">
          <label
            htmlFor={pairedField.name}
            className="block truncate text-sm font-medium leading-6 text-gray-900"
          >
            {pairedField.label}
          </label>
          <input
            type="date"
            name={pairedField.name}
            id={pairedField.name}
            className={`mt-2 block w-full rounded-sm border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ${
              dateError ? "ring-red-500" : "ring-gray-300"
            } focus:ring-2 focus:ring-accent-100 sm:text-sm sm:leading-6`}
            value={localFilters[pairedField.name] || ""}
            onChange={handleLocalFilterChange}
          />
        </div>
      </div>

      {/* Error Message */}
      {dateError && (
        <div className="mt-2 text-sm text-red-600 bg-red-50 p-2 rounded">
          {dateError}
        </div>
      )}
    </div>
  );

  const renderRegularField = (option: FilterOption) => (
    <div key={option.name} className="flex-1">
      <label
        htmlFor={option.name}
        className="block truncate text-sm font-medium leading-6 text-gray-900"
      >
        {option.label}
      </label>
      {option.type === "selector" ? (
        <select
          name={option.name}
          id={option.name}
          className="mt-2 block w-full rounded-sm border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-accent-100 sm:text-sm sm:leading-6"
          value={localFilters[option.name] || ""}
          onChange={handleLocalFilterChange}
        >
          {option.options?.map((opt) => (
            <option key={opt.value} value={opt.value}>
              {opt.label}
            </option>
          ))}
        </select>
      ) : option.type === "checkbox" ? (
        <div className="mt-2">
          <input
            type={option.type}
            name={option.name}
            id={option.name}
            className="mr-2 rounded-sm text-secondary-100 focus:ring-accent-500"
            checked={localFilters[option.name] || false}
            onChange={handleLocalFilterChange}
          />
          <span className="text-sm font-semibold">{option.label}</span>
        </div>
      ) : (
        <input
          type={option.type}
          name={option.name}
          id={option.name}
          className="mt-2 block w-full rounded-sm border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-accent-100 sm:text-sm sm:leading-6"
          placeholder={option.label}
          value={localFilters[option.name] || ""}
          onChange={handleLocalFilterChange}
        />
      )}
    </div>
  );

  const content = (
    <div className="flex gap-1 flex-wrap flex-col px-2">
      {filterOptions.map((option, index, array) => {
        // Handle date range fields
        if (option.name === "dueDateFrom") {
          const endDateOption = array.find((opt) => opt.name === "dueDateTo");
          if (endDateOption) {
            return (
              <React.Fragment key={`date-range-${index}`}>
                {renderDateFields(option, endDateOption)}
              </React.Fragment>
            );
          }
        }
        // Skip the end date field since it's handled in the start date field
        if (option.name === "dueDateTo") return null;
        // Handle regular fields
        return (
          <React.Fragment key={`regular-field-${index}`}>
            {renderRegularField(option)}
          </React.Fragment>
        );
      })}
      <div className="flex w-full gap-2 mt-4">
        <button
          onClick={handleResetFilters}
          className="flex-1 bg-gray-100 rounded-sm text-gray-800 font-medium py-1.5"
        >
          Reset Filters
        </button>
        <button
          onClick={handleApplyFilters}
          //className="flex-1 bg-accent-100 rounded-sm text-white font-medium py-1.5"
          className={`flex-1 rounded-sm text-white font-medium py-1.5 ${
            dateError ? "bg-accent-300 cursor-not-allowed" : "bg-accent-100"
          }`}
        >
          Apply Filters
        </button>
      </div>
    </div>
  );

  return (
    <Transition.Root show={show} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={() => setShow(false)}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-sm bg-white pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-md">
                <div className="flex h-full flex-col overflow-auto bg-white ">
                  <div className="px-4 sm:px-6 border-b border-gray-200">
                    <div className="flex items-start justify-between ">
                      <div>
                        <Dialog.Title className="text-base font-semibold leading-4 text-secondary ">
                          Advanced Filters
                        </Dialog.Title>
                        <Dialog.Description
                          className={"text-sm leading-2 text-gray-500 py-2"}
                        >
                          Search for just what you need
                        </Dialog.Description>
                      </div>
                      <div className="">
                        <button
                          type="button"
                          className="justify-center items-center relative rounded-sm bg-white text-gray-400 hover:text-gray-500 focus:outline-none "
                          onClick={() => setShow(false)}
                        >
                          <span className="sr-only">Close panel</span>
                          <Close
                            style={{ fontSize: "1rem" }}
                            aria-hidden="true"
                          />
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="relative mt-6 flex-1 px-2">{content}</div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default FilterModal;
