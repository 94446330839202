import React from "react";
import { requestDoc } from "../../../api/services/LandingPageService";
const Footer: React.FC = () => {
  return (
    <footer className="site-footer">
      <div className="floating-symbols">
        <img
          className="w-100 d-none d-md-block"
          src="/assets/images/symbols.png"
          alt=""
        />
        <img
          className="w-100 d-md-none"
          src="/assets/images/symbols-mobile.png"
          alt=""
        />
      </div>
      <div
        className="floating-pattern-bg"
        style={{
          backgroundImage: "url(/assets/images/site-footer-bg-site-icon.png)",
        }}
      />
      <div className="container">
        <div className="section-heading">
          <h2>
            Stay on Top of your Facilities Operations{" "}
            <strong>and on Top of your Game</strong>
          </h2>
        </div>
        <div className="footer-contact-info">
          <div className="row justify-content-between">
            <div className="col-md-auto column column--email">
              <h6 className="footer-widget-title">email</h6>
              <ul>
                <li>
                  <a href="mailto:info@sequra.ai" target="_blank">
                    info@sequra.ai
                  </a>
                </li>
              </ul>
            </div>
            <div className="col-md-auto column column--phone">
              <h6 className="footer-widget-title">Phone</h6>
              <ul>
                <li>
                  <a href="tel:+1 516-232-4760" target="_blank">
                    +1 516-232-4760
                  </a>
                </li>
              </ul>
            </div>
            <div className="col-md-auto column column--address">
              <h6 className="footer-widget-title">Address</h6>
              <ul>
                <li>
                  961 Broadway,
                  <br /> Woodmere, NY 11598
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="site-footer__btm">
          <div className="row align-items-center">
            <div className="col-md-auto order-md-2">
              <div className="row align-items-center justify-content-center">
                <div className="col-auto footer-inline-links">
                  <ul className="nav">
                    <li>
                      <a onClick={() => requestDoc("https://sequraadmindocs.s3.us-east-1.amazonaws.com/Sequra+Claim.pdf")}>Claim</a>
                    </li>
                    <li>
                      <a onClick={() => requestDoc("https://sequraadmindocs.s3.us-east-1.amazonaws.com/Sequra+Privacy+Policy.pdf")}>Privacy</a>
                    </li>
                    <li>
                      <a onClick={() => requestDoc("https://sequraadmindocs.s3.us-east-1.amazonaws.com/Sequra+Terms.pdf")}>Terms</a>
                    </li>
                  </ul>
                </div>
                <div className="col-auto inline-social-icons">
                  <ul className="nav">
                    <li>
                      <a href="https://linkedin.com/company/sequra-ai" title="linkedin" target="_blank">
                        <span className="icon-linkedin"></span>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-md column-site-logo-and-copyright">
              <div className="row align-items-center">
                <div className="col-md-auto">
                  <a className="site-logo" href="/">
                    <img
                      className="w-100"
                      src="/assets/images/site-logo.svg"
                      alt=""
                    />
                  </a>
                </div>
                <div className="col-md copyright">
                  © 2024 Sequra. All Rights Reserved.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
