import React from "react";

const WMattersSection: React.FC = () => {
  return (
    <div className="w-matters-section">
      <div className="container">
        <div className="inner-container">
          <div className="section-heading fadeInUp">
            <h2>
              Built for <strong>What Matters</strong>
            </h2>
          </div>
          <div className="row">
            <div className="col-md-6 gutters-end-0 column-heading fadeInUp">
              <h5>
                With Sequra, every detail of preventative maintenance, life
                safety, and work order management is under control and
                accessible at your fingertips.
              </h5>
            </div>
            <div className="col-md gutters-end-0 column-content fadeInUp">
              <p>
                Powered by a comprehensive compliance database of CMS and
                NFPA-related task templates, instant access to audit logs, and
                data validation for compliance standards, the platform
                streamlines all operations and simplifies survey audits,
                allowing you to focus on delivering exceptional service without
                getting bogged down by administrative tasks.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="floating-bg-img parallaxBlockUp">
        <img
          className="w-100 d-none d-md-block"
          src="/assets/images/curve-img-1.png"
          alt=""
        />
        <img
          className="w-100 d-md-none"
          src="/assets/images/curve-img-1-mobile.png"
          alt=""
        />
      </div>
    </div>
  );
};

export default WMattersSection;
