import React, { Fragment, useContext, useState } from "react";
import {
  Link,
  Outlet,
  useLocation,
  useMatch,
  useNavigate,
} from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";
import {
  Logout,
  People,
  Business,
  Person,
  Close,
  Schedule,
  QueryStats,
  PieChartOutline,
  CalendarMonthOutlined,
  TaskOutlined,
  HomeRepairServiceOutlined,
  SummarizeOutlined,
  ManageAccountsOutlined,
  PeopleOutline,
  PlumbingOutlined,
  ExpandMore,
  ExpandLess,
} from "@mui/icons-material";
import { Dialog, Menu, Transition } from "@headlessui/react";
import { FacilityContext } from "../../context/FacilityContext";
import { useAuth } from "../../hooks/useAuth";
import { classNames } from "../../utils/FormatFunctions";
import Toast from "../modals/Toast";

type NavigationComponent = {
  name: string;
  href: string;
  icon: React.ElementType;
  access: string[];
  current?: boolean;
  children?: NavigationComponent[];
};

const ApplicationShell: React.FC = () => {
  const { currentUser, dispatch } = useAuth();

  const navigate = useNavigate();

  const { selectedFacility, handleFacilityChange } =
    useContext(FacilityContext);

  const handleLogout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    localStorage.removeItem("selectedFacility");
    localStorage.removeItem("work-order-filters");

    dispatch({ type: "LOGOUT" });
    navigate("/login");
  };

  const [nestedButton, setNestedButton] = useState<null | string>(null);

  const navigation: NavigationComponent[] = [
    /* {
      name: "My List",
      href: "/home",
      icon: SummarizeOutlined,
      access: ["worker"],
    }, */
    {
      name: "Dashboard",
      href: "/home",
      icon: PieChartOutline,
      current: false,
      access: [
        "administrator",
        "director",
        "organization-admin",
        "regional",
        "system-admin",
        "system-support",
      ],
    },
    {
      name: "Work Orders",
      href: "/work-orders",
      icon: HomeRepairServiceOutlined,
      access: [
        "worker",
        "administrator",
        "director",
        "organization-admin",
        "regional",
      ],
    },
    {
      name: "Tasks",
      href: "/tasks",
      icon: TaskOutlined,
      current: false,
      access: [
        "worker",
        "administrator",
        "director",
        "organization-admin",
        "regional",
      ],
      children: [
        {
          name: "Task Scheduler",
          href: "/task-scheduler",
          icon: CalendarMonthOutlined,
          access: [
            "administrator",
            "director",
            "organization-admin",
            "regional",
          ],
        },
      ],
    },

    {
      name: "Log Books",
      href: "/logs",
      icon: SummarizeOutlined,
      current: false,
      access: ["administrator", "director", "organization-admin", "regional"],
    },
    {
      name: "My Building",
      href: "/my-building",
      icon: Business,
      access: ["administrator", "director", "organization-admin", "regional"],
    },
    {
      name: "Facilities",
      href: "/facilities",
      icon: Business,
      access: ["system-admin"],
    },
    {
      name: "Users",
      href: "/users",
      icon: People,
      access: ["system-admin"],
    },
    {
      name: "Task Catalog",
      href: "/task-catalog",
      icon: Schedule,
      access: ["system-admin", "system-support"],
    },

    {
      name: "Onboarding",
      href: "/support/onboarding",
      icon: Schedule,
      access: ["system-support"],
    },
  ];

  const permissions = currentUser.access;

  function useMatchStart(
    path: string,
    children: NavigationComponent[] | undefined
  ) {
    const location = useLocation();
    const isChildCurrentRoute = children?.some((child: { href: string }) =>
      location.pathname.startsWith(child.href)
    );

    const isCurrentRoute =
      !isChildCurrentRoute && location.pathname.startsWith(path);
    return isCurrentRoute;
  }

  const currentRoutes = navigation.map((item) => {
    const isCurrent = useMatchStart(item.href, item.children);

    return {
      ...item,
      current: isCurrent,
      children: item.children
        ?.filter((child) => child.access.includes(permissions))
        .map((child) => ({
          ...child,
          current: location.pathname.startsWith(child.href),
        })),
    };
  });

  const allowedRoutes = currentRoutes.filter((item) => {
    return item.access.includes(permissions);
  });

  const secondaryRoutes = [
    {
      name: "Team",
      href: "/team",
      icon: PeopleOutline,
      access: ["administrator", "director", "organization-admin", "regional"],
    },

    {
      name: "Analytics",
      href: "/analytics",
      icon: QueryStats,
      access: ["regional", "organization-admin", "administrator"],
    },
    {
      name: "Vendors",
      href: "/vendors",
      icon: PlumbingOutlined,
      access: ["administrator", "director", "organization-admin", "regional"],
    },
    {
      name: "Settings",
      href: "/settings",
      icon: ManageAccountsOutlined,
      access: [
        "administrator",
        "director",
        "organization-admin",
        "regional",
        "worker",
        "system-admin",
        "system-support",
      ],
    },
  ];

  const allowedSecondaryRoutes = secondaryRoutes.filter((item) => {
    return item.access.includes(permissions);
  });

  const currentSecondaryRoutes = allowedSecondaryRoutes.map(
    (item: NavigationComponent) => ({
      ...item,
      current: useMatchStart(item.href, item.children),
    })
  );

  const userNavigation = [
    { name: "Your profile", href: "/settings", function: null },
    { name: "Sign out", href: "#", function: handleLogout },
  ];

  const [sidebarOpen, setSidebarOpen] = useState(false);

  return (
    <>
      <div>
        <Transition.Root show={sidebarOpen} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-40 lg:hidden"
            onClose={setSidebarOpen}
          >
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-secondary-100/80" />
            </Transition.Child>

            <div className="fixed inset-0 flex">
              <Transition.Child
                as={Fragment}
                enter="transition ease-in-out duration-300 transform"
                enterFrom="-translate-x-full"
                enterTo="translate-x-0"
                leave="transition ease-in-out duration-300 transform"
                leaveFrom="translate-x-0"
                leaveTo="-translate-x-full"
              >
                <Dialog.Panel className="relative mr-16 flex w-full max-w-xs flex-1">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-300"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="absolute left-full top-0 flex w-16 justify-center pt-5">
                      <button
                        type="button"
                        className="-m-2.5 p-2.5"
                        onClick={() => setSidebarOpen(false)}
                      >
                        <span className="sr-only">Close sidebar</span>
                        <Close
                          className="h-6 w-6 text-primary"
                          aria-hidden="true"
                        />
                      </button>
                    </div>
                  </Transition.Child>
                  <div className="flex grow flex-col gap-y-5 overflow-y-auto ring-1 bg-primary ring-white/10">
                    <div className="flex py-2 shrink-0 items-center justify-center bg-secondary-100 border-b border-accent-200 h-16">
                      <img
                        className="w-auto h-6"
                        src="\sequra_logo__primary_white.png"
                        alt="Company logo"
                      />
                    </div>
                    <nav className="flex flex-1 flex-col px-2">
                      <ul role="list" className="flex flex-1 flex-col gap-y-7">
                        <li>
                          <ul role="list" className=" space-y-1">
                            {allowedRoutes.map((item: NavigationComponent) => (
                              <li key={item.name}>
                                <div
                                  onClick={() => {
                                    navigate(item.href);
                                    !item.children && setNestedButton(null);
                                    setSidebarOpen(false);
                                  }}
                                  className={classNames(
                                    item.current
                                      ? "bg-accent-500 text-primary"
                                      : "text-secondary-100 hover:text-primary hover:bg-accent-500",
                                    "w-full group flex justify-between items-center gap-x-3 rounded-sm p-2 text-sm leading-6 font-normal"
                                  )}
                                >
                                  <div className="flex items-center gap-x-3">
                                    <item.icon
                                      className="shrink-0"
                                      aria-hidden="true"
                                      style={{ fontSize: "1rem" }}
                                    />
                                    {item.name}
                                  </div>
                                  {item.children &&
                                    item.children.length > 0 &&
                                    (nestedButton !== item.name ? (
                                      <button
                                        className="border-l border-secondary-1000 "
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          setNestedButton(item.name);
                                        }}
                                      >
                                        <ExpandMore
                                          className="mr-0"
                                          aria-hidden="true"
                                        />
                                      </button>
                                    ) : (
                                      <button
                                        className="border-l border-secondary-1000 "
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          setNestedButton(null);
                                        }}
                                      >
                                        <ExpandLess
                                          className="h-5 w-5"
                                          aria-hidden="true"
                                        />
                                      </button>
                                    ))}
                                </div>
                                {item.children &&
                                  nestedButton === item.name && (
                                    <ul className="ml-4 border-l-2 pl-2 border-secondary-1000 mt-1">
                                      {item.children.map((child) => (
                                        <li key={child.name}>
                                          <button
                                            onClick={() => {
                                              navigate(child.href);
                                              setSidebarOpen(false);
                                            }}
                                            className={classNames(
                                              child.current
                                                ? "bg-accent-500 text-primary"
                                                : "text-secondary-100 hover:text-primary hover:bg-accent-500",
                                              "w-full group flex  items-center gap-x-3 rounded-sm p-2 text-sm leading-6 font-normal"
                                            )}
                                          >
                                            <child.icon
                                              className="shrink-0"
                                              aria-hidden="true"
                                              style={{ fontSize: "1.1rem" }}
                                            />
                                            {child.name}
                                          </button>
                                        </li>
                                      ))}
                                    </ul>
                                  )}
                              </li>
                            ))}
                          </ul>
                        </li>
                        <hr className="border-b border-secondary-1000" />
                        <li>
                          <div className="text-xs font-semibold leading-2 text-accent-500">
                            ADDITIONAL PAGES
                          </div>
                          <ul role="list" className="mt-2 space-y-1">
                            {currentSecondaryRoutes.map((item) => (
                              <li key={item.name}>
                                <button
                                  onClick={() => {
                                    navigate(item.href);
                                    setSidebarOpen(false);
                                  }}
                                  className={classNames(
                                    item.current
                                      ? "bg-accent-500 text-primary"
                                      : "text-secondary-100 hover:text-primary hover:bg-accent-500",
                                    "w-full group flex items-center gap-x-3 rounded-sm p-2 text-sm leading-6 font-normal"
                                  )}
                                >
                                  <item.icon
                                    className="shrink-0"
                                    aria-hidden="true"
                                    style={{ fontSize: "1.1rem" }}
                                  />
                                  {item.name}
                                </button>
                              </li>
                            ))}
                          </ul>
                        </li>
                        <li className="mt-auto -mx-2  border-t border-secondary-1000 h-14">
                          <button
                            onClick={handleLogout}
                            className="mx-4 h-full group flex items-center cursor-pointer gap-x-3 rounded-sm p-2 text-sm font-semibold leading-6 text-reds-600  hover:text-reds-400"
                          >
                            <Logout
                              className="shrink-0"
                              aria-hidden="true"
                              style={{ fontSize: "1rem" }}
                            />
                            Sign Out
                          </button>
                        </li>
                      </ul>
                    </nav>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition.Root>

        {/* Static sidebar for desktop */}
        <div className="hidden lg:fixed lg:inset-y-0 lg:z-40 lg:flex lg:w-72 lg:flex-col bg-primary">
          {/* Sidebar component */}
          <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-primary  border-r border-secondary-1000">
            <Link
              to="/home"
              className="flex cursor-pointer py-2 shrink-0 items-center justify-center bg-secondary-100 border-b border-accent-200 h-16"
            >
              <img
                className="w-auto h-6"
                src="\sequra_logo__primary_white.png"
                alt="Company logo"
              />
            </Link>

            <nav className="flex flex-1 flex-col px-6">
              <ul role="list" className="flex flex-1 flex-col gap-y-7">
                <li>
                  <ul role="list" className="space-y-1 -mx-2">
                    {allowedRoutes.map((item) => (
                      <li key={item.name}>
                        <div
                          className={classNames(
                            item.current
                              ? "bg-accent-500 text-primary"
                              : "text-secondary-100 hover:text-primary hover:bg-accent-500",
                            "group cursor-pointer flex items-center gap-x-3  rounded-sm text-sm leading-6 font-normal justify-between"
                          )}
                          onClick={() => {
                            navigate(item.href);
                            !item.children && setNestedButton(null);
                          }}
                        >
                          <button className="p-2">
                            <div className="flex items-center gap-x-3">
                              <item.icon
                                className="shrink-0"
                                aria-hidden="true"
                                style={{ fontSize: "1.1rem" }}
                              />
                              {item.name}
                            </div>
                          </button>
                          {item.children && item.children.length > 0 && (
                            <button
                              className="border-l border-secondary-1000 "
                              onClick={(e) => {
                                e.stopPropagation();
                                nestedButton === item.name
                                  ? setNestedButton(null)
                                  : setNestedButton(item.name);
                              }}
                            >
                              {nestedButton !== item.name ? (
                                <ExpandMore
                                  className="mr-0 rounded-full hover:bg-accent-700 "
                                  aria-hidden="true"
                                />
                              ) : (
                                <ExpandLess
                                  className="h-5 w-5"
                                  aria-hidden="true"
                                />
                              )}
                            </button>
                          )}
                        </div>
                        {item.children && nestedButton === item.name && (
                          <ul className="ml-4 border-l-2 pl-2 border-secondary-1000 mt-1">
                            {item.children.map((child) => (
                              <li key={child.name}>
                                <button
                                  onClick={() => navigate(child.href)}
                                  className={classNames(
                                    child.current
                                      ? "bg-accent-500 text-primary"
                                      : "text-secondary-100 hover:text-primary hover:bg-accent-500",
                                    "w-full group flex  items-center -mx-2 gap-x-3 rounded-sm p-2 text-sm leading-6 font-normal"
                                  )}
                                >
                                  <child.icon
                                    className="shrink-0"
                                    aria-hidden="true"
                                    style={{ fontSize: "1.1rem" }}
                                  />
                                  {child.name}
                                </button>
                              </li>
                            ))}
                          </ul>
                        )}
                      </li>
                    ))}
                  </ul>
                </li>
                <hr className="border-b border-secondary-1000 -ml-4 -mr-4" />
                <li>
                  <div className="text-xs font-semibold mb-2 text-accent-400">
                    ADDITIONAL PAGES
                  </div>
                  <ul role="list" className="mt-2 -mx-2 space-y-1">
                    {currentSecondaryRoutes.map((item) => (
                      <li key={item.name}>
                        <button
                          onClick={() => navigate(item.href)}
                          className={classNames(
                            item.current
                              ? "bg-accent-500 text-primary"
                              : "text-secondary-100 hover:text-primary hover:bg-accent-500",
                            "w-full group flex items-center gap-x-3  rounded-sm p-2 text-sm leading-6 font-normal"
                          )}
                        >
                          <item.icon
                            className=""
                            aria-hidden="true"
                            style={{ fontSize: "1.1rem" }}
                          />
                          {item.name}
                        </button>
                      </li>
                    ))}
                  </ul>
                </li>
                <li className="mt-auto border-t border-secondary-1000 -mx-6  px-6 h-14">
                  <button
                    onClick={handleLogout}
                    className="group  h-full cursor-pointer flex items-center gap-x-3 rounded-sm p-1 text-sm font-semibold leading-6 text-reds-500  hover:text-reds-400"
                  >
                    <Logout
                      className="shrink-0"
                      style={{ fontSize: "1rem" }}
                      aria-hidden="true"
                    />
                    Sign out
                  </button>
                </li>
              </ul>
            </nav>
          </div>
        </div>

        <div className="lg:pl-72">
          <div className="sticky top-0 z-40 flex h-16 shrink-0 items-center gap-x-4 border-b border-gray-200 bg-primary px-4 shadow-sm sm:gap-x-6 sm:px-6 lg:px-8">
            <button
              type="button"
              className="-m-2.5 p-2.5 text-secondary-400 lg:hidden"
              onClick={() => setSidebarOpen(true)}
            >
              <span className="sr-only">Open sidebar</span>
              <img
                src="/sequra_logo__icon_black.png"
                className="h-6 w-6"
                alt="Company logo"
              />
              {/* <Dashboard className="h-6 w-6" aria-hidden="true" /> */}
            </button>

            {/* Separator */}
            <div
              className="h-6 w-px bg-secondary-100/10 lg:hidden"
              aria-hidden="true"
            />

            <div className="flex flex-1 gap-x-4 self-stretch lg:gap-x-6">
              <div className="relative flex flex-1" />
              <div className="flex items-center gap-x-4 lg:gap-x-6">
                {currentUser.facility.length > 1 && (
                  <div>
                    <span className="text-secondary-500 text-xs max-w-36 md:hidden block truncate">
                      {
                        currentUser.facility.filter(
                          (facility) => facility._id === selectedFacility
                        )[0]?.name
                      }
                    </span>

                    <div className="m-4 hidden md:flex">
                      <select
                        id="facility"
                        name="facility"
                        className="max-w-36 border-none text-xs truncate cursor-pointer block w-full rounded-sm p-1 pl-2 pr-8 text-secondary-100 ring-1 ring-inset ring-secondary-800 focus:ring-2 focus:ring-accent-700 leading-4  overflow-hidden text-overflow-ellipsis whitespace-nowrap"
                        value={selectedFacility}
                        onChange={(e) =>
                          handleFacilityChange(e.target.value, currentUser._id)
                        }
                      >
                        {currentUser.facility.map((facility) => (
                          <option value={facility._id} key={facility._id}>
                            {facility.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                )}

                <div
                  className="hidden lg:block lg:h-6 lg:w-px lg:bg-primary"
                  aria-hidden="true"
                />

                {/* Profile dropdown */}
                <Menu as="div" className="relative ">
                  <Menu.Button className="-m-1.5 flex items-center p-1.5">
                    <span className="sr-only">Open user menu</span>

                    {currentUser.avatar ? (
                      <img
                        src={currentUser.avatar}
                        alt=""
                        className="h-5 w-5 flex-shrink-0 rounded-full"
                      />
                    ) : (
                      <Person className="bg-secondary-1000 p-1 rounded-xl" />
                    )}
                    <span className="hidden lg:flex lg:items-center">
                      <span
                        className="ml-4 text-sm font-semibold leading-6 text-secondary-100"
                        aria-hidden="true"
                      >
                        {currentUser.firstname} {currentUser.lastname}
                      </span>
                    </span>
                  </Menu.Button>

                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="absolute right-0 z-10 mt-2.5 w-32 origin-top-right rounded-sm bg-primary py-2 shadow-lg ring-1 ring-secondary-100/5 focus:outline-none">
                      {userNavigation.map((item) => (
                        <Menu.Item key={item.name}>
                          {({ active }) => (
                            <a
                              onClick={item.function ?? (() => {})}
                              href={item.href}
                              className={classNames(
                                active ? "bg-secondary-1000" : "",
                                "block px-3 py-1 text-sm leading-6 text-secondary-100"
                              )}
                            >
                              {item.name}
                            </a>
                          )}
                        </Menu.Item>
                      ))}
                      {currentUser.facility.length > 1 && (
                        <div className="m-4 md:hidden">
                          <label
                            htmlFor="facility"
                            className="block text-sm font-medium leading-6 text-secondary-500"
                          >
                            Facility
                          </label>
                          <select
                            id="facility"
                            name="facility"
                            className="cursor-pointer mt-2 block w-full rounded-sm  py-1.5 pl-3 pr-10  text-secondary-100 ring-1 ring-inset ring-secondary-1000 focus:ring-2 focus:ring-accent-500 focus:border-none sm:text-sm sm:leading-6"
                            value={selectedFacility}
                            onChange={(e) =>
                              handleFacilityChange(
                                e.target.value,
                                currentUser._id
                              )
                            }
                          >
                            {currentUser.facility.map((facility) => (
                              <option value={facility._id} key={facility._id}>
                                {facility.name}
                              </option>
                            ))}
                          </select>
                        </div>
                      )}
                    </Menu.Items>
                  </Transition>
                </Menu>
              </div>
            </div>
          </div>

          <main className="h-screen sm:h-[calc(100vh-4rem)] sm:overflow-y-auto scrollbar-thin">
            <Outlet />
            <Toast />
          </main>
        </div>
      </div>
    </>
  );
};

export default ApplicationShell;
