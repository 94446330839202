import VendorsTable from "../../updatedcomponents/tables/VendorsList";
import PageHeader from "../../updatedcomponents/general-ui/PageHeader";
import Pagination from "../../updatedcomponents/table-components/Pagination";
import FilterModal from "../../updatedcomponents/table-components/FilterModal";
import { useVendor } from "../../hooks/useVendor";
import FilterButton from "../../updatedcomponents/table-components/FilterButton";
import Button from "../../updatedcomponents/general/Button";
import GenericModal from "../../updatedcomponents/modals/GenericModal";
import { useAuth } from "../../hooks/useAuth";
import NewVendorForm from "../../updatedcomponents/forms/NewVendorForm";
import Modal from "../../updatedcomponents/general-ui/Modal";
const Vendors = () => {
  const { currentUser } = useAuth();
  const {
    selectedVendor,
    filters,
    handleViewVendor,
    applyFilters,
    resetFilters,
    handlePageChange,
    handlePageSizeChange,
    page,
    pageSize,
    handleDelete,
    totalPages,
    showModalState,
    updateShowModalState,
  } = useVendor();

  const permissions = currentUser.access;

  const filterOptions = [
    {
      label: "Company Name",
      type: "text",
      name: "name",
    },
    {
      label: "Company Type",
      type: "selector",
      name: "type",
      options: [
        { label: "All", value: "" },
        { label: "Electrician", value: "electrician" },
        { label: "HVAC", value: "HVAC" },
        { label: "Plumber", value: "plumber" },
        { label: "Sprinkler", value: "sprinkler" },
        { label: "Other", value: "other" },
      ],
    },
  ];

  return (
    <div>
      <PageHeader
        title="Vendors"
        subtitle="All vendors servicing your facility"
        buttons={
          ["director", "regional", "organization-admin"].includes(
            permissions
          ) && (
            <Button
              icon="Add"
              styleString="primary"
              children="Add Vendor"
              onClick={() => handleViewVendor(null)}
              overrideStyles="w-full"
            />
          )
        }
      />
      <div className=" px-4 sm:px-6 sm:overflow-y-auto sm:pb-2 thin-scrollbar ">
        <FilterButton
          setShowFilterModal={() => updateShowModalState("showFilter", true)}
        />
        <FilterModal
          show={showModalState.showFilter}
          setShow={() => updateShowModalState("showFilter", false)}
          filters={filters}
          filterOptions={filterOptions}
          applyFilters={applyFilters}
          resetFilters={resetFilters}
        />
        <VendorsTable />
        <Pagination
          itemsToShow={pageSize}
          handlePageSizeChange={handlePageSizeChange}
          currentPage={page}
          setCurrentPage={handlePageChange}
          totalPages={totalPages || 1}
        />
      </div>
      <Modal
        open={showModalState.showVendor}
        setOpen={() => updateShowModalState("showVendor", false)}
        title={"Create Vendor"}
        subtitle="Add a new vendor to your facility"
        content={<NewVendorForm />}
      />

      <GenericModal
        title={"Delete Vendor"}
        item={selectedVendor}
        handleFunction={handleDelete}
        showModal={showModalState.showDelete}
        setShowModal={() => updateShowModalState("showDelete", false)}
        color="reds-500"
        secondaryColor={"reds-1000"}
        buttonText="Delete"
      />
    </div>
  );
};

export default Vendors;
