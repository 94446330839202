import React from "react";
import LogsList from "../../updatedcomponents/tables/Logslist";
import WorkOrderLogTable from "../../updatedcomponents/tables/WorkOrderLogTable";
import LogPrint from "../../updatedcomponents/modals/LogPrint";
import Pagination from "../../updatedcomponents/table-components/Pagination";
import PageHeader from "../../updatedcomponents/general-ui/PageHeader";
import FilterButton from "../../updatedcomponents/table-components/FilterButton";
import FilterModal from "../../updatedcomponents/table-components/FilterModal";
import {
  BuildOutlined,
  EngineeringOutlined,
  HealthAndSafetyOutlined,
} from "@mui/icons-material";
import ViewLog from "../../updatedcomponents/modals/ViewLog";
import { classNames } from "../../utils/FormatFunctions";
import Button from "../../updatedcomponents/general/Button";
import { useLog } from "../../hooks/useLog";
import { TabType } from "../../context/LogContext";
import Modal from "../../updatedcomponents/general-ui/Modal";

const Logs: React.FC = () => {
  const {
    selectedTab,
    filters,
    page,
    pageSize,
    totalPages,
    showFilter,
    setShowFilter,
    setTotalPages,
    updateTab,
    applyFilters,
    resetFilters,
    handlePageChange,
    handlePageSizeChange,
    showState,
    updateShowState,
    selectedLog,
  } = useLog();

  const getFilterOptions = (selectedTab: TabType) => {
    switch (selectedTab) {
      case "work-orders":
        return [
          {
            label: "Title",
            type: "text",
            name: "title",
          },
          { name: "id", label: "ID", type: "text" },
          {
            label: "Date Reported From",
            type: "date",
            name: "dateReportedFrom",
            joinId: "reportRange",
          },
          {
            label: "Date Reported To",
            type: "date",
            name: "dateReportedTo",
            joinId: "reportRange",
          },
          {
            label: "Date Closed From",
            type: "date",
            name: "dateClosedFrom",
            joinId: "dateRange",
          },
          {
            label: "Date Closed To",
            type: "date",
            name: "dateClosedTo",
            joinId: "dateRange",
          },
          {
            label: "Location",
            type: "text",
            name: "location",
          },
          {
            name: "urgency",
            label: "Urgency",
            type: "selector",
            options: [
              { value: "", label: "All Urgencies" },
              { value: "standard", label: "Standard" },
              { value: "urgent", label: "Urgent" },
            ],
          },
        ];
      case "life-safety":
        return [
          {
            label: "Title",
            type: "text",
            name: "title",
          },
          {
            label: "Task Frequency",
            type: "selector",
            options: [
              { label: "All", value: "" },
              { label: "Daily", value: "daily" },
              { label: "Weekly", value: "weekly" },
              { label: "Bi-weekly", value: "bi-weekly" },
              { label: "Monthly", value: "monthly" },
              { label: "Quarterly", value: "quarterly" },
              { label: "Semi-annually", value: "semi-annually" },
              { label: "Annually", value: "annually" },
              { label: "2 Year", value: "biennially" },
              { label: "3 Year", value: "3-year" },
              { label: "4 Year", value: "4-year" },
              { label: "5 Year", value: "5-year" },
            ],
            name: "frequency",
          },
          {
            label: "Data Source",
            type: "selector",
            options: [
              { label: "All", value: "" },
              { label: "Internal", value: "internal" },
              { label: "External", value: "external" },
            ],
            name: "source",
          },
        ];
      case "prev-maint":
        return [
          {
            label: "Title",
            type: "text",
            name: "title",
          },
          {
            label: "Task Frequency",
            type: "selector",
            options: [
              { label: "All", value: "" },
              { label: "Daily", value: "daily" },
              { label: "Weekly", value: "weekly" },
              { label: "Bi-weekly", value: "bi-weekly" },
              { label: "Monthly", value: "monthly" },
              { label: "Quarterly", value: "quarterly" },
              { label: "Semi-annually", value: "semi-annually" },
              { label: "Annually", value: "annually" },
              { label: "2 Year", value: "biennially" },
              { label: "3 Year", value: "3-year" },
              { label: "4 Year", value: "4-year" },
              { label: "5 Year", value: "5-year" },
            ],
            name: "frequency",
          },
          {
            label: "Data Source",
            type: "selector",
            options: [
              { label: "All", value: "" },
              { label: "Internal", value: "internal" },
              { label: "External", value: "external" },
            ],
            name: "source",
          },
        ];
      default:
        return [];
    }
  };

  const navigation = [
    {
      name: "Work Orders",
      value: "work-orders",
      current: selectedTab === "work-orders",
      icon: <BuildOutlined style={{ fontSize: "1rem" }} />,
    },
    {
      name: "Life Safety",
      value: "life-safety",
      current: selectedTab === "life-safety",
      icon: <HealthAndSafetyOutlined style={{ fontSize: "1rem" }} />,
    },
    {
      name: "Preventative Maintenance",
      value: "prev-maint",
      current: selectedTab === "prev-maint",
      icon: <EngineeringOutlined style={{ fontSize: "1rem" }} />,
    },
  ];

  return (
    <div className="relative bg-secondary-1200 sm:bg-primary h-full">
      <PageHeader
        title={"Log Books"}
        subtitle={"View all closed tasks and work orders for your facility."}
      />
      <div className={`px-4 sm:px-6 lg:px-8`}>
        <nav className="flex w-fit border rounded-sm my-4 bg-secondary-1100">
          {navigation.map((navItem, index) => (
            <button
              key={index}
              onClick={() =>
                updateTab(
                  navItem.value as "work-orders" | "life-safety" | "prev-maint"
                )
              }
              className={classNames(
                navItem.current
                  ? "bg-primary text-secondary-100 shadow"
                  : "text-secondary-100  hover:bg-secondary-1200 hover:shadow-sm",
                "m-1 group flex items-center flex-1 rounded-sm px-2 py-1 text-xs sm:text-sm leading-6 font-normal sm:whitespace-nowrap",
                index < navigation.length - 1
                  ? "border-r border-secondary-500"
                  : ""
              )}
            >
              <span className="flex align-middle mr-2">{navItem.icon}</span>{" "}
              {navItem.name}
            </button>
          ))}
        </nav>
        <div className="flex flex-col sm:flex-row justify-between w-full">
          <div className="flex flex-col">
            <div className="flex items-center divide-x divide-secondary-500 gap-4 text-sm">
              <h1 className="font-semibold text-secondary-100">
                {selectedTab === "life-safety" && "Life Safety"}
                {selectedTab === "prev-maint" && "Preventative Maintenance"}
                {selectedTab === "work-orders" && "Work Orders"}
              </h1>
              <p className="pl-4 text-secondary-500">List of all logs.</p>
            </div>
            <div className="flex w-fit">
              <FilterButton setShowFilterModal={setShowFilter} />
            </div>
          </div>
          <div className="flex gap-4 my-4 sm:mt-0">
            <Button
              icon="Print"
              onClick={() => updateShowState("showPrintState", true)}
              styleString="primary"
              children="Logs"
            />
          </div>
        </div>

        <FilterModal
          show={showFilter}
          setShow={setShowFilter}
          filters={JSON.stringify(filters)}
          filterOptions={getFilterOptions(selectedTab)}
          applyFilters={applyFilters}
          resetFilters={resetFilters}
        />

        {selectedTab !== "work-orders" && <LogsList />}

        {selectedTab === "work-orders" && (
          <>
            <WorkOrderLogTable />
            <Pagination
              itemsToShow={pageSize}
              currentPage={page}
              setCurrentPage={handlePageChange}
              handlePageSizeChange={handlePageSizeChange}
              totalPages={totalPages}
            />
          </>
        )}
      </div>
      <Modal
        title={selectedLog?.title || "View Log"}
        open={showState.showLogState}
        setOpen={() => updateShowState("showLogState", false)}
        content={<ViewLog />}
      />
      <Modal
        title="Print Logs"
        open={showState.showPrintState}
        setOpen={() => updateShowState("showPrintState", false)}
        content={<LogPrint />}
      />
    </div>
  );
};

export default Logs;
