import React from "react";
import Header from "./components/Header";
import HeroBanner from "./components/HeroBanner";
import AdvantageSection from "./components/AdvantageSection";
import SleepingCardSection from "./components/SleepingCardSection";
import LittleDetailsSection from "./components/LittleDetailsSection";
import HDSection from "./components/HDSection";
import WMattersSection from "./components/WMattersSection";
import TestimonialsSection from "./components/TestimonialsSection";
import FacManagementSection from "./components/FacManagementSection";
import ContactSection from "./components/ContactSection";
import Footer from "./components/Footer";
import "bootstrap/dist/js/bootstrap.min.js";
import "./styles/main-scaled.scss";
import "./styles/icomoon.css";
import { useGsapAnimations } from "./hooks/useGsapAnimations";

const LandingPage = () => {
  useGsapAnimations();

  return (
    <div id="landing" className="bootstrap-scope">
      <div className="site-main body">
      <Header />
        <HeroBanner />
        <AdvantageSection />
        <SleepingCardSection />
        <LittleDetailsSection />
        <HDSection />
        <WMattersSection />
        <TestimonialsSection />
        <FacManagementSection />
        <ContactSection />
        <Footer />
      </div>
    </div>
  );
};

export default LandingPage;
