import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation } from "swiper/modules";
import "swiper/swiper-bundle.css";

const testimonials = [
  {
    quote:
      "Because of Sequra, I spend less time running the floors and sorting paper work, allowing me to focus on the things that matter.",
    name: "James Ferrer",
    position: "Regional Director",
    image: "/assets/images/james-ferrer.png",
  },
  {
    quote:
      "Sequra has helped me streamline my facility's maintenance program. I can now easily track all of my facilities needs and ensure that my facilities are being effectively run.",
    name: "Carrie Hartley",
    position: "Regional Director",
    image: "/assets/images/carrie-hartley.png",
  },
  {
    quote:
      "Using Sequra has saved me countless hours of work. I can now easily track all of my facilities needs and ensure that my facility is always up to code.",
    name: "Neftali Anadon",
    position: "Maintenance Director",
    image: "/assets/images/neftali-anadon.png",
  },
  {
    quote:
      "Because of Sequra, I spend less time running the floors and sorting paper work, allowing me to focus on the things that matter.",
    name: "Neftali Anadon",
    position: "Maintenance Director",
    image: "/assets/images/neftali-anadon.png",
  },
  {
    quote:
      "Using Sequra has saved me countless hours of work. I can now easily track all of my facilities needs and ensure that my facility is always up to code.",
    name: "Neftali Anadon",
    position: "Maintenance Director",
    image: "/assets/images/neftali-anadon.png",
  },
];

const TestimonialsSection: React.FC = () => {
  return (
    <div className="testimonials">
      <div className="container">
        <div className="section-heading fadeInUp">
          <h2>
            Hear From Our <strong>Happy Customers</strong>
          </h2>
          <div className="swiper-buttons swiper-buttons--mobile d-md-none">
            <div className="swiper-pagination"></div>
          </div>
        </div>
      </div>
      <div className="outer-swiper fadeInUp">
        <Swiper
          slidesPerView="auto"
          spaceBetween={8}
          speed={750}
          loop={true}
          modules={[Pagination, Navigation]}
          pagination={{
            el: ".testimonials .swiper-buttons--mobile .swiper-pagination",
            clickable: true,
          }}
          centeredSlides={true}
          autoplay={{
            delay: 8000,
            disableOnInteraction: false,
          }}
          breakpoints={{
            768: {
              spaceBetween: 30,
              pagination: {
                el: ".testimonials .swiper .swiper-pagination",
                clickable: true,
              },  
            },
          }}
          navigation={{
            prevEl: ".swiper-button-prev",
            nextEl: ".swiper-button-next",
          }}
          className="swiper js-testimonials-slider"
        >
          {testimonials.map((testimonial, index) => (
            <SwiperSlide key={index} className="swiper-slide testimonials-item">
              <blockquote>{testimonial.quote}</blockquote>
              <div className="testimonials-item__footer">
                <div className="row align-items-end">
                  <div className="col">
                    <div className="row align-items-center gx-3">
                      <div className="col-auto">
                       {/*  <img
                          className="avataar-img"
                          src={testimonial.image}
                          alt={testimonial.name}
                        /> */}
                      </div>
                      <div className="col">
                        <h5 className="avataar-name">{testimonial.name}</h5>
                        <div className="avataar-position">
                          {testimonial.position}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-auto">
                    <span className="icon-quote-right"></span>
                  </div>
                </div>
              </div>
            </SwiperSlide>
          ))}
          <div className="swiper-buttons d-none d-md-flex">
            <div className="swiper-button-prev"></div>
            <div className="swiper-pagination"></div>
            <div className="swiper-button-next"></div>
          </div>
        </Swiper>
      </div>
    </div>
  );
};

export default TestimonialsSection;
