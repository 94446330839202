import React from "react";

const managementItems = [
  {
    count: "01",
    title: "Compliance Templates & Regulatory Standards",
    description:
      "Access a comprehensive database of expert-curated task templates, keeping your facility in line with the highest standards.",
  },
  {
    count: "02",
    title: "Survey Audit Readiness",
    description:
      "Stay audit-ready with instant access to logs and reports, simplifying compliance and reducing stress.",
  },
  {
    count: "03",
    title: "Data Validation for Compliance",
    description:
      "Ensure every entry meets compliance standards with Sequra’s built-in data validation, keeping your operations streamlined and compliant.",
  },
  {
    count: "04",
    title: "Employee Performance Metrics",
    description:
      "Track productivity and efficiency with detailed employee performance metrics, offering clear insights for informed management.",
  },
  {
    count: "05",
    title: "Effortless Record Management",
    description:
      "Say goodbye to misplaced documents. Sequra’s record management provides immediate access to reports and documents, making audits a breeze.",
  },
];

const FacManagementSection: React.FC = () => {
  return (
    <div className="fac-management-section">
      <div className="container">
        <div className="inner-container">
          <div className="section-heading fadeInUp">
            <h2>
              Ahead of <strong>the Curve</strong>
            </h2>
            <p>
              Sequra is breaking new ground, going further than any available
              platform to deliver powerful capabilities and insights that are
              redefining what's possible in facility management.
            </p>
          </div>
        </div>
      </div>
      <div className="outer-columns">
        <div className="bgImg parallaxBgImg">
          <img
            className="bg"
            src="/assets/images/ze_casal_clean_and_modern_building-2.png"
            alt=""
          />
          <div className="floating-vector">
            <img
              className="w-100"
              src="/assets/images/bg-vector-2.png"
              alt=""
            />
          </div>
        </div>
        <div className="container">
          <div className="inner-container">
            <div className="row columns">
              {managementItems.map((item, index) => (
                <div key={index} className="col-md-4 column fadeInUp">
                  <div className="column__box">
                    <div className="column-item-count">{item.count}</div>
                    <h4>{item.title}</h4>
                    <p>{item.description}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FacManagementSection;
