import apiClient from "../apiClient";

export const demoRequestSubmit = async (data: any) => {
  try {
    await apiClient.post("/landingPageHandler/request", data);
  } catch (error) {
    console.error(error);
  }
};

export const requestDoc = async (link: string) => {
  await apiClient
    .get("/landingPageHandler/legaldoc", {
      params: { link },
    })
    .then((response) => {
      console.log(response.data);
      window.open(response.data, "_blank");
    })
    .catch((error) => {
      console.error("Error fetching file:", error);
    });
};
