import React, { useState, useEffect } from "react";
import { Swiper as SwiperComponent, SwiperSlide } from "swiper/react";
import { EffectCoverflow, Pagination } from "swiper/modules";
import "swiper/swiper-bundle.css";

const SleepingCardSection: React.FC = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className="sleeping-card-section">
      <div className="container">
        <div className="inner-container">
          <div className="section-heading fadeInUp">
            <h2>
              Sequra Users
              <strong>Sleep Better</strong>
            </h2>
            <div className="swiper-buttons swiper-buttons--mobile d-md-none">
              <div className="swiper-pagination"></div>
            </div>
          </div>

          {isMobile ? (
            <SwiperComponent
              modules={[Pagination, EffectCoverflow]}
              effect="coverflow"
              grabCursor={true}
              centeredSlides={true}
              coverflowEffect={{
                rotate: -10,
                stretch: 0,
                depth: 100,
                modifier: 2,
                slideShadows: false,
              }}
              pagination={{
                el: ".sleeping-card-section .swiper-pagination",
                clickable: true,
              }}
              spaceBetween={0}
              loop={false}
              slidesPerView={1.2}
              className="js-card-slider fadeInUp"
              breakpoints={{
                640: {
                  slidesPerView: 2,
                },
                1024: {
                  slidesPerView: 3,
                },
              }}
            >
              <SwiperSlide className="card">
                <div className="card__box">
                  <div
                    className="bgImg"
                    style={{
                      backgroundImage: "url('/assets/images/bg-card-1.png')",
                    }}
                  />
                  <div className="ico-holder">
                    <span className="icon-favicon-symbol"></span>
                  </div>
                  <h3>Precision</h3>
                  <p>
                    Sequra's customizability empowers operators and directors with
                    precise, fine-grained control, ensuring optimal flexibility
                    and accuracy
                  </p>
                </div>
              </SwiperSlide>

              <SwiperSlide className="card">
                <div className="card__box">
                  <div
                    className="bgImg"
                    style={{
                      backgroundImage: "url('/assets/images/bg-card-2.png')",
                    }}
                  />
                  <div className="ico-holder">
                    <span className="icon-favicon-symbol"></span>
                  </div>
                  <h3>Protection</h3>
                  <p>
                    Crafted by industry professionals and built to meet the
                    highest standards, Sequra delivers the regulatory protection
                    your facility needs to stay compliant and secure.
                  </p>
                </div>
              </SwiperSlide>

              <SwiperSlide className="card">
                <div className="card__box">
                  <div
                    className="bgImg"
                    style={{
                      backgroundImage: "url('/assets/images/bg-card-3.png')",
                    }}
                  />
                  <div className="ico-holder">
                    <span className="icon-favicon-symbol"></span>
                  </div>
                  <h3>Perfection</h3>
                  <p>
                    Sequra sets the benchmark for regulatory excellence by
                    standardizing processes, streamlining workflows, validating
                    critical data, and enhancing communication across your
                    operations
                  </p>
                </div>
              </SwiperSlide>
            </SwiperComponent>
          ) : (
            <div className="swiper js-card-slider fadeInUp">
            <div className="swiper-wrapper">
              <div className="swiper-slide card">
                <div className="card__box">
                  <div className="bgImg" style={{ backgroundImage: "url('/assets/images/bg-card-1.png')" }}></div>
                  <div className="ico-holder">
                    <span className="icon-favicon-symbol"></span>
                  </div>
                  <h3>Precision</h3>
                  <p>
                    Sequra's customizability empowers
                    operators and directors with precise, fine-grained
                    control, ensuring optimal flexibility and accuracy
                  </p>
                </div>
              </div>
              <div className="swiper-slide card">
                <div className="card__box">
                  <div className="bgImg" style={{ backgroundImage: "url('/assets/images/bg-card-2.png')" }}></div>
                  <div className="ico-holder">
                    <span className="icon-favicon-symbol"></span>
                  </div>
                  <h3>Protection</h3>
                  <p>
                    Crafted by industry professionals and built
                    to meet the highest standards, Sequra delivers the
                    regulatory protection your facility needs to stay
                    compliant and secure.
                  </p>
                </div>
              </div>
              <div className="swiper-slide card">
                <div className="card__box">
                  <div className="bgImg" style={{ backgroundImage: "url('/assets/images/bg-card-3.png')" }}></div>
                  <div className="ico-holder">
                    <span className="icon-favicon-symbol"></span>
                  </div>
                  <h3>Perfection</h3>
                  <p>
                    Sequra sets the benchmark for regulatory
                    excellence by standardizing processes, streamlining
                    workflows, validating critical data, and enhancing
                    communication across your operations
                  </p>
                </div>
              </div>
            </div>
          </div>
          )}
        </div>
      </div>
      <div className="floating-vector">
        <img
          className="w-100 parallaxBlockUp"
          src="/assets/images/bg-vector-2.png"
          alt=""
        />
      </div>
    </div>
  );
};

export default SleepingCardSection;