import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import { useFacility } from "../hooks/useFacility";
import moment from "moment-timezone";
import {
  Add,
  CheckOutlined,
  DoneAllOutlined,
  TimerOutlined,
} from "@mui/icons-material";
import {
  fetchChartData,
  fetchTableData,
} from "../api/services/AnalyticsService";
import { determineChangeType } from "../utils/FormatFunctions";

interface DateParams {
  displayText: string;
  startDate: Date | null;
  endDate: Date | null;
  selectedRange: string;
}

interface Stat {
  name: string;
  icon: JSX.Element;
  stat: string | number;
  currentContent?: any;
  previousStat: string | number;
  change: string;
  changeType: string;
  canView: boolean;
}

type FilterOptions = "dataSets" | "status" | null;

interface ChartDataElement {
  date: string;
  created: number;
  completed: number;
  closed: number;
}

// Define the type for the context values
interface AnalyticsContextType {
  workOrderStats: Stat[];
  recurringStats: Stat[];
  chartData: ChartDataElement[];
  showState: {
    showCompleted: boolean;
    showClosed: boolean;
    showCreated: boolean;
    showWorkOrders: boolean;
    showRecurring: boolean;
    showStatModal: boolean;
  };
  updateShowState: (key: string, value: boolean) => void;
  dateParams: DateParams;
  setDateParams: Dispatch<SetStateAction<DateParams>>;
  currentFilter: FilterOptions;
  setCurrentFilter: Dispatch<SetStateAction<FilterOptions>>;
  handleViewStat: (item: Stat) => void;
  selectedStat: Stat | null;
}

// Define the type for the provider props
interface AnalyticsProviderProps {
  children: ReactNode;
}

// Create the context
export const AnalyticsContext = createContext<AnalyticsContextType | undefined>(
  undefined
);

// Create the provider component
export const AnalyticsProvider = ({ children }: AnalyticsProviderProps) => {
  const { selectedFacility } = useFacility();

  const [dateParams, setDateParams] = useState<DateParams>({
    displayText: moment().format("MMMM YYYY"),
    startDate: moment().subtract(1, "week").toDate(),
    endDate: moment().toDate(),
    selectedRange: "week",
  });

  const [showState, setShowState] = useState({
    showCompleted: true,
    showClosed: true,
    showCreated: true,
    showWorkOrders: true,
    showRecurring: true,
    showStatModal: false,
  });

  const [currentFilter, setCurrentFilter] = useState<FilterOptions>(null);

  const [workOrderStats, setWorkOrderStats] = useState<Stat[]>([
    {
      name: "Created",
      icon: <Add className="rounded-sm text-secondary-100 bg-secondary-1000" />,
      stat: 0,
      previousStat: 0,
      change: "0.00%",
      changeType: "increase",
      canView: true,
    },
    {
      name: "Completed",
      icon: (
        <CheckOutlined className="rounded-sm text-secondary-100 bg-secondary-1000" />
      ),
      stat: 0,
      previousStat: 0,
      change: "0.00%",
      changeType: "increase",
      canView: true,
    },
    {
      name: "Closed",
      stat: 0,
      icon: (
        <DoneAllOutlined className="rounded-sm text-secondary-100 bg-secondary-1000" />
      ),
      previousStat: 0,
      change: "0.00%",
      changeType: "increase",
      canView: true,
    },
    {
      name: "Avg. Time to Complete",
      icon: (
        <TimerOutlined className="rounded-sm text-secondary-100 bg-secondary-1000" />
      ),
      stat: "0 days",
      previousStat: "0 days",
      change: "0.00%",
      changeType: "increase",
      canView: false,
    },
  ]);

  const [recurringStats, setRecurringStats] = useState<Stat[]>([
    {
      name: "Completed",
      icon: (
        <CheckOutlined className="rounded-sm text-secondary-100 bg-secondary-1000" />
      ),
      stat: 0,
      previousStat: 0,
      change: "0.00%",
      changeType: "increase",
      canView: false,
    },
    {
      name: "Closed",
      icon: (
        <DoneAllOutlined className="rounded-sm text-secondary-100 bg-secondary-1000" />
      ),
      stat: 0,
      previousStat: 0,
      change: "0.00%",
      changeType: "increase",
      canView: false,
    },
    {
      name: "Overdue Tasks",
      icon: (
        <TimerOutlined className="rounded-sm text-secondary-100 bg-secondary-1000" />
      ),
      stat: 0,
      previousStat: 0,
      change: "0.00%",
      changeType: "increase",
      canView: false,
    },
  ]);

  const [chartData, setChartData] = useState<ChartDataElement[]>([]);

  const updateShowState = (key: string, value: boolean) => {
    setShowState((prev) => ({ ...prev, [key]: value }));
  };

  //Populates data for data bars
  useEffect(() => {
    const loadTableData = async () => {
      try {
        const startDate = moment(dateParams.startDate).format("YYYY-MM-DD");
        const endDate = moment(dateParams.endDate).format("YYYY-MM-DD");

        if (startDate === "Invalid date" || endDate === "Invalid date") return;
        const dateQuery = dateParams.selectedRange;

        const { workOrders, recurringTasks } = await fetchTableData(
          selectedFacility,
          { startDate, endDate, dateQuery }
        );

        const {
          createdWorkOrders,
          completeWorkOrders,
          closedWorkOrders,
          timeWorkOrders,
        } = workOrders;

        const { completeRecurring, closedRecurring, overdueRecurring } =
          recurringTasks;

        setWorkOrderStats((prevStats) => [
          {
            ...prevStats[0],
            stat: createdWorkOrders.current.length,
            currentContent: createdWorkOrders.current,
            previousStat: createdWorkOrders.previous.length,
            change: createdWorkOrders.change,
            changeType: determineChangeType(createdWorkOrders.change),
          },
          {
            ...prevStats[1],
            stat: completeWorkOrders.current.length,
            currentContent: completeWorkOrders.current,
            previousStat: completeWorkOrders.previous.length,
            change: completeWorkOrders.change,
            changeType: determineChangeType(completeWorkOrders.change),
          },
          {
            ...prevStats[2],
            stat: closedWorkOrders.current.length,
            currentContent: closedWorkOrders.current,
            previousStat: closedWorkOrders.previous.length,
            change: closedWorkOrders.change,
            changeType: determineChangeType(closedWorkOrders.change),
          },
          {
            ...prevStats[3],
            stat: `${timeWorkOrders.current} Days`,
            previousStat: `${timeWorkOrders.previous} Days`,
            change: timeWorkOrders.change,
            changeType: determineChangeType(timeWorkOrders.change),
          },
        ]);
        setRecurringStats((prevStats) => [
          {
            ...prevStats[0],
            stat: completeRecurring.current,
            previousStat: completeRecurring.previous,
            change: completeRecurring.change,
            changeType: determineChangeType(completeRecurring.change),
          },
          {
            ...prevStats[1],
            stat: closedRecurring.current,
            previousStat: closedRecurring.previous,
            change: closedRecurring.change,
            changeType: determineChangeType(closedRecurring.change),
          },
          {
            ...prevStats[2],
            stat: overdueRecurring.current,
            previousStat: overdueRecurring.previous,
            change: overdueRecurring.change,
            changeType: determineChangeType(overdueRecurring.change),
          },
        ]);
      } catch (error) {
        console.error("Error:", error);
      }
    };

    if (!selectedFacility) return;

    loadTableData();
  }, [dateParams, selectedFacility]);

  useEffect(() => {
    const loadChartData = async () => {
      const startDate = moment(dateParams.startDate).format("YYYY-MM-DD");
      const endDate = moment(dateParams.endDate).format("YYYY-MM-DD");
      const showStateQuery = JSON.stringify(showState);

      if (startDate === "Invalid date" || endDate === "Invalid date") return;

      try {
        const fetchedChartData = await fetchChartData(selectedFacility, {
          startDate,
          endDate,
          showStateQuery,
        });
        setChartData(fetchedChartData);
      } catch (error) {
        console.error("Error:", error);
      }
    };

    if (!selectedFacility) return;

    loadChartData();
  }, [dateParams, selectedFacility, showState]);

  const [selectedStat, setSelectedStat] = useState<Stat | null>(null);
  const handleViewStat = (item: Stat) => {
    setSelectedStat(item);
    updateShowState("showStatModal", true);
  };

  return (
    <AnalyticsContext.Provider
      value={{
        workOrderStats,
        recurringStats,
        chartData,
        showState,
        updateShowState,
        dateParams,
        setDateParams,
        currentFilter,
        setCurrentFilter,
        handleViewStat,
        selectedStat,
      }}
    >
      {children}
    </AnalyticsContext.Provider>
  );
};
