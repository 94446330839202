import React, { useState, Fragment } from "react";
import axios from "axios";
import { CheckCircleOutline, ErrorOutline, Person } from "@mui/icons-material";
import UploadImage from "../../updatedcomponents/forms/UploadImage";
import {
  SubmitHandler,
  useForm,
  UseFormRegister,
  UseFormSetValue,
} from "react-hook-form";
import PageHeader from "../../updatedcomponents/general-ui/PageHeader";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useAuth } from "../../hooks/useAuth";
import Button from "../../updatedcomponents/general/Button";
import { useSettings } from "../../hooks/useSettings";
import Modal from "../../updatedcomponents/general-ui/Modal";
import Input from "../../updatedcomponents/general-ui/Input";

export interface UpdateUserFormData {
  firstname: string;
  lastname: string;
  phone?: string;
  email: string;
  password?: string;
  newPassword?: string;
  confirmNewPassword?: string;
  emailNotifications?: {
    taskAssignment: boolean;
    workOrderCreation: boolean;
    urgentWorkOrderCreation: boolean;
  };
  smsNotifications?: {
    taskAssignment: boolean;
    workOrderCreation: boolean;
    urgentWorkOrderCreation: boolean;
  };
}

interface Response {
  show: boolean;
  title: string;
  body: string;
  icon: React.ReactNode | null;
}


const SettingsPage: React.FC = () => {
  const { currentUser, dispatch } = useAuth();
  const { showState, updateShowState, handleFormSubmit } = useSettings();

  const permissions = currentUser?.access || "";

  const schema = yup.object().shape({
    firstname: yup.string().required("*Required"),
    lastname: yup.string().required("*Required"),
    phone: yup.string(),
    email: yup
      .string()
      .email("*Must be a valid email address.")
      .required("*Required"),
    password: yup.string(),
    newPassword: yup.string(),
    confirmNewPassword: yup
      .string()
      .oneOf([yup.ref("newPassword"), undefined], "Passwords must match"),
  });

  const {
    register,
    handleSubmit,
    setValue,
    setError,
    watch,
    control,
    formState: { errors, isDirty },
  } = useForm<UpdateUserFormData>({
    resolver: yupResolver(schema),
    defaultValues: {
      firstname: currentUser?.firstname || "",
      lastname: currentUser?.lastname || "",
      email: currentUser?.email || "",
      phone: currentUser?.phone || "",
      emailNotifications: {
        taskAssignment:
          currentUser?.emailNotifications?.taskAssignment || false,
        workOrderCreation:
          currentUser?.emailNotifications?.workOrderCreation || false,
        urgentWorkOrderCreation:
          currentUser?.emailNotifications?.urgentWorkOrderCreation || false,
      },
      smsNotifications: {
        taskAssignment: currentUser?.smsNotifications?.taskAssignment || false,
        workOrderCreation:
          currentUser?.smsNotifications?.workOrderCreation || false,
        urgentWorkOrderCreation:
          currentUser?.smsNotifications?.urgentWorkOrderCreation || false,
      },
      password: "",
      newPassword: "",
      confirmNewPassword: "",
    },
  });

  const [response, setResponse] = useState<Response>({
    show: false,
    title: "",
    body: "",
    icon: null,
  });

  const userId = currentUser?._id;

  const checkboxes = [
    {
      label: "Task Assignment",
      description: "Receive notifications whenever you are assigned a new task",
      types: [
        "emailNotifications.taskAssignment",
        "smsNotifications.taskAssignment",
      ],
      access: [
        "worker",
        "administrator",
        "director",
        "regional",
        "organization-admin",
      ],
    },
    {
      label: "All Work Order Creation",
      description: "Receive notifications whenever a work order is created",
      types: [
        "emailNotifications.workOrderCreation",
        "smsNotifications.workOrderCreation",
      ],
      access: ["administrator", "director", "regional", "organization-admin"],
    },
    {
      label: "Urgent Work Order Creation",
      description:
        "Receive notifications whenever an urgent work order is created",
      types: [
        "emailNotifications.urgentWorkOrderCreation",
        "smsNotifications.urgentWorkOrderCreation",
      ],
      access: ["administrator", "director", "regional", "organization-admin"],
    },
  ];

  return (
    <>
      <PageHeader
        title="Settings"
        buttons={
          <Button
            styleString="primary"
            children="Save Changes"
            onClick={handleSubmit(handleFormSubmit)}
            disabled={!isDirty}
          />
        }
      />

      <div className="px-4 sm:px-6 lg:px-8 mx-auto mb-8">
        <form noValidate onSubmit={handleSubmit(handleFormSubmit)}>
          {/* PERSONAL INFORMATION SECTION */}
          <div className="flex w-full flex-col sm:flex-row justify-between mx-auto py-6 border-b border-secondary-1000">
            <h2 className="text-secondary-100 text-xl">Personal Information</h2>
            <h3 className="text-secondary-500 text-md font-normal">
              Information related to your account
            </h3>
          </div>
          <div className="my-4 flex items-center gap-x-4">
            {currentUser?.avatar ? (
              <img
                src={currentUser?.avatar}
                alt=""
                className="h-20 w-20 flex-none rounded-full bg-gray-800 object-cover"
              />
            ) : (
              <Person className="flex-none rounded-full bg-gray-50 h-24 object-cover" />
            )}
            <div>
              <button
                type="button"
                className="rounded-sm bg-secondary-1000 px-3 py-1 text-xs font-medium text-secondary-100  hover:bg-secondary-1100"
                onClick={() => updateShowState("showPhotoAdd", true)}
              >
                Change avatar
              </button>
              <p className="mt-2 text-xs  text-secondary-100">
                JPG, GIF or PNG. 1MB max.
              </p>
            </div>
          </div>
          <div className="my-4 w-full">
            <div className="grid grid-cols-1 sm:grid-cols-3 space-y-4">
              <label className="flex items-center text-md font-medium leading-6 text-secondary-100 col-span-1 sm:col-span-2">
                Name
              </label>
              <div className="flex flex-col sm:flex-row gap-4 justify-start w-full">
                <Input
                  label="First Name"
                  type="text"
                  name="firstname"
                  register={register}
                  errors={errors.firstname}
                />
                <Input
                  label="Last Name"
                  type="text"
                  name="lastname"
                  register={register}
                  errors={errors.lastname}
                />
              </div>

              <label className="flex items-center text-md font-medium leading-6 text-secondary-100 col-span-1 sm:col-span-2">
                Phone
              </label>
              <div className="flex w-full gap-4">
                <div className="flex w-full flex-col">
                  <Input
                    label="Phone"
                    type="text"
                    name="phone"
                    showLabel={false}
                    register={register}
                    errors={errors.phone}
                    disabled={!showState.changePhone}
                  />
                </div>
                <button
                  type="button"
                  onClick={() =>
                    updateShowState("changePhone", !showState.changePhone)
                  }
                  className="flex items-center justify-center gap-2 rounded-sm bg-secondary-1000 px-4 py-1 text-center text-sm font-semibold text-secondary-100 border-transparent border shadow-sm hover:bg-secondary-1100"
                >
                  Change
                </button>
              </div>
            </div>
          </div>

          {/* SECURITY SECTION */}
          <div className="flex w-full flex-col sm:flex-row justify-between mx-auto py-6 border-b border-secondary-1000">
            <h2 className="text-secondary-100 text-xl">Account Security</h2>
            <h3 className="text-secondary-500 text-md font-normal">
              Important security information for your account
            </h3>
          </div>
          <div className="my-4 w-full">
            <div className="grid grid-cols-1 sm:grid-cols-3 space-y-4">
              <label className="flex items-center text-md font-medium leading-6 text-secondary-100 col-span-1 sm:col-span-2">
                Email
              </label>
              <div className="flex gap-4 w-full">
                <div className="flex w-full flex-col">
                  <Input
                    label="Email"
                    type="email"
                    name="email"
                    register={register}
                    errors={errors.email}
                    disabled={!showState.changeEmail}
                    showLabel={false}
                  />
                </div>

                <button
                  type="button"
                  onClick={() =>
                    updateShowState("changeEmail", !showState.changeEmail)
                  }
                  className="flex h-fit items-center justify-center gap-2 rounded-sm bg-secondary-1000 px-4 py-1 text-center text-sm font-semibold text-secondary-100 border-transparent border shadow-sm hover:bg-secondary-1100"
                >
                  Change
                </button>
              </div>

              <label className="flex items-center text-md font-medium leading-6 text-secondary-100 col-span-1 sm:col-span-2">
                Current Password
              </label>
              <div className="flex gap-4 w-full">
                <div className="flex w-full flex-col">
                  <Input
                    label="Password"
                    type="password"
                    name="password"
                    register={register}
                    errors={errors.password}
                    disabled={!showState.changePassword}
                    showLabel={false}
                  />
                </div>
                <button
                  type="button"
                  onClick={() =>
                    updateShowState("changePassword", !showState.changePassword)
                  }
                  className="flex items-center justify-center gap-2 rounded-sm bg-secondary-1000 px-4 py-1 text-center text-sm font-semibold text-secondary-100 border-transparent border shadow-sm hover:bg-secondary-1100"
                >
                  Change
                </button>
              </div>
              {showState.changePassword && (
                <>
                  <label className="flex items-center text-md font-medium leading-6 text-secondary-100 col-span-1 sm:col-span-2">
                    New Password
                  </label>
                  <div className="flex flex-col">
                    <Input
                      label="New Password"
                      type="password"
                      name="newPassword"
                      register={register}
                      errors={errors.newPassword}
                      showLabel={false}
                    />
                  </div>
                  <label className="flex items-center text-md font-medium leading-6 text-secondary-100 col-span-1 sm:col-span-2">
                    Confrim New Password
                  </label>
                  <div className="flex flex-col">
                    <Input
                      label="Confirm New Password"
                      type="password"
                      name="confirmNewPassword"
                      register={register}
                      errors={errors.confirmNewPassword}
                      showLabel={false}
                    />
                  </div>
                </>
              )}
            </div>
          </div>
          {/* NOTIFICATIONS SECTION */}
          <div className="flex w-full flex-col sm:flex-row justify-between mx-auto py-6 border-b border-secondary-1000">
            <h2 className="text-secondary-100 text-xl">Notifications</h2>
            <h3 className="text-secondary-500 text-md font-normal">
              Stay informed with notifications
            </h3>
          </div>
          <div className="w-full">
            <div className="grid grid-cols-3 space-y-6 text-end justify-end">
              <div></div>
              <div className="flex justify-end ">
                <label className="flex items-center text-md font-medium leading-6 text-secondary-100 ">
                  Email
                </label>
              </div>
              <div className="flex justify-end">
                <label className="flex items-center text-md font-medium leading-6 text-secondary-100 ">
                  SMS
                </label>
              </div>
              {checkboxes
                .filter(({ access }) => access.includes(permissions))
                .map(({ label, description, types }, index) => (
                  <Fragment key={index}>
                    <div className="text-left">
                      <label className="flex items-center text-md font-medium leading-6 text-secondary-100 ">
                        {label}
                      </label>
                      <p className="text-xs text-secondary-500">
                        {description}
                      </p>
                    </div>

                    {types.map((type, index) => (
                      <div
                        key={index}
                        className="flex justify-end items-center w-full h-fit text-right"
                      >
                        <Input
                          key={type}
                          control={control}
                          label=""
                          errors={errors}
                          type="switch"
                          name={type as keyof UpdateUserFormData}
                          showLabel={false}
                        />
                      </div>
                    ))}
                  </Fragment>
                ))}
            </div>
          </div>
        </form>
      </div>

      <Modal
        title="Profile photo"
        open={showState.showPhotoAdd}
        setOpen={() => updateShowState("showPhotoAdd", false)}
        content={<UploadImage />}
      />
    </>
  );
};

export default SettingsPage;
