import { useState } from "react";
import MapList from "../../updatedcomponents/tables/MapList";
import CreateMapButton from "../../updatedcomponents/forms/CreateMapForm";
import FilesList from "../../updatedcomponents/tables/FilesList";
import FileUpload from "../../updatedcomponents/modals/FileUploader";
import KioskList from "../../updatedcomponents/tables/KioskList";
import CreateKioskForm from "../../updatedcomponents/forms/CreateKioskForm";
import PageHeader from "../../updatedcomponents/general-ui/PageHeader";
import {
  Add,
  ArrowBack,
  ComputerOutlined,
  MapOutlined,
  PolicyOutlined,
  SourceOutlined,
} from "@mui/icons-material";
import { useBuilding } from "../../hooks/useBuilding";
import GenericModal from "../../updatedcomponents/modals/GenericModal";
import Modal from "../../updatedcomponents/general-ui/Modal";
const MyBuilding = () => {
  const {
    kiosks,
    docs,
    handleAddFile,
    selectedKiosk,
    handleShowKiosk,
    handleUpdateKioskStatus,
    showState,
    updateShowState,
    selectedMap,
    handleDeleteMap,
    handleShowMap,
    fileType,
    selectedFile,
    handleDeleteFile
  } = useBuilding();

  const [showId, setShowId] = useState(0);

  interface PageItem {
    id: number;
    icon: any;
    title: string;
    subtitle: string;
    list: any;
    type: string;
    clickParam?: any;
    clickFunction: (...args: any) => void;
    kiosks?: object[];
    files?: object[];
  }

  const pageItems: PageItem[] = [
    {
      id: 1,
      icon: MapOutlined,
      title: "Maps",
      subtitle: "View and manage maps for your building",
      list: MapList,
      type: "map",
      clickParam: true,
      clickFunction: () => handleShowMap(null),
    },
    {
      id: 2,
      icon: ComputerOutlined,
      title: "My Kiosks",
      subtitle: "View and manage kiosks for your building",
      list: KioskList,
      type: "kiosk",
      kiosks: kiosks,
      clickFunction: () => handleShowKiosk(null),
    },
    {
      id: 3,
      icon: SourceOutlined,
      title: "My Documents",
      subtitle: "View and manage documents for your building",
      list: FilesList,
      type: "documents",
      files: docs.documents,
      clickFunction: handleAddFile,
      clickParam: "document",
    },
    {
      id: 4,
      icon: PolicyOutlined,
      title: "My Policies",
      subtitle: "View and manage policies for your building",
      list: FilesList,
      type: "policies",
      files: docs.policies,
      clickParam: "policy",
      clickFunction: handleAddFile,
    },
  ];

  const selectedItem = pageItems.find((item) => item.id === showId);

  return (
    <div className="relative">
      {showId === 0 ? (
        <PageHeader
          title={<div className="animate-fade-in-right">My Building</div>}
          subtitle={""}
        />
      ) : (
        <PageHeader
          title={
            <button
              className=" text-secondary-100"
              onClick={() => setShowId(0)}
            >
              <div className="flex items-center gap-2">
                <ArrowBack
                  className="text-secondary-300"
                  style={{ fontSize: "1rem" }}
                />
                <span className="animate-fade-in-left">
                  Back to my building
                </span>
              </div>
            </button>
          }
        />
      )}
      <div className="flex relative ">
        <ul
          role="list"
          className={`transition-all duration-500 ease-in-out flex gap-4 bg-secondary-1100 sm:bg-primary ${
            showId === 0
              ? "w-full p-4 justify-evenly flex-col sm:flex-row sm:justify-start sm:items-center "
              : "w-1/3 p-4 hidden sm:flex sm:flex-col border-r border-secondary-1000 overflow-y-auto hide-scrollbar-arrows"
          } sm:max-h-[calc(100vh-118px)] overflow-y-auto overflow-x-hidden hide-scrollbar-arrows`}
        >
          {pageItems.map((item) => (
            <li
              key={item.id}
              className={`flex h-full flex-1 border bg-primary ${
                showId === item.id
                  ? "border-secondary-100"
                  : "border-secondary-1000"
              } ${
                showId !== 0 ? "cursor-pointer" : ""
              } rounded-sm flex flex-col p-4`}
              onClick={() => {
                showId !== 0 && setShowId(item.id);
              }}
            >
              {
                <item.icon
                  style={{ fontSize: "2rem" }}
                  className={` mb-4  rounded-full p-1.5 ${
                    showId === item.id
                      ? "bg-secondary-100 text-secondary-1000"
                      : "bg-secondary-1000 text-secondary-100"
                  }`}
                />
              }

              <p className="text-md font-medium text-secondary-100">
                {item.title}
              </p>
              <p className="text-sm text-secondary-500 pb-4">{item.subtitle}</p>
              <div className="flex h-full flex-col justify-end gap-2">
                <button
                  className={`${
                    showId === item.id
                      ? "border border-secondary-100"
                      : "border border-secondary-1000"
                  } flex justify-center items-center rounded-sm bg-primary px-3 py-2 text-sm font-semibold text-secondary shadow-sm hover:bg-secondary-1200 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 `}
                  onClick={() => item.clickFunction(item.clickParam)}
                >
                  <Add style={{ fontSize: "1rem" }} /> Add {item.type}
                </button>
                {showId === 0 && (
                  <button
                    className="flex justify-center items-center rounded-sm border border-secondary-1000 bg-secondary-1000 px-3 py-2 text-sm font-semibold text-secondary-100 shadow-sm  focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2"
                    onClick={() => setShowId(item.id)}
                  >
                    View Details
                  </button>
                )}
              </div>
            </li>
          ))}
        </ul>
        {showId !== 0 && selectedItem && (
          <div className="m-2 w-full  sm:max-h-[calc(100vh-116px)] overflow-scroll hide-scrollbar-arrows">
            {<selectedItem.list {...selectedItem} />}
          </div>
        )}

        <Modal
          title={`Upload ${fileType}`}
          open={showState.showFileAdd}
          setOpen={() => updateShowState("showFileAdd", false)}
          content={<FileUpload />}
        />

        <Modal
          title="Create Map"
          open={showState.showMapCreate}
          setOpen={() => updateShowState("showMapCreate", false)}
          content={<CreateMapButton />}
        />

        <Modal
          title={selectedKiosk ? "Update Kiosk" : "Create Kiosk"}
          open={showState.showKioskAdd}
          setOpen={() => updateShowState("showKioskAdd", false)}
          content={<CreateKioskForm />}
        />

        <GenericModal
          title="Delete Map"
          showModal={showState.showDelete}
          item={selectedMap}
          setShowModal={() => updateShowState("showDelete", false)}
          handleFunction={handleDeleteMap}
        />
        <GenericModal
          title="Deactivate Kiosk"
          showModal={showState.showDeactivate}
          item={selectedKiosk}
          setShowModal={() => updateShowState("showDeactivate", false)}
          handleFunction={() => handleUpdateKioskStatus("inactive")}
          buttonText="Deactivate"
        />
        <GenericModal
          title="Reactivate Kiosk"
          showModal={showState.showReactivate}
          item={selectedKiosk}
          setShowModal={() => updateShowState("showReactivate", false)}
          handleFunction={() => handleUpdateKioskStatus("active")}
          buttonText="Reactivate"
          color="accent-500"
        />
        {selectedFile && (
          <GenericModal
            title={`Delete ${selectedFile.name}`}
            item={selectedFile}
            showModal={showState.showDeleteFile}
            setShowModal={() => updateShowState("showDeleteFile", false)}
            handleFunction={() => handleDeleteFile()}
          />
        )}
      </div>
    </div>
  );
};

export default MyBuilding;
