import React, { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { OnboardingFormValues } from "./OnboardingSchema";
import Button from "../updatedcomponents/general/Button";
import { formatDate } from "../utils/FormatFunctions";

interface SummaryProps {
  navigate: (step: number) => void;
}

const Summary: React.FC<SummaryProps> = ({ navigate }) => {
  const { getValues } = useFormContext<OnboardingFormValues>();

  const [expandedMapIndex, setExpandedMapIndex] = useState<number | null>(null);

  const toggleMapFields = (index: number) => {
    setExpandedMapIndex(expandedMapIndex === index ? null : index);
  };

  const formData = getValues();
  const { facility, users, kiosks, maps, tasks } = formData;

  return (
    <div className="flex flex-col gap-y-2 divide-y divide-secondary-1000 animate-fade-in-right">
      {/* Facility Section */}
      <div>
        <div className="flex w-full justify-between">
          <label className="text-md font-light text-secondary-100">
            Facility Information
          </label>
          <div className="flex w-fit">
            <Button
              children="Edit Section"
              styleString="secondary"
              onClick={() => navigate(1)}
            />
          </div>
        </div>
        <div className="grid grid-cols-2 py-2">
          <span className="text-sm font-light text-secondary-300">Name</span>
          <span className="text-sm text-secondary-100">{facility.name}</span>
          <span className="text-sm font-light text-secondary-300">Address</span>
          <span className="text-sm text-secondary-100">{facility.address}</span>
        </div>
      </div>
      {/* User Section */}
      <div>
        <div className="flex w-full justify-between mt-2">
          <label className="text-md font-light text-secondary-100">Users</label>
          <div className="flex w-fit">
            <Button
              children="Edit Section"
              styleString="secondary"
              onClick={() => navigate(2)}
            />
          </div>
        </div>{" "}
        {users.map((user, index) => (
          <div className="grid grid-cols-2 py-2" key={index}>
            <span className="text-sm font-light text-secondary-300">Name</span>
            <span className="text-sm text-secondary-100">
              {user.firstname} {user.lastname}
            </span>
            <span className="text-sm font-light text-secondary-300">Email</span>
            <span className="text-sm text-secondary-100">{user.email}</span>
            <span className="text-sm font-light text-secondary-300">Phone</span>
            <span className="text-sm text-secondary-100">{user.phone}</span>
            <span className="text-sm font-light text-secondary-300">
              Access
            </span>
            <span className="text-sm text-secondary-100">{user.access}</span>
          </div>
        ))}
      </div>
      {/* Kiosk Section */}
      <div>
        <div className="flex w-full justify-between mt-2">
          <label className="text-md font-light text-secondary-100">
            Kiosks
          </label>
          <div className="flex w-fit">
            <Button
              children="Edit Section"
              styleString="secondary"
              onClick={() => navigate(3)}
            />
          </div>
        </div>{" "}
        {kiosks.map((kiosk, index) => (
          <div className="grid grid-cols-2 py-2" key={index}>
            <span className="text-sm font-light text-secondary-300">
              Location
            </span>
            <span className="text-sm text-secondary-100">{kiosk.location}</span>
          </div>
        ))}
      </div>
      {/* Map Section */}
      <div>
        <div className="flex w-full justify-between mt-2">
          <label className="text-md font-light text-secondary-100">Maps</label>
          <div className="flex w-fit">
            <Button
              children="Edit Section"
              styleString="secondary"
              onClick={() => navigate(4)}
            />
          </div>
        </div>
        <div className="divide-y">
          {maps.map((map, index) => (
            <div className="grid grid-cols-1 my-2" key={index}>
              <div className="flex justify-between items-center py-2">
                <span className="text-sm font-medium text-secondary-100">
                  {map.name}
                </span>
                {!map.skipped ? (
                  map.fields.length > 0 && (
                    <button
                      type="button"
                      className="text-sm text-secondary-300"
                      onClick={() => toggleMapFields(index)}
                    >
                      {expandedMapIndex === index ? "Hide" : "Show"}
                    </button>
                  )
                ) : (
                  <span className="text-secondary-300 bg-secondary-1100 rounded-full px-1.5 py-0.5 text-xs">
                    Skipped
                  </span>
                )}
              </div>
              {expandedMapIndex === index && (
                <div>
                  {map.fields.map((field, fieldIndex) => (
                    <div className="ml-6 border-l pl-1" key={fieldIndex}>
                      <span className="text-sm text-secondary-100">
                        {field.identifier}
                      </span>
                    </div>
                  ))}
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
      {/* Task Section */}
      <div>
        <div className="flex w-full justify-between mt-2">
          <label className="text-md font-light text-secondary-100">Tasks</label>
          <div className="flex w-fit">
            <Button
              children="Edit Section"
              styleString="secondary"
              onClick={() => navigate(5)}
            />
          </div>
        </div>{" "}
        {tasks.map((task, index) => (
          <div className="grid grid-cols-2 py-2 divide-y" key={index}>
            <div className="text-sm font-light text-secondary-300">Task</div>
            <div className="flex flex-col">
              <span className="text-sm text-secondary-100">{task.title}</span>

              {task.skipped ? (
                <span className="text-secondary-300 bg-secondary-1100 rounded-full px-1.5 py-0.5 text-xs w-fit">
                  Skipped
                </span>
              ) : (
                <span className="text-sm text-secondary-100">
                  {formatDate(task.startDate)}
                </span>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Summary;
