import React from "react";
import { Dialog } from "@headlessui/react";
import { Close } from "@mui/icons-material";

interface Modal {
  open: boolean;
  setOpen: (open: boolean) => void;
  content: React.ReactNode;
  title?: string;
  subtitle?: string | React.ReactNode;
}

const Modal: React.FC<Modal> = ({
  open,
  setOpen,
  content,
  title = "",
  subtitle = "",
}) => {
  return (
    <Dialog
      as="div"
      open={open}
      onClose={setOpen}
      className="relative z-50 max-h-screen"
    >
      <Dialog.Backdrop className="fixed z-40 inset-0 bg-secondary-500 bg-opacity-70 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in" />
      <div className="fixed inset-0 w-screen overflow-y-auto">
        <div className="font-body flex min-h-full justify-center text-center items-center ">
          <Dialog.Panel className="min-w-[350px] relative transform overflow-clip rounded bg-primary p-2 sm:p-4 text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 w-fit  max-h-[calc(100vh-64px)]">
            <Dialog.Title as="div" className="flex w-full justify-between">
              <h2 className="leading-loose text-lg">{title}</h2>
              <button onClick={() => setOpen(false)}>
                <Close className="text-secondary-500" />
              </button>
            </Dialog.Title>
            {typeof subtitle === "string" ? (
              <h3 className="font-light text-sm w-4/5 pb-1">{subtitle}</h3>
            ) : (
              <div className="font-light text-sm w-4/5 pb-1">{subtitle}</div>
            )}
            <div className="border-t sm:pt-4 max-h-[calc(100vh-200px)] overflow-y-auto scrollbar-thin">
              {content}
            </div>
          </Dialog.Panel>
        </div>
      </div>
    </Dialog>
  );
};

export default Modal;
