import React, { useEffect, useState } from "react";
import { set, useFieldArray, useFormContext, useWatch } from "react-hook-form";
import Input from "../updatedcomponents/general-ui/Input";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import Button from "../updatedcomponents/general/Button";
import { OnboardingFormValues } from "./OnboardingSchema";

const KioskInformationStep: React.FC = () => {
  const {
    register,
    control,
    formState: { errors },
  } = useFormContext<OnboardingFormValues>();
  const { fields, append, remove } = useFieldArray({
    control,
    name: "kiosks", // Name of the dynamic field array in the form
  });

  const [showIndex, setShowIndex] = useState<number | null>(0);

  const toggleCollapse = (index: number) => {
    console.log(index);
    setShowIndex((prev) => (prev === index ? null : index));
  };

  const kioskFields = useWatch({
    control,
    name: `kiosks`,
  });

  return (
    <div className="animate-fade-in-right">
      {errors.kiosks && (
        <p className="text-reds-500">{errors.kiosks.message}</p>
      )}

      {fields.map((field, index) => (
        <div
          key={field.id}
          className={`border ${
            errors.kiosks?.[index] ? "border-reds-500" : "border-secondary-1000"
          } p-4 mb-4 rounded-md shadow-sm`}
        >
          <h3 className="text-md flex justify-between items-center">
            {showIndex !== index ? (
              <div>
                {kioskFields[index]?.location
                  ? `${kioskFields[index]?.location}`
                  : "New Kiosk"}
              </div>
            ) : (
              <div></div>
            )}
            <button
              type="button"
              onClick={() => toggleCollapse(index)}
              className="text-secondary-500"
            >
              {showIndex !== index ? <ExpandMore /> : <ExpandLess />}
            </button>
          </h3>
          {showIndex === index && (
            <>
              <Input
                type="text"
                label="Kiosk Location"
                register={register}
                name={`kiosks.${index}.location`}
                placeholder="Enter Location"
                errors={errors.kiosks?.[index]?.location}
              />
              <div className="mt-2">
                <Button
                  type="button"
                  onClick={() => remove(index)}
                  styleString="danger"
                  children="Remove"
                  icon="Remove"
                />
              </div>
            </>
          )}
        </div>
      ))}

      <button
        type="button"
        onClick={() => {
          append({
            location: "",
          });
          setShowIndex(fields.length);
        }}
        className="text-accent-500 mt-4"
      >
        Add Kiosk
      </button>
    </div>
  );
};

export default KioskInformationStep;
