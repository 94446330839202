import React, { useState, useEffect, useContext } from "react";
import { Controller, useForm } from "react-hook-form";
import { Add, LabelOutlined, Notes, Remove } from "@mui/icons-material";
import StatusBadge from "../general-ui/StatusBadges";
import {
  capitalizeString,
  decodedString,
  extractFilenameFromUrl,
  formatDate,
} from "../../utils/FormatFunctions";
import Button from "../general/Button";
import { TaskInstance } from "../../types/TaskInstance";
import Input, { InputType } from "../general-ui/Input";
import { useRecurring } from "../../hooks/useRecurring";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

interface Input {
  dataType: InputType;
  name: string;
  validator: {
    required: boolean;
    requireNote: boolean;
    lowerLimit?: string;
    upperLimit?: string;
  };
  isDeleted?: boolean;
  _id: string;
}

interface TaskInstanceFormProps {
  currentIndex: number;
  goToSection: (index: number) => void;
  goToNextSection: () => void;
  goToPreviousSection: () => void;
  isFirstSection: boolean;
  isLastSection: boolean;
}

interface FormData {
  Notes?: string;
  [key: string]: any;
  currentSection: number;
}

const TaskInstanceForm: React.FC<TaskInstanceFormProps> = ({
  currentIndex,
  goToSection,
  goToNextSection,
  goToPreviousSection,
  isFirstSection,
  isLastSection,
}) => {
  const {
    handleViewFile,
    files,
    setFiles,
    onSubmit,
    canCloseOut,
    handleCloseOut,
    instanceToEdit: logData,
  } = useRecurring();

  if (!logData) {
    return <div>Loading...</div>;
  }

  const initializeData = (): Record<string, any> => {
    const taskInputs = logData.parentTask.inputs;

    const existingData: Record<string, any> =
      logData.customInput?.[currentIndex] || {};

    const emptyData: Record<InputType, any> = {
      text: "",
      number: "",
      radio: null,
      "radio-na": null,
      file: null,
      date: null,
      email: "",
      password: "",
      textarea: "",
      select: null,
      checkbox: false,
      switch: false,
    };

    const mapKeys = logData.parentTask.map.fields[currentIndex];
    const formInputs = taskInputs.reduce(
      (acc: Record<string, any>, field: any) => {
        acc[field._id] =
          existingData[field._id] ?? emptyData[field.dataType as InputType];
        acc["Notes"] = existingData?.["Notes"] ?? undefined;
        return acc;
      },
      {}
    );

    Object.assign(formInputs, mapKeys);
    formInputs["currentSection"] = currentIndex;

    return formInputs;
  };

  const schema = yup.object().shape({
    Notes: yup.string(),
    ...logData.parentTask.inputs.reduce(
      (acc: Record<string, any>, field: any) => {
        if (field.dataType === "file") {
          acc[field._id] = field.validator.required
            ? yup
                .array()
                .of(yup.mixed())
                .nullable()
                .required("This field is required.")
                .min(1, "At least one file is required.")
            : yup.array().of(yup.mixed());
        } else {
          acc[field._id] = field.validator.required
            ? yup.string().nullable().required("This field is required.")
            : yup.string().nullable();
        }
        return acc;
      },
      {}
    ),
    currentSection: yup.number().required("Section index is required."),
  });

  const {
    register,
    handleSubmit,
    formState: { isDirty, errors, isSubmitSuccessful },
    reset,
    control,
  } = useForm<FormData>({
    resolver: yupResolver(schema),
    defaultValues: initializeData(),
  });

  useEffect(() => {
    reset(initializeData());
  }, [currentIndex, reset]);

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset(initializeData());
    }
  }, [isSubmitSuccessful, reset]);

  return (
    <form
      noValidate
      onSubmit={handleSubmit(onSubmit)}
      className="px-4 space-y-2"
    >
      <div className="flex flex-col h-full w-screen max-w-xs sm:max-w-lg md:max-w-2xl">
        {/* Render List Items (Maps) */}
        <div className="flex bg-secondary-1200 w-full mx-auto overflow-x-auto p-2 gap-x-2 rounded-sm ">
          {logData.parentTask.map &&
            logData.parentTask.map.fields.map((field, fieldIndex) => (
              <span
                key={`${field._id}-${fieldIndex}`}
                className={`text-sm px-2 cursor-pointer py-1.5 items-center inline-flex text-nowrap rounded-sm ${
                  fieldIndex === currentIndex
                    ? "bg-primary shadow"
                    : "bg-transparent"
                } cursor-pointer`}
                onClick={() => {
                  if (!isDirty) {
                    goToSection(fieldIndex);
                  } else {
                    window.confirm(
                      "You have unsaved changes. Are you sure you want to leave this section?"
                    ) && goToSection(fieldIndex);
                  }
                }}
              >
                {String(field[Object.keys(field)[1] as keyof typeof field])}
              </span>
            ))}
        </div>
        {/* Display the status of the form */}
        <div className="flex w-full justify-between my-2">
          <StatusBadge
            status={logData?.sectionStatus?.[currentIndex] ?? "not started"}
          />
          {logData.customInput &&
            logData.customInput[currentIndex] &&
            logData.customInput[currentIndex].timestamp && (
              <div className="text-xs text-secondary-500">
                Last updated:{" "}
                {formatDate(
                  String(logData.customInput[currentIndex].timestamp),
                  "MMM DD, yyyy hh:mm a"
                )}
              </div>
            )}
        </div>

        <div className="flex flex-col bg-secondary-1100 rounded border p-4 gap-y-2 flex-grow overflow-y-auto thin-scrollbar">
          {logData.parentTask.inputs.map((input) => (
            <div
              key={input._id}
              className="flex flex-col bg-primary shadow rounded-sm p-2 gap-y-2"
            >
              <label className="flex items-center gap-x-2">
                <LabelOutlined fontSize="small" />
                {capitalizeString(decodedString(input.name))}
              </label>
              <div className="flex flex-col items-end">
                <Input
                  key={input._id}
                  register={register}
                  control={control}
                  name={input._id}
                  type={input.dataType}
                  options={["Pass", "Fail"]}
                  label=""
                  showLabel={false}
                  errors={errors[input._id]}
                  fileProps={{
                    files,
                    setFiles,
                    handleViewFile,
                  }}
                />
              </div>
            </div>
          ))}
          <div className="flex flex-col bg-primary shadow rounded-sm p-2">
            <label className="flex items-center gap-x-2">
              <LabelOutlined fontSize="small" />
              Notes
            </label>
            <Input
              type="textarea"
              label=""
              showLabel={false}
              register={register}
              name="Notes"
              errors={errors.Notes}
            />
          </div>
        </div>
        {isDirty ? (
          <>
            {/*  <label className="flex items-center gap-2 my-4">
              <input
                type="checkbox"
                className="h-4 w-4 text-accent-500 rounded focus:ring-accent-500"
                required
                {...register("isCertified")}
              />
              <span className="text-sm">
                I hereby certify that the data recorded above is accurate to the
                best of my ability.
              </span>
            </label> */}

            <div className="flex W-full gap-2 justify-between items-center mt-4">
              <Button
                styleString="danger"
                type="button"
                children="Cancel"
                onClick={() => reset(initializeData())}
              />
              <Button
                styleString={isDirty ? "primary" : "secondary"}
                type="submit"
                children="Save Section"
                disabled={!isDirty || Object.keys(errors).length > 0}
              />
            </div>
          </>
        ) : (
          <div className="flex W-full gap-2 justify-between items-center mt-4">
            <Button
              styleString={"secondary"}
              type="button"
              children={!isFirstSection ? "Back" : "Cancel"}
              {...(!isFirstSection && { icon: "ChevronLeftOutlined" })}
              onClick={goToPreviousSection}
            />
            <Button
              styleString={!isLastSection ? "bold" : "primary"}
              type="button"
              children={!isLastSection ? "Next" : "Close Out"}
              {...(!isLastSection && { icon: "ChevronRightOutlined" })}
              onClick={!isLastSection ? goToNextSection : handleCloseOut}
              disabled={isLastSection && !canCloseOut}
              reverse={true}
            />
          </div>
        )}
      </div>
    </form>
  );
};

export default TaskInstanceForm;
