import { useContext } from "react";
import { BuildingContext } from "../context/BuildingContext";

export const useBuilding = () => {
  const context = useContext(BuildingContext);
  if (!context) {
    throw new Error("useBuilding must be used within a BuildingProvider");
  }
  return context;
};
