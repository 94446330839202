import { ArrowDownward, ArrowUpward } from "@mui/icons-material";
import Button from "../general/Button";
import { useAnalytics } from "../../hooks/useAnalytics";
import { useNavigate } from "react-router-dom";
import { classNames } from "../../utils/FormatFunctions";

interface button {
  text: string;
  link: string;
}
interface stat {
  name: string;
  icon: JSX.Element;
  stat: string | number;
  previousStat: string | number;
  change: string;
  changeType: string;
  canView: boolean;
}

const StatsDisplay = ({
  title,
  stats,
  button,
  timeFrameOptions,
}: {
  title: string;
  stats: stat[];
  button: button;
  timeFrameOptions: { timeFrame: string; timeString: string }[];
}) => {
  const { dateParams, handleViewStat } = useAnalytics();
  const navigate = useNavigate();

  return (
    <div className="border border-secondary-1000 pt-2 px-2 sm:px-8 ">
      <div className="flex w-full justify-between py-6 border-b border-secondary-1000">
        <div>
          <h3 className="text-base">{title}</h3>
          <p className="text-xs text-secondary-500 pt-2">
            View stats related to {button.text.toLowerCase()}
          </p>
        </div>
        <Button
          icon="LaunchOutlined"
          styleString="primary"
          reverse
          onClick={() => navigate(button.link)}
          overrideStyles="flex-none"
        >
          View
        </Button>
      </div>
      <div className="my-4 flex flex-col md:flex-row gap-4 w-full justify-between">
        {stats.map((item) => (
          <div
            key={item.name}
            className={`flex w-full gap-2 bg-secondary-1200 p-4 ${item.canView ? 'cursor-pointer hover:shadow' : ''}`}
            onClick={item.canView ? () => handleViewStat(item) : undefined}
          >
            <>{item.icon}</>
            <div className="flex flex-col">
              <h2 className="text-base text-secondary-100">{item.name}</h2>
              <span className="text-secondary-100 font-semibold text-xl">
                {item.stat}
              </span>

              {dateParams.selectedRange !== "custom" &&
                dateParams.selectedRange !== "all" && (
                  <>
                    {item.change !== "invalid" && (
                      <div
                        className={classNames(
                          item.changeType === "increase"
                            ? "text-accent-200"
                            : "text-reds-500",
                          "inline-flex items-baseline rounded-full py-0.5 text-sm font-medium md:mt-2 lg:mt-0"
                        )}
                      >
                        {item.changeType === "increase" ? (
                          <ArrowUpward
                            className="mr-0.5 flex-shrink-0 self-center text-green-500"
                            aria-hidden="true"
                            style={{ fontSize: "1rem" }}
                          />
                        ) : (
                          <ArrowDownward
                            className="mr-0.5 flex-shrink-0 self-center text-reds-500"
                            aria-hidden="true"
                            style={{ fontSize: "1rem" }}
                          />
                        )}

                        <span className="sr-only">
                          {" "}
                          {item.changeType === "increase"
                            ? "Increased"
                            : "Decreased"}{" "}
                          by{" "}
                        </span>
                        {item.change}
                      </div>
                    )}
                    <span className="text-sm text-secondary-100">
                      Compared to {item.previousStat}{" "}
                      {
                        timeFrameOptions.find(
                          (option) =>
                            option.timeFrame === dateParams.selectedRange
                        )?.timeString
                      }
                    </span>
                  </>
                )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default StatsDisplay;
