import React from "react";
import UrgentWorkOrderTable from "../../updatedcomponents/tables/UrgentWorkOrderTable";
import HomeChart from "../../updatedcomponents/data-display/HomeChart";
import PageHeader from "../../updatedcomponents/general-ui/PageHeader";
import DateFilter from "../../updatedcomponents/general-ui/DateFilter";
import Button from "../../updatedcomponents/general/Button";
import { useHome } from "../../hooks/useHome";

const Home: React.FC = () => {
  const {
    dateParams,
    setDateParams,
    handleReset,
  } = useHome();
  return (
    <div className="relative bg-secondary-1100 sm:bg-primary">
      <PageHeader
        title="Dashboard"
        subtitle=""
        buttons={
          <>
            <DateFilter dateParams={dateParams} setDateParams={setDateParams} />
            <Button
              onClick={() => handleReset()}
              styleString="primary"
              children="Reset to Default"
            />
          </>
        }
      />
      <div className="p-4 flex flex-col gap-4">
        <HomeChart />
        <UrgentWorkOrderTable />
      </div>
    </div>
  );
};

export default Home;
