import React, { useState, useEffect, useContext } from "react";
import TeamList from "../../updatedcomponents/tables/TeamList";
import { AuthContext } from "../../context/AuthContext";
import axios from "axios";
import PageHeader from "../../updatedcomponents/general-ui/PageHeader";
import NewUserForm from "../../updatedcomponents/forms/NewUserForm";
import { Add, ArrowBack } from "@mui/icons-material";
import Pagination from "../../updatedcomponents/table-components/Pagination";
import useFetchData from "../../hooks/useFetchData";
import { useFacility } from "../../hooks/useFacility";
import { useAuth } from "../../hooks/useAuth";
import Button from "../../updatedcomponents/general/Button";
import { useTeam } from "../../hooks/useTeam";
import Modal from "../../updatedcomponents/general-ui/Modal";

const Team = () => {
  const { currentUser } = useAuth();
  const {
    team,
    handleAddUser,
    pageSize,
    totalPages,
    page,
    handlePageSizeChange,
    handlePageChange,
    modalShowState,
    updateModalShowState,
  } = useTeam();

  const permissions = currentUser.access;

  return (
    <>
      <PageHeader
        title={"Team"}
        subtitle={"All users in the current facility"}
        buttons={
          (permissions === "regional" ||
            permissions === "organization-admin") && (
            <Button
              icon="Add"
              children="Add User"
              onClick={handleAddUser}
              styleString="primary"
            />
          )
        }
      />
      <div className="mt-4 px-4 sm:px-6 sm:overflow-y-auto sm:pb-2 thin-scrollbar">
        <TeamList users={team} />
        <Pagination
          itemsToShow={pageSize}
          handlePageSizeChange={handlePageSizeChange}
          currentPage={page}
          setCurrentPage={handlePageChange}
          totalPages={totalPages}
        />
      </div>
      <Modal
        open={modalShowState.showNewUser}
        setOpen={() => updateModalShowState("showNewUser", false)}
        title="New Work Order"
        subtitle="Get started by filling in the information below to add a new user to your facility."
        content={<NewUserForm />}
      />
    </>
  );
};

export default Team;
