import { FC } from "react";
import {
  BusinessOutlined,
  EmailOutlined,
  Home,
  HomeOutlined,
  Person2Outlined,
  PhoneAndroidOutlined,
} from "@mui/icons-material";
import {
  formatPhoneNumber,
  capitalizeString,
} from "../../utils/FormatFunctions";
import { useVendor } from "../../hooks/useVendor";
import { Vendor } from "../../types/Vendor";
import Table from "../general/Table";
import Button from "../general/Button";
import { useAuth } from "../../hooks/useAuth";

const VendorsTable: FC = () => {
  const { currentUser } = useAuth();
  const { vendors, updateShowModalState, setSelectedVendor } = useVendor();
  const permissions = currentUser.access;

  const columns = [
    {
      label: "Company",
      key: "name",
      sortable: false,
      render: (vendor: Vendor) => (
        <>
          <div className="whitespace-nowrap text-sm">
            <div className="flex ">
              <div className="flex-shrink-0 text-accent-300 bg-accent-1000 h-8 rounded-full w-8 flex items-center justify-center">
                <BusinessOutlined className="" style={{ fontSize: "1.2rem" }} />
              </div>
              <div className="ml-4">
                <div className="font-medium text-secondary">{vendor.name}</div>
                <div className="lg:hidden mt-1 text-secondary-400 gap-2 flex items-center">
                  <Person2Outlined style={{ fontSize: "1rem" }} />
                  {capitalizeString(vendor.contact)}
                </div>
                <div className="mt-1 text-secondary-400 gap-2 flex items-center max-w-40 sm:max-w-52 md:max-w-96 overflow-hidden ">
                  <EmailOutlined style={{ fontSize: "1rem" }} />
                  <span className="truncate">{vendor.email}</span>
                </div>
                <div className="mt-1 text-secondary-400 gap-2 flex items-center">
                  <PhoneAndroidOutlined style={{ fontSize: "1rem" }} />
                  {formatPhoneNumber(vendor.phone)}
                </div>
                <div className="mt-1 xl:hidden text-secondary-400 gap-2 flex items-center max-w-40 sm:max-w-52 md:max-w-96 overflow-hidden ">
                  <HomeOutlined style={{ fontSize: "1rem" }} />
                  <span className="truncate">{vendor.address}</span>
                </div>
              </div>
            </div>
          </div>
        </>
      ),
      className: "w-full max-w-0 py-4 pr-3 sm:w-auto sm:max-w-none ",
    },
    {
      label: "Type",
      key: "type",
      sortable: false,
      render: (vendor: Vendor) => (
        <>
          <span className="whitespace-nowrap px-3 py-5 text-sm text-secondary-400">
            {capitalizeString(vendor.type)}
          </span>
        </>
      ),
      className: "hidden sm:table-cell",
    },
    {
      label: "Address",
      key: "address",
      sortable: false,
      render: (vendor: Vendor) => (
        <>
          <span className="whitespace-nowrap px-3 py-5 text-sm text-secondary-400">
            {capitalizeString(vendor.address)}
          </span>
        </>
      ),
      className: "hidden xl:table-cell",
    },
    {
      label: "Contact Person",
      key: "contact",
      sortable: false,
      render: (vendor: Vendor) => (
        <>
          <span className="whitespace-nowrap px-3 py-5 text-sm text-secondary-400">
            {vendor.contact}
          </span>
        </>
      ),
      className: "hidden lg:table-cell",
    },
    {
      label: "",
      key: "delete",
      sortable: false,
      render: (vendor: Vendor) =>
        ["director", "organization-admin", "regional"].includes(permissions) && (
          <Button
            onClick={() => {
              setSelectedVendor(vendor);
              updateShowModalState("showDelete", true);
            }}
            icon="Delete"
            styleString="danger"
            children={null}
            overrideStyles="w-6"
          />
        ),
      className: "sm:table-cell",
    },
  ];
  return (
    <Table
      columns={columns}
      data={vendors}
      isClickable={false}
      height="max-h-[calc(100vh-280px)] sm:max-h-[calc(100vh-250px)]"
    />
  );
};

export default VendorsTable;
