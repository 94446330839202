import React, { Fragment, useEffect, useState } from "react";
import { useFieldArray, useFormContext, useWatch } from "react-hook-form";
import Input from "../updatedcomponents/general-ui/Input";
import Button from "../updatedcomponents/general/Button";
import { OnboardingFormValues } from "./OnboardingSchema";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { Bars4Icon } from "@heroicons/react/20/solid";
import { stripTrailingCharacter } from "../utils/FormatFunctions";

const MapInformationStep: React.FC<{ handleSave: () => void }> = ({
  handleSave,
}) => {
  const {
    control,
    register,
    setValue,
    trigger,
    formState: { errors },
  } = useFormContext<OnboardingFormValues>();
  const { fields: mapFields } = useFieldArray({ control, name: "maps" });
  const [showIndex, setShowIndex] = useState<number | null>(null);

  const toggleCollapse = (index: number) => {
    setShowIndex((prev) => (prev === index ? null : index));
  };

  const handleSkip = (index: number, require: boolean) => {
    setValue(`maps.${index}.skipped`, require);
    handleSave();
  };

  useEffect(() => {
    console.log("Errors", errors);
  } , [errors]);

  return (
    <div className="animate-fade-in-right">
      {mapFields.map((mapField, mapIndex) => {
        const nestedFields = useWatch({
          control,
          name: `maps.${mapIndex}.fields`,
        });
        const skipped = useWatch({
          control,
          name: `maps.${mapIndex}.skipped`,
        });

        return (
          <div
            key={mapField.id}
            className={`flex flex-col border ${
              errors.maps?.[mapIndex]
                ? "border-reds-800"
                : "border-secondary-1000"
            } p-4 mb-4 rounded-sm shadow-sm`}
          >
            <h3
              className="text-md flex justify-between items-center cursor-pointer"
              onClick={() => toggleCollapse(mapIndex)}
            >
              <div>
                {mapField.name}{" "}
                {skipped ? (
                  <span className="text-secondary-300 bg-secondary-1100 rounded-full px-1.5 py-0.5 text-xs">
                    Skipped
                  </span>
                ) : (
                  <span className="text-secondary-500 text-xs">
                    ({nestedFields.length})
                  </span>
                )}
              </div>
              <button type="button" className="text-secondary-500">
                {showIndex !== mapIndex ? <ExpandMore /> : <ExpandLess />}
              </button>
            </h3>
            {showIndex === mapIndex && (
              <>
                <span className="my-2 font-light text-sm w-3/4">
                  {mapField.description}
                </span>
                {errors.maps?.[mapIndex]?.fields && (
                  <span className="text-reds-500 text-sm">
                    {errors.maps?.[mapIndex]?.fields?.message}
                  </span>
                )}
                <NestedFields
                  mapField={mapField}
                  control={control}
                  register={register}
                  nestIndex={mapIndex}
                  skipped={skipped}
                  onSkip={handleSkip}
                  errors={errors.maps?.[mapIndex]}
                />
              </>
            )}
          </div>
        );
      })}
    </div>
  );
};

const NestedFields: React.FC<{
  mapField: {
    name: string;
    description?: string;
    skipped: boolean;
    id: string;
  };
  control: any;
  register: any;
  nestIndex: number;
  errors?: any;
  skipped: boolean;
  onSkip: (index: number, require: boolean) => void;
}> = ({ mapField, control, register, nestIndex, skipped, onSkip, errors }) => {
  const {
    fields: nestedFields,
    append: appendField,
    remove: removeField,
    move: moveField,
  } = useFieldArray({
    control,
    name: `maps.${nestIndex}.fields`,
  });

  const handleSort = (newList: any[]) => {
    if (!newList || !Array.isArray(newList)) {
      console.error("Invalid newList:", newList);
      return;
    }

    // Filter out null or undefined items from newList
    const validList = newList.filter((item) => item && item.id);

    if (validList.length !== newList.length) {
      console.warn(
        "Filtered invalid items from newList:",
        newList.filter((item) => !item || !item.id)
      );
    }

    validList.forEach((item, newIndex) => {
      const currentIndex = nestedFields.findIndex(
        (field) => field.id === item.id
      );

      if (currentIndex === -1) {
        console.error(`Item with id ${item.id} not found in nestedFields.`);
        return;
      }

      if (currentIndex !== newIndex) {
        moveField(currentIndex, newIndex);
      }
    });
  };

  return (
    <>
      <div>
        {nestedFields.map((nestedField, nestedIndex) => (
          <Fragment key={nestedField.id}>
            <div
              className={`border ${
                errors?.fields?.[nestedIndex]
                  ? "border-reds-500 text-reds-400"
                  : "border-secondary-1000"
              } flex w-full items-center border rounded-sm my-1 `}
            >
           {/*    <div className="drag-handle px-2 cursor-move text-inherit">
                <Bars4Icon className="w-5 h-5" />
              </div> */}
              <input
                className="w-full border-none focus:ring-0 placeholder:text-inherit"
                {...register(
                  `maps.${nestIndex}.fields.${nestedIndex}.identifier`
                )}
                type="text"
                placeholder="Item Identifier"
              />
              <Button
                type="button"
                onClick={() => removeField(nestedIndex)}
                styleString="danger"
                icon="CancelOutlined"
              />
            </div>
            <span className="text-reds-500 text-sm">
              {errors?.fields?.[nestedIndex]?.identifier?.message}
            </span>
          </Fragment>
        ))}
      </div>
      <div className="flex flex-col gap-2 mt-2">
        <Button
          type="button"
          onClick={() => appendField({ identifier: "" })}
          styleString="primary"
          children={`Add ${stripTrailingCharacter(mapField.name, "s")}`}
          disabled={skipped}
          icon="Add"
        />
        {skipped ? (
          <Button
            type="button"
            styleString="secondary"
            children={`Require ${mapField.name}`}
            icon="Verified"
            onClick={() => onSkip(nestIndex, false)}
          />
        ) : (
          <Button
            type="button"
            styleString="secondary"
            children={`Skip ${mapField.name}`}
            icon="Redo"
            onClick={() => onSkip(nestIndex, true)}
          />
        )}
      </div>
    </>
  );
};

export default MapInformationStep;
