import moment from "moment-timezone";
import PageHeader from "../../updatedcomponents/general-ui/PageHeader";
import DateFilter from "../../updatedcomponents/general-ui/DateFilter";
import Button from "../../updatedcomponents/general/Button";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import { useAnalytics } from "../../hooks/useAnalytics";
import StatsDisplay from "../../updatedcomponents/data-display/StatsDisplay";
import AnalyticsChart from "../../updatedcomponents/data-display/AnalyticsChart";
import Modal from "../../updatedcomponents/general-ui/Modal";
import MiniWorkOrdersTable from "../../updatedcomponents/tables/MiniWorkOrdersTable";
import Input from "../../updatedcomponents/general-ui/Input";

interface TimeFrameOption {
  value: number;
  unit: moment.unitOfTime.DurationConstructor;
  label: string;
  timeString: string;
  timeFrame: string;
}
const Analytics = () => {
  const {
    workOrderStats,
    recurringStats,
    chartData,
    showState,
    updateShowState,
    dateParams,
    setDateParams,
    currentFilter,
    setCurrentFilter,
    selectedStat,
  } = useAnalytics();

  const timeFrameOptions: TimeFrameOption[] = [
    {
      value: 0,
      unit: "days",
      label: "Today",
      timeString: " yesterday",
      timeFrame: "day",
    },
    {
      value: 1,
      unit: "days",
      label: "Yesterday",
      timeString: " two days ago",
      timeFrame: "2day",
    },
    {
      value: 3,
      unit: "days",
      label: "3 Day",
      timeString: " three days ago",
      timeFrame: "3day",
    },
    {
      value: 1,
      unit: "weeks",
      label: "Week",
      timeString: " last week",
      timeFrame: "week",
    },
    {
      value: 1,
      unit: "months",
      label: "1 Month",
      timeString: " a month ago",
      timeFrame: "month",
    },
    {
      value: 3,
      unit: "months",
      label: "3 Months",
      timeString: " three months ago",
      timeFrame: "3month",
    },
    {
      value: 6,
      unit: "months",
      label: "6 Months",
      timeString: " six months ago",
      timeFrame: "6month",
    },
    {
      value: 1,
      unit: "years",
      label: "1 Year",
      timeString: " a year ago",
      timeFrame: "year",
    },
    {
      value: 1,
      unit: "years",
      label: "All Time",
      timeString: " ",
      timeFrame: "all",
    },
  ];

  return (
    <>
      <PageHeader
        title={"Analytics"}
        subtitle={""}
        buttons={
          <div className="hidden sm:flex w-full h-fit justify-evenly mx-4 gap-4">
            {/* <DateFilter dateParams={dateParams} setDateParams={setDateParams} /> */}

            <div className="flex flex-col">
              <Button
                styleString="secondary"
                icon="DatasetOutlined"
                children={
                  <div className="flex items-center gap-2">
                    <span>Dataset</span>
                    {
                      <ChevronDownIcon
                        className={`h-5 w-5 ui-open:transition  ${
                          currentFilter === "dataSets" ? "" : "rotate-180"
                        }`}
                        aria-hidden="true"
                      />
                    }
                  </div>
                }
                onClick={() => {
                  currentFilter !== "dataSets"
                    ? setCurrentFilter("dataSets")
                    : setCurrentFilter(null);
                }}
              />
              {currentFilter === "dataSets" && (
                <div className="shadow-md gap-y-2 flex flex-col absolute w-52 z-30 bg-primary top-14 px-4 pb-4">
                  <div className="flex justify-between ">
                    <label className="text-sm">Work Orders</label>
                    <input
                      type="checkbox"
                      checked={showState.showWorkOrders}
                      onChange={() =>
                        updateShowState(
                          "showWorkOrders",
                          !showState.showWorkOrders
                        )
                      }
                      className="text-accent-500 focus:ring-accent-500 rounded-sm cursor-pointer"
                    />
                  </div>
                  <div className="flex justify-between">
                    <label className="text-sm">Recurring Tasks</label>
                    <input
                      type="checkbox"
                      checked={showState.showRecurring}
                      onChange={() =>
                        updateShowState(
                          "showRecurring",
                          !showState.showRecurring
                        )
                      }
                      className="text-accent-500 focus:ring-accent-500 rounded-sm cursor-pointer"
                    />
                  </div>
                  <button
                    onClick={() => {
                      updateShowState("showWorkOrders", true);
                      updateShowState("showRecurring", true);
                      setCurrentFilter(null);
                    }}
                    className="mt-2 flex-1 flex items-center justify-center rounded-sm bg-secondary-100 px-2.5 py-0.5 h-fit text-center text-sm text-primary border-transparent border hover:bg-secondary-200"
                  >
                    Select All
                  </button>
                </div>
              )}
            </div>
            <div className="flex flex-col">
              <Button
                styleString="secondary"
                icon="PendingActionsOutlined"
                children={
                  <div className="flex items-center gap-2">
                    <span>Status</span>
                    {
                      <ChevronDownIcon
                        className={`h-5 w-5 ui-open:transition  ${
                          currentFilter === "status" ? "" : "rotate-180"
                        }`}
                        aria-hidden="true"
                      />
                    }
                  </div>
                }
                onClick={() => {
                  currentFilter !== "status"
                    ? setCurrentFilter("status")
                    : setCurrentFilter(null);
                }}
              />

              {currentFilter === "status" && (
                <div className="shadow-md  absolute z-30 bg-primary w-52 top-14 px-4 pb-4">
                  <ul className="flex flex-col gap-2 pt-2">
                    <li className="flex justify-between w-full ">
                      <label className="mr-2 text-sm">Created</label>
                      <input
                        type="checkbox"
                        checked={showState.showCreated}
                        onChange={() =>
                          updateShowState("showCreated", !showState.showCreated)
                        }
                        className="text-accent-500 focus:ring-accent-500 rounded-sm cursor-pointer"
                      />
                    </li>
                    <li className="flex justify-between w-full ">
                      <label className="mr-2 text-sm">Completed</label>
                      <input
                        type="checkbox"
                        checked={showState.showCompleted}
                        onChange={() =>
                          updateShowState(
                            "showCompleted",
                            !showState.showCompleted
                          )
                        }
                        className="text-accent-500 focus:ring-accent-500 rounded-sm cursor-pointer"
                      />
                    </li>
                    <li className="flex justify-between w-full ">
                      <label className="mr-2 text-sm">Closed</label>
                      <input
                        type="checkbox"
                        checked={showState.showClosed}
                        onChange={() =>
                          updateShowState("showClosed", !showState.showClosed)
                        }
                        className="text-accent-500 focus:ring-accent-500 rounded-sm cursor-pointer"
                      />
                    </li>
                  </ul>
                  <Button
                    onClick={() => {
                      updateShowState("showCreated", true);
                      updateShowState("showCompleted", true);
                      updateShowState("showClosed", true);
                      setCurrentFilter(null);
                    }}
                    styleString="secondary"
                    overrideStyles="w-full"
                    children="Select All"
                  />
                </div>
              )}
            </div>
            <Button
              icon="Refresh"
              onClick={() => {
                setCurrentFilter(null);
                setDateParams((prevParams) => ({
                  ...prevParams,
                  displayText: moment().format("MMMM YYYY"),
                  startDate: moment().subtract(1, "week").toDate(),
                  selectedRange: "week",
                }));
                updateShowState("showWorkOrders", true);
                updateShowState("showRecurring", true);
                updateShowState("showCreated", true);
                updateShowState("showCompleted", true);
                updateShowState("showClosed", true);
              }}
              styleString="primary"
            >
              Reset
            </Button>
          </div>
        }
      />
      {/* Page Content */}
      <div className="py-8 px-2 sm:px-8 flex flex-col gap-y-8">
        <select
            className="md:hidden block w-full sm:w-fit rounded border-0 bg-transparent py-1.5 shadow-sm ring-1 ring-inset focus:ring-2 focus:ring-inset focus:ring-accent-500 sm:text-sm sm:leading-6 ring-secondary-100"
          onChange={(e) => {
            const selectedTimeFrame = e.target.value;
            const option = timeFrameOptions.find(
              (option) => option.timeFrame === selectedTimeFrame
            );
            if (option) {
              setDateParams((prevParams) => ({
                ...prevParams,
                displayText: moment().format("MMMM YYYY"),
                startDate: moment()
                  .subtract(option.value, option.unit)
                  .toDate(),
                selectedRange: option.timeFrame,
              }));
            }
          }}
        >
          {timeFrameOptions.map((option) => (
            <option key={option.timeFrame} value={option.timeFrame}>
              {option.label}
            </option>
          ))}
        </select>

        <div className="hidden md:flex flex-col sm:flex-row justify-start bg-secondary-1100 w-full sm:w-fit p-1 rounded-sm">
          {timeFrameOptions.map((option, index) => (
            <button
              key={option.timeFrame}
              type="button"
              className={`relative inline-flex items-center rounded-sm sm:rounded-none ${
                index === 0 ? "sm:rounded-l-sm" : "-ml-px"
              } ${
                index === timeFrameOptions.length - 1 ? "sm:rounded-r-sm" : ""
              } ${
                dateParams.selectedRange === option.timeFrame
                  ? "bg-primary shadow  text-secondary-100 border-b border-accent-500"
                  : "bg-inherit hover:bg-gray-50 text-gray-900"
              } px-3 py-0.5 text-sm   focus:z-10  transition-all ease-in-out duration-200`}
              onClick={() => {
                setDateParams((prevParams) => ({
                  ...prevParams,
                  displayText: moment().format("MMMM YYYY"),
                  startDate: moment()
                    .subtract(option.value, option.unit)
                    .toDate(),
                  selectedRange: option.timeFrame,
                }));
              }}
            >
              {option.label}
            </button>
          ))}
        </div>
        <div className="flex flex-col gap-y-6">
          <StatsDisplay
            title="Work Order Data"
            stats={workOrderStats}
            button={{ text: "Work orders", link: "/work-orders" }}
            timeFrameOptions={timeFrameOptions}
          />
          <StatsDisplay
            title="Recurring Task Data"
            stats={recurringStats}
            button={{ text: "Recurring tasks", link: "/tasks" }}
            timeFrameOptions={timeFrameOptions}
          />
        </div>

        {/* Chart */}
        <div className="h-[350px] border border-secondary-1000">
          <div className="flex w-full py-2 px-8">
            <ul className="flex w-full justify-end gap-x-4">
              <li className="flex items-center gap-x-2">
                <div className="w-2 h-2 rounded-full bg-accent-300"></div>
                <div className="text-sm">Created</div>
              </li>
              <li className="flex items-center gap-x-2">
                <div className="w-2 h-2 rounded-full bg-secondary-100"></div>
                <div className="text-sm">Completed</div>
              </li>
              <li className="flex items-center gap-x-2">
                <div className="w-2 h-2 rounded-full bg-accent-500"></div>
                <div className="text-sm">Closed</div>
              </li>
            </ul>
          </div>
          <AnalyticsChart data={chartData} />
        </div>
      </div>
      <Modal
        open={showState.showStatModal}
        setOpen={() => updateShowState("showStatModal", false)}
        title={`${selectedStat?.name}`}
        subtitle={`Showing ${
          selectedStat?.currentContent.length
        } ${selectedStat?.name.toLowerCase()} from ${dateParams.displayText}`}
        content={
          <MiniWorkOrdersTable workOrders={selectedStat?.currentContent} />
        }
      />
    </>
  );
};

export default Analytics;
