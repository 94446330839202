import React, {
  createContext,
  useContext,
  useState,
  ReactNode,
  useEffect,
  useCallback,
} from "react";
import {
  createOnboardingForm,
  fetchOnboardingForm,
  fetchOnboardingForms,
  getFacilityMaps,
} from "../api/services/AdminService";
import { useNavigate, useParams } from "react-router-dom";
import { Map } from "../types/Map";

interface ManageOnboardingContextValue {
  forms: any[];
  selectedForm: any | null;
  setSelectedForm: (form: any) => void;
  handleNewForm: () => void;
  handleViewForm: (id: string) => void;
  handleNavigateBack: () => void;
  facilityMaps: Map[];
}

export const ManageOnboardingContext = createContext<
  ManageOnboardingContextValue | undefined
>(undefined);

export const ManageOnboardingProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [formDataArray, setFormDataArray] = useState<any[]>([]);
  const { id } = useParams();

  const fetchForms = useCallback(async () => {
    // Fetch forms from API
    const forms = await fetchOnboardingForms();
    setFormDataArray(forms);
  }, []);

  const [selectedForm, setSelectedForm] = useState<any | null>(null);
  const fetchFormById = useCallback(
    async (id: string) => {
      const currentForm = await fetchOnboardingForm(id);
      setSelectedForm(currentForm);
    },
    [id]
  );

  useEffect(() => {
    fetchForms();
  }, []);

  useEffect(() => {
    if (id) {
      fetchFormById(id);
    }
  }, [id]);

  const createNewForm = async () => {
    try {
      await createOnboardingForm();
      fetchForms();
    } catch (error) {
      console.error("Error creating new form: ", error);
    }
  };

  const navigate = useNavigate();
  const handleNavigateBack = () => {
    navigate("/support/onboarding");
  };

  const handleViewForm = (id: string) => {
    navigate(`/support/onboarding/${id}`);
  };

  const [facilityMaps, setFacilityMaps] = useState<Map[]>([]);

  useEffect(() => {
    const fetchFacilityMaps = async () => {
      try {
        const { maps } = await getFacilityMaps(
          selectedForm?.facility.migratedFacilityId
        );
        setFacilityMaps(maps);
      } catch (error) {
        console.error("Error fetching facility maps:", error);
      }
    };

    if (selectedForm && selectedForm?.facility.migratedFacilityId) {
      fetchFacilityMaps();
    }
  }, [selectedForm?.facility.migratedFacilityId, selectedForm?.maps]);

  return (
    <ManageOnboardingContext.Provider
      value={{
        forms: formDataArray,
        selectedForm,
        setSelectedForm,
        handleNewForm: createNewForm,
        handleViewForm,
        handleNavigateBack,
        facilityMaps,
      }}
    >
      {children}
    </ManageOnboardingContext.Provider>
  );
};
