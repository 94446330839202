import StatusBadge from "../general-ui/StatusBadges";
import { calculateDays, capitalizeString } from "../../utils/FormatFunctions";
import Table from "../general/Table";
import { WorkOrder } from "../../types/WorkOrder";
import { useNavigate } from "react-router-dom";
import {
  AttachmentOutlined,
  CalendarMonthOutlined,
  Person,
} from "@mui/icons-material";

interface WorkOrderTableProps {
  workOrders: WorkOrder[];
}

const MiniWorkOrdersTable = ({ workOrders }: WorkOrderTableProps) => {
  const navigate = useNavigate();
  const columns = [
    {
      label: "Request Title",
      key: "title",
      sortable: true,
      render: (workOrder: WorkOrder) => (
        <>
          <span className="sm:max-w-96 max-w-56 md:min-w-96 flex overflow-auto text-sm">
            {capitalizeString(workOrder.title)}
          </span>
          {workOrder.attachments && workOrder.attachments.length > 0 && (
            <>
              <dd className="mt-1 truncate text-secondary-100 text-xs  font-light">
                <AttachmentOutlined style={{ fontSize: "1rem" }} />{" "}
              </dd>
            </>
          )}
          <dl>
            <dt className="sr-only hidden">Details</dt>
            <dd className="mt-1 sm:max-w-72 max-w-56  text-secondary-100  text-xs font-light">
              {workOrder.details}
            </dd>

            <dd className="mt-1 flex w-full justify-between items-center text-secondary-100 font-normal text-xs">
              <span className="truncate text-secondary-100 text-xs  font-light">
                <CalendarMonthOutlined style={{ fontSize: "1rem" }} />{" "}
                {calculateDays(workOrder.dateReported)} days ago
              </span>
              <StatusBadge status={workOrder.status} />
            </dd>

            <dd className="mt-1 flex w-full justify-between items-center text-secondary-100 font-normal text-xs">
              <span className="text-secondary-100 font-normal text-xs">
                ID {workOrder?.shortId}
              </span>
              <span className="">
                <StatusBadge status={workOrder.urgency} />
              </span>
            </dd>
          </dl>
        </>
      ),
      className: "w-full max-w-0 py-4 pr-3 sm:w-auto sm:max-w-none ",
    },
    /*  {
      label: "Date Reported",
      key: "dateReported",
      sortable: true,
      render: (workOrder: WorkOrder) => (
        <>
          <span className=" text-secondary">
            {calculateDays(workOrder.dateReported)} days ago
          </span>
        </>
      ),
      className: "hidden sm:table-cell",
    },
    {
      label: "Status",
      key: "status",
      sortable: true,
      render: (workOrder: WorkOrder) => (
        <>
          <StatusBadge status={workOrder.status} />
        </>
      ),
      className: "hidden sm:table-cell",
    },
    {
      label: "Urgency",
      key: "urgency",
      render: (workOrder: WorkOrder) => (
        <>
          <StatusBadge status={workOrder.urgency} />
        </>
      ),
      className: "hidden md:table-cell",
    }, */
  ];

  return (
    <Table
      data={workOrders}
      onRowClick={(workOrder) =>
        navigate(`/work-orders/instance/${workOrder._id}`)
      }
      columns={columns}
      noDataMessage={"No work orders found"}
      showHeader={false}
    />
  );
};

export default MiniWorkOrdersTable;
