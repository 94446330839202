import { createContext, ReactNode, useState } from "react";
import { useAuth } from "../hooks/useAuth";
import { SubmitHandler } from "react-hook-form";
import { UpdateUserFormData } from "../pages/ApplicationPages/Settings";
import {
  removeImage,
  updateImage,
  updateUser,
} from "../api/services/SettingsService";
import { ImageFormData } from "../updatedcomponents/forms/UploadImage";
import { useToast } from "../hooks/useToast";
import {
  CheckCircleIcon,
  ExclamationCircleIcon,
} from "@heroicons/react/20/solid";

// Define the type for the context values
interface SettingsContextType {
  showState: {
    showPhotoAdd: boolean;
    changePassword: boolean;
    changeEmail: boolean;
    changePhone: boolean;
  };
  updateShowState: (state: string, show: boolean) => void;
  handleFormSubmit: SubmitHandler<UpdateUserFormData>;
  onSubmitAvatar: (data: ImageFormData) => void;
  imageState: {
    selectedImage: string | null;
    isImageRemoved: boolean;
  };
  updateImageState: (key: string, value: any) => void;
}

// Define the type for the provider props
interface SettingsProviderProps {
  children: ReactNode;
}

// Create the context
export const SettingsContext = createContext<SettingsContextType | undefined>(
  undefined
);

// Create the provider component
export const SettingsProvider = ({ children }: SettingsProviderProps) => {
  const { currentUser, dispatch } = useAuth();
  const { showResponse } = useToast();
  const [showState, setShowState] = useState({
    showPhotoAdd: false,
    changePassword: false,
    changeEmail: false,
    changePhone: false,
  });

  const updateShowState = (state: string, show: boolean) => {
    setShowState((prevState) => ({
      ...prevState,
      [state]: show,
    }));
  };

  const handleFormSubmit: SubmitHandler<UpdateUserFormData> = async (
    data,
    event
  ) => {
    event?.preventDefault();

    const updatedUserData = {
      firstname: data.firstname,
      lastname: data.lastname,
      email: data.email,
      phone: data.phone,
      password: data.password,
      newPassword: data.newPassword,
      emailNotifications: data.emailNotifications || {
        taskAssignment: false,
        workOrderCreation: false,
        urgentWorkOrderCreation: false,
      },
      smsNotifications: data.smsNotifications || {
        taskAssignment: false,
        workOrderCreation: false,
        urgentWorkOrderCreation: false,
      },
    };
    try {
      await updateUser(currentUser._id, updatedUserData);

      const updatedUser = { ...currentUser, ...updatedUserData };
      localStorage.setItem("user", JSON.stringify(updatedUser));

      setTimeout(() => {
        dispatch({ type: "UPDATE_USER", payload: updatedUser });
      }, 100);
      showResponse(
        "User Updated",
        "User has been successfully updated",
        <CheckCircleIcon className="h-6 w-6 text-accent-500" />
      );
    } catch (error) {
      showResponse(
        "Error",
        "There was an error updating the user",
        <ExclamationCircleIcon className="h-6 w-6 text-reds-500" />
      );

      console.error("Error updating user:", error);
    }

    // const updateUser = async (dispatch: any, updatedUserData: any) => {

    /*  await axios
        .put(`/api/settings/user/${userId}`, updatedUserData, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          const updatedUser = { ...currentUser, ...updatedUserData };
          localStorage.setItem("user", JSON.stringify(updatedUser));

          setResponse({
            show: true,
            title: "Success",
            body: "User data updated",
            icon: (
              <CheckCircleOutline
                className="text-accent-500"
                style={{ fontSize: "1rem" }}
              />
            ),
          });

          setTimeout(() => {
            dispatch({ type: "UPDATE_USER", payload: updatedUser });
          }, 2000);
        })
        .catch((error) => {
          console.error("Error updating user:", error);
          if (error.response.status === 400) {
            setError("password", { message: "Invalid password" });
          }
          setResponse({
            show: true,
            title: "Error updating user data",
            body: "Invalid password.",
            icon: (
              <ErrorOutline
                className="text-reds-500"
                style={{ fontSize: "1rem" }}
              />
            ),
          });
        }); */

    // await updateUser(dispatch, updatedUserData);
  };

  const [imageState, setImageState] = useState({
    selectedImage: currentUser.avatar ?? null,
    isImageRemoved: false,
  });

  const updateImageState = (key: string, value: any) => {
    setImageState((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  const onSubmitAvatar = async (data: ImageFormData) => {
    if (imageState.isImageRemoved) {
      await removeAvatar();
    } else {
      const formData = new FormData();
      formData.append("avatar", data.photo as Blob);
      const userId = currentUser._id;
      formData.append("userId", userId);

      try {
        const link = await updateImage(userId, formData);
        const updatedUser = { ...currentUser, avatar: link };
        localStorage.setItem("user", JSON.stringify(updatedUser));
        dispatch({ type: "UPDATE_USER", payload: updatedUser });

        updateImageState("selectedImage", null);
        showResponse(
          "Avatar Updated",
          "Your avatar has been successfully updated",
          <CheckCircleIcon className="h-6 w-6 text-accent-500" />
        );
      } catch (error) {
        showResponse(
          "Error",
          "There was an error updating the avatar",
          <ExclamationCircleIcon className="h-6 w-6 text-reds-500" />
        );
        console.error("Error uploading avatar:", error);
      }
    }
  };

  const removeAvatar = async () => {
    try {
      await removeImage();
      updateImageState("selectedImage", null);
      updateImageState("isImageRemoved", true);
      const updatedUser = { ...currentUser, avatar: null };
      localStorage.setItem("user", JSON.stringify(updatedUser));
      dispatch({ type: "UPDATE_USER", payload: updatedUser });
      showResponse(
        "Avatar Removed",
        "Your avatar has been successfully removed",
        <CheckCircleIcon className="h-6 w-6 text-accent-500" />
      );
    } catch (error) {
      showResponse(
        "Error",
        "There was an error removing the avatar",
        <ExclamationCircleIcon className="h-6 w-6 text-reds-500" />
      );
      console.error("Error removing avatar:", error);
    }
  };

  return (
    <SettingsContext.Provider
      value={{
        showState,
        updateShowState,
        handleFormSubmit,
        onSubmitAvatar,
        imageState,
        updateImageState,
      }}
    >
      {children}
    </SettingsContext.Provider>
  );
};
