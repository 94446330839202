import React from "react";
import { capitalizeString, formatDate } from "../../utils/FormatFunctions";
import { LabelOutlined } from "@mui/icons-material";
import Button from "../general/Button";

const RecurringTaskPreview = ({
  taskData,
  onEdit,
}: {
  taskData: any;
  onEdit?: () => void;
}) => {
  return (
    <dl className="bg-secondary-1200 p-4 rounded px-4 my-4 max-w-screen-sm">
      <div className="flex items-center pb-4">
        <h2 className="text-secondary text-lg">Review Recurring Task</h2>
        {onEdit && (
          <div className="w-fit">
            <Button styleString="minimal" onClick={onEdit} icon="Edit" />
          </div>
        )}
      </div>
      <div className=" sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
        <dt className="text-sm font-medium leading-6 text-secondary-100">
          Task Title
        </dt>
        <dd className="mt-1 flex text-sm  text-secondary-400 sm:col-span-2 sm:mt-0">
          <span className="flex-grow">{capitalizeString(taskData.title)}</span>
        </dd>
      </div>
      <div className=" py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
        <dt className="text-sm font-medium  text-secondary-100">Task Type</dt>
        <dd className="mt-1 flex text-sm  text-secondary-400 sm:col-span-2 sm:mt-0">
          <span className="flex-grow">
            {capitalizeString(taskData.taskType)}
          </span>
        </dd>
      </div>
      <div className=" py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
        <dt className="text-sm font-medium  text-secondary-100">
          Task Category
        </dt>
        <dd className="mt-1 flex text-sm  text-secondary-400 sm:col-span-2 sm:mt-0">
          <span className="flex-grow">
            {capitalizeString(taskData.category)}
          </span>
        </dd>
      </div>
      <div className=" py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
        <dt className="text-sm font-medium  text-secondary-100">Inputs</dt>
        <dd className="mt-1 flex text-sm  text-secondary-400 sm:col-span-2 sm:mt-0">
          <span className="flex-grow">
            <ul>
              {taskData.inputs
                .filter((field: { isDeleted: any }) => !field.isDeleted)
                .map(
                  (input: { name: string; dataType: string }, index: any) => (
                    <div key={index} className="flex items-center gap-4 py-1">
                      <LabelOutlined
                        className="text-secondary-100"
                        fontSize="small"
                      />
                      {capitalizeString(decodeURIComponent(input.name))}
                      <span className="bg-secondary-1100 py-1 px-2 w-fit text-xs rounded-full">
                        {capitalizeString(input.dataType)}
                      </span>
                    </div>
                  )
                )}
            </ul>
          </span>
        </dd>
      </div>
      <div className=" py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
        <dt className="text-sm font-medium  text-secondary-100">Start Date</dt>
        <dd className="mt-1 flex text-sm  text-secondary-400 sm:col-span-2 sm:mt-0">
          <span className="flex-grow">
            {formatDate(taskData.startDate, "YYYY-MM-DD", "utc")}
          </span>
        </dd>
      </div>
      <div className=" py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
        <dt className="text-sm font-medium  text-secondary-100">End Date</dt>
        <dd className="mt-1 flex text-sm  text-secondary-400 sm:col-span-2 sm:mt-0">
          <span className="flex-grow">
            {formatDate(taskData.endDate, "YYYY-MM-DD", "utc")}
          </span>
        </dd>
      </div>
      <div className=" py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
        <dt className="text-sm font-medium  text-secondary-100">Frequency</dt>
        <dd className="mt-1 flex text-sm  text-secondary-400 sm:col-span-2 sm:mt-0">
          <span className="flex-grow">
            {capitalizeString(taskData?.recurrence?.frequency)}
          </span>
        </dd>
      </div>
      <div className=" py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
        <dt className="text-sm font-medium leading-6 text-secondary-100">
          Description
        </dt>
        <dd className="mt-1 flex text-sm leading-6 text-secondary-400 sm:col-span-2 sm:mt-0">
          <span className="flex-grow ">
            {capitalizeString(taskData.details)}
          </span>
        </dd>
      </div>
    </dl>
  );
};

export default RecurringTaskPreview;
