import { useEffect } from "react";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

const useGsapAnimations = () => {
  useEffect(() => {
    // Parallax Background Image Animation
    const parallaxBgImg = gsap.utils.toArray(".parallaxBgImg");
    parallaxBgImg.forEach((box) => {
      const anim = gsap.to(box as Element, { y: -100 });
      ScrollTrigger.create({
        trigger: box as Element,
        animation: anim,
        start: "top 0",
        end: "bottom 0",
        toggleActions: "play none none none",
        scrub: 1,
      });
    });

    // Fade In Up Animation
    const fadeInUp = gsap.utils.toArray(".fadeInUp");
    fadeInUp.forEach((box) => {
      const anim = gsap.fromTo(
        box as Element,
        { autoAlpha: 0, y: 50 },
        { duration: 1, autoAlpha: 1, y: 0 }
      );
      ScrollTrigger.create({
        trigger: box as Element,
        animation: anim,
        start: "top 90%",
        toggleActions: "play none none none",
        once: true,
      });
    });

    // Fade In Up Initial Animation
    const fadeInUpInitial = gsap.utils.toArray(".fadeInUpInitial");
    fadeInUpInitial.forEach((box) => {
      const anim = gsap.fromTo(
        box as Element,
        { autoAlpha: 0, y: 50 },
        { duration: 1, autoAlpha: 1, y: 0 }
      );
      ScrollTrigger.create({
        trigger: box as Element,
        animation: anim,
        start: "top 100%",
        toggleActions: "play none none none",
        once: true,
      });
    });

    // Hero Banner Parallax Image Animation
    const heroBannerParallaxImg = gsap.utils.toArray(".heroBannerParallaxImg");
    heroBannerParallaxImg.forEach((box) => {
      const anim = gsap.fromTo(box as Element, { y: 0 }, { y: -50 });
      ScrollTrigger.create({
        trigger: box as Element,
        animation: anim,
        start: "top 100",
        end: "bottom 0",
        toggleActions: "play none none none",
        scrub: 1,
      });
    });

    // Banner Parallax Block Up Animation
    const BannerParallaxBlockUp = gsap.utils.toArray(".BannerParallaxBlockUp");
    BannerParallaxBlockUp.forEach((box) => {
      const anim = gsap.fromTo(box as Element, { y: 0 }, { y: -60 });
      ScrollTrigger.create({
        trigger: gsap.utils.toArray(".hero-banner"),
        animation: anim,
        start: "top 0",
        end: "bottom 0",
        toggleActions: "play none none none",
        scrub: 1,
      });
    });

    // Parallax Block Up Animation
    const parallaxBlockUp = gsap.utils.toArray(".parallaxBlockUp");
    parallaxBlockUp.forEach((box) => {
      const anim = gsap.to(box as Element, { y: -75 });
      ScrollTrigger.create({
        trigger: box as Element,
        animation: anim,
        start: "top 100%",
        end: "bottom 0",
        toggleActions: "play none none none",
        scrub: 1,
      });
    });

    // Mobile HD Section Dropdown Tab Animation
    const mobileHdSectionDropdownTab = gsap.utils.toArray(
      ".hd-section .tab-wrapper"
    );
    mobileHdSectionDropdownTab.forEach((box) => {
      if (window.outerWidth < 768) {
        const navLinks = (box as Element).querySelectorAll(".nav .nav-link");
        navLinks.forEach((link) => {
          link.addEventListener("click", (event) => {
            event.preventDefault();
            const statusText = (link as Element).innerHTML;
            const selectBtn = (box as Element).querySelector(".selectBtn");
            if (selectBtn) {
              selectBtn.innerHTML = statusText;
            }
          });
        });
      }
    });
    // Cleanup function to kill all ScrollTriggers on component unmount
    return () => {
      ScrollTrigger.getAll().forEach((trigger) => trigger.kill());
    };
  }, []);
};

export { useGsapAnimations };
