import * as yup from "yup";

export interface OnboardingFormValues {
  facility: {
    name: string;
    address: string;
  };
  users: {
    firstname: string;
    lastname: string;
    email: string;
    phone: string;
    access: string;
  }[];
  kiosks: {
    location: string;
  }[];
  maps: {
    mapTemplateId: string;
    name: string;
    description?: string;
    skipped: boolean;
    fields: { identifier: string }[];
  }[];
  tasks: {
    title: string;
    details?: string;
    recurrence:{
      frequency: string
    };
    skipped: boolean;
    startDate: string;
    templateId: string;
  }[];
}

// Return empty schema for now
export const welcomeSchema = yup.object({});

export const facilitySchema = yup.object({
  facility: yup.object({
    name: yup.string().required("Facility name is required"),
    address: yup.string().required("Address is required"),
  }),
});

export const usersSchema = yup.object({
  users: yup
    .array()
    .of(
      yup.object({
        firstname: yup.string().required("First name is required"),
        lastname: yup.string().required("Last name is required"),
        email: yup
          .string()
          .email("Invalid email format")
          .required("Email is required"),
        phone: yup.string().required("Phone number is required"),
        access: yup.string().required("Access level is required"),
      })
    )
    .min(1, "At least one user is required"),
});

export const kiosksSchema = yup.object({
  kiosks: yup
    .array()
    .of(
      yup.object({
        location: yup.string().required("Location is required"),
      })
    )
    .min(1, "At least one kiosk is required"),
});

export const mapsSchema = yup.object({
  maps: yup.array().of(
    yup.object({
      name: yup.string().required("Map name is required"),
      description: yup.string().required("Description is required"),
      skipped: yup.boolean().default(false),
      fields: yup
        .array()
        .of(
          yup.object({
            identifier: yup.string().required("Field identifier is required"),
          })
        )
        .when("skipped", (skipped, schema) => {
          const isSkipped = skipped[0];
          return isSkipped
            ? schema.notRequired()
            : schema.min(1, "*At least one item is required");
        }),
    })
  ),
});
export const tasksSchema = yup.object({
  tasks: yup.array().of(
    yup.object({
      templateId: yup.string().required("Task template is required"),
      title: yup.string().required("Task title is required"),
      description: yup.string(),
      skipped: yup.boolean().default(false),
      startDate: yup.string().when("skipped", (skipped, schema) => {
        const isSkipped = skipped[0];
        return isSkipped
          ? schema.notRequired()
          : schema
              .required("Start date is required")
              .matches(
                /^\d{4}-\d{2}-\d{2}$/,
                "Start date must be in the format YYYY-MM-DD"
              );
      }),
    })
  ),
});

export const finalSchema = yup.object({
  ...facilitySchema.fields,
  ...usersSchema.fields,
  ...kiosksSchema.fields,
  ...mapsSchema.fields,
  ...tasksSchema.fields,
});
