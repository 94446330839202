import { Fragment } from "react";
import { useNavigate } from "react-router-dom";
import { capitalizeString, formatDate } from "../../utils/FormatFunctions";
import { WorkOrder } from "../../types/WorkOrder";
import { CalendarMonthOutlined } from "@mui/icons-material";
import StatusBadge from "../general-ui/StatusBadges";
import Table from "../general/Table";
import { useLog } from "../../hooks/useLog";

const WorkOrderLogTable: React.FC = () => {
  const { workOrders } = useLog();
  const navigate = useNavigate();

  const handleView = (workOrderId: string) => {
    // navigate to the work order instance page pass the workOrder in state
    navigate(`/work-orders/instance/${workOrderId}`, {
      state: { workOrderId },
    });
  };

  const columns = [
    {
      label: "Request Title",
      key: "title",
      sortable: true,
      render: (workOrder: WorkOrder) => (
        <>
          <span className="sm:max-w-96 max-w-56 flex overflow-auto text-sm">
            {capitalizeString(workOrder.title)}
          </span>
          <dl>
            <dt className="sr-only hidden">Details</dt>
            <dd className="mt-1 sm:max-w-72 max-w-56  text-secondary-100  text-xs font-light">
              {workOrder.details}
            </dd>
            <dt className="sr-only hidden">Days since reported</dt>
            <dd className="mt-1 truncate text-secondary-100 text-xs sm:hidden font-light">
              Reported: {formatDate(workOrder.dateReported)}
            </dd>
            <dd className="mt-1 truncate text-secondary-100 text-xs sm:hidden font-light">
              Closed: {formatDate(workOrder.dateClosed)}
            </dd>

            <dt className="sr-only hidden">Status and urgency</dt>
            <dd className="mt-1 truncate text-secondary-100  flex sm:flex-col gap-2 flex-row w-full justify-between my-2">
              <span className="md:hidden">
                <StatusBadge status={workOrder.urgency} />
              </span>
              <span className="text-secondary-100 font-normal text-xs">
                ID {workOrder?.shortId}
              </span>
            </dd>
          </dl>
        </>
      ),
      className: "w-full max-w-0 py-4 pr-3 sm:w-auto sm:max-w-none ",
    },
    {
      label: "Date Reported",
      key: "dateReported",
      sortable: true,
      render: (workOrder: WorkOrder) => (
        <>
          <span className=" text-secondary">
            {formatDate(workOrder.dateReported)}
          </span>
        </>
      ),
      className: "hidden sm:table-cell",
    },
    {
      label: "Date Closed",
      key: "dateClosed",
      sortable: true,
      render: (workOrder: WorkOrder) => (
        <>
          <span className=" text-secondary">
            {formatDate(workOrder.dateClosed)}
          </span>
        </>
      ),
      className: "hidden sm:table-cell",
    },
    {
      label: "Location",
      key: "location",
      sortable: true,
      render: (workOrder: WorkOrder) => (
        <>
          <span
            className="hidden sm:inline-flex"
            style={{
              maxWidth: "100px",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {capitalizeString(workOrder.location)}
          </span>
        </>
      ),
      className: "hidden xl:table-cell",
    },
    {
      label: "Urgency",
      key: "urgency",
      render: (workOrder: WorkOrder) => (
        <>
          <StatusBadge status={workOrder.urgency} />
        </>
      ),
      className: "hidden md:table-cell",
    },
  ];

  return (
    <Table
      data={workOrders}
      onRowClick={(workOrder) => handleView(workOrder._id)}
      columns={columns}
      noDataMessage={"No work orders found"}
      height="max-h-[calc(100vh-400px)] sm:max-h-[calc(100vh-335px)]"
    />
  );
};

export default WorkOrderLogTable;
