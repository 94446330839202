import React, { useState } from "react";
import { Swiper as SwiperComponent, SwiperSlide } from "swiper/react";
import { Navigation, Autoplay, Controller, Thumbs } from "swiper/modules";
import type { Swiper } from "swiper";
// import "swiper/swiper-bundle.css";
import { scrollToComponent } from "../utils/helpers";

const AdvantageSection: React.FC = () => {
  const [thumbsSwiper, setThumbsSwiper] = useState<Swiper | null>(null);

  return (
    <div className="advantage-section">
      <div className="container">
        <div className="section-heading fadeInUp">
          <h2>
            Simply In Control:
            <strong>The Sequra Advantage</strong>
          </h2>
          <p>
            Sequra is redefining how facilities manage compliance and operations
          </p>
          <button
            className="btn btn-gradient"
            onClick={() => scrollToComponent()}
          >
            Ready For An Upgrade?
          </button>
        </div>
        <div className="inner-container">
          <div className="outer-js-advantage-image-slider fadeInUp">
            <SwiperComponent
              modules={[Navigation, Autoplay, Controller, Thumbs]}
              onSwiper={setThumbsSwiper}
              slidesPerView={1}
              effect="slide"
              speed={750}
              slideToClickedSlide={true}
              spaceBetween={10}
              autoplay={{
                delay: 8000,
                disableOnInteraction: true,
              }}
              thumbs={{ swiper: thumbsSwiper }}
              navigation={{
                nextEl: ".outer-js-advantage-image-slider .swiper-button-next",
                prevEl: ".outer-js-advantage-image-slider .swiper-button-prev",
              }}
              className="js-advantage-image-slider"
            >
              <SwiperSlide>
                <img
                  className="w-100"
                  src="/assets/images/tablet-1.png"
                  alt=""
                />
              </SwiperSlide>
              <SwiperSlide>
                <img
                  className="w-100"
                  src="/assets/images/tablet-3.png"
                  alt=""
                />
              </SwiperSlide>
              <SwiperSlide>
                <img
                  className="w-100"
                  src="/assets/images/tablet-2.png"
                  alt=""
                />
              </SwiperSlide>
            </SwiperComponent>
            <div className="swiper-button-prev"></div>
            <div className="swiper-button-next"></div>
          </div>

          <div className="outer-js-advantage-thumb-slider fadeInUp">
            <SwiperComponent
              modules={[Controller, Autoplay, Thumbs]}
              onSwiper={setThumbsSwiper}
              slidesPerView="auto"
              effect="slide"
              speed={750}
              spaceBetween={40}
              centerInsufficientSlides={true}
              slideToClickedSlide={true}
              autoplay={{
                delay: 8000,
                disableOnInteraction: true,
              }}
              breakpoints={{
                768: {
                  spaceBetween: 60,
                },
              }}
              className="swiper js-advantage-thumb-slider"
            >
              <SwiperSlide>
                <div className={"slide-item-count"}>01</div>
                <p>
                  A central user-friendly hub for facility maintenance and
                  safety tracking
                </p>
              </SwiperSlide>
              <SwiperSlide>
                <div className="slide-item-count">02</div>
                <p>
                  Real-time work order
                  <br /> updates - from any device.
                </p>
              </SwiperSlide>
              <SwiperSlide>
                <div className="slide-item-count">03</div>
                <p>
                  Intuitive reports for instant insights on performance and
                  outstanding items.
                </p>
              </SwiperSlide>
            </SwiperComponent>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdvantageSection;
