import { useState } from "react";
import PageHeader from "../../updatedcomponents/general-ui/PageHeader";
import WorkOrderDetails from "./WorkOrderDetails";
import { ArrowBack, ChevronLeft, ChevronRight } from "@mui/icons-material";
import TaskNotes from "../../updatedcomponents/modals/TaskNotes";
import { useSwipeable } from "react-swipeable";
import { Tooltip } from "react-tooltip";
import { useWorkOrder } from "../../hooks/useWorkOrder";
import { useAuth } from "../../hooks/useAuth";
import { useNavigate } from "react-router-dom";

const WorkOrderInstance = () => {
  const {
    selectedWorkOrder,
    fetchAdjacentWorkOrder,
    searchParams,
  } = useWorkOrder();

  const swipeHandlers = useSwipeable({
    onSwipedLeft: () => {
      fetchAdjacentWorkOrder("next");
    },
    onSwipedRight: () => {
      fetchAdjacentWorkOrder("prev");
    },
  });

  const navigate = useNavigate();
  return (
    <>
      <PageHeader
        title={
          <button
            type="button"
            className="flex gap-2 items-center text-md font-normal text-secondary-300 hover:text-secondary-200 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-secondary-100 "
            onClick={() => navigate(`/work-orders?${searchParams}`)}
          >
            <ArrowBack style={{ fontSize: "1rem" }} /> Back to Work Orders
          </button>
        }
        subtitle={""}
        buttons={
          <div className="gap-2  hidden sm:flex">
            <button
              className="text-secondary-300 hover:text-secondary-100 hover:bg-secondary-1100 rounded-full px-0.5 py-0.5"
              onClick={() => fetchAdjacentWorkOrder("prev")}
              data-tooltip-id="adjacent_work_order"
              data-tooltip-content="Previous Work Order"
              data-tooltip-place="bottom"
            >
              <ChevronLeft />
            </button>
            <button
              className="text-secondary-300 hover:text-secondary-100 hover:bg-secondary-1100 rounded-full px-0.5 py-0.5"
              onClick={() => fetchAdjacentWorkOrder("next")}
              data-tooltip-id="adjacent_work_order"
              data-tooltip-content="Next Work Order"
              data-tooltip-place="bottom"
            >
              <ChevronRight />
            </button>
            <Tooltip id="adjacent_work_order" place="bottom" />
          </div>
        }
      />
      <div {...swipeHandlers}>{selectedWorkOrder && <WorkOrderDetails />}</div>

      <TaskNotes />
    </>
  );
};

export default WorkOrderInstance;
