import React from "react";
// import "bootstrap/dist/css/bootstrap.min.css";
// import "bootstrap/dist/js/bootstrap.bundle.min";

const HDSection: React.FC = () => {
  return (
    <div className="hd-section">
      <div className="container">
        <div className="inner-container">
          <div className="section-heading fadeInUp">
            <h2>
              <strong>Sequra:</strong> All Hands on Deck
            </h2>
          </div>
          <div className="main-image-holder fadeInUp">
            <img
              className="w-100"
              src="/assets/images/all_hands_on_deck.png"
              alt=""
            />
          </div>
          <div className="tab-wrapper fadeInUp">
            <div className="nav-wrapper dropdown">
              <div
                className="selectBtn d-md-none dropdown-toggle"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <span className="ico">
                  <img src="/assets/images/icon-command.svg" alt="" />
                </span>{" "}
                Command
              </div>
              <div className="dropdown-menu">
                <ul className="nav" role="tablist">
                  <li className="nav-item">
                    <a
                      className="nav-link active"
                      data-bs-toggle="tab"
                      href="#hd-tab-1"
                    >
                      <span className="ico">
                        <img src="/assets/images/icon-command.svg" alt="" />
                      </span>{" "}
                      Command
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      data-bs-toggle="tab"
                      href="#hd-tab-2"
                    >
                      <span className="ico">
                        <img src="/assets/images/icon-control.svg" alt="" />
                      </span>{" "}
                      Control
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      data-bs-toggle="tab"
                      href="#hd-tab-3"
                    >
                      <span className="ico">
                        <img src="/assets/images/icon-comply.svg" alt="" />
                      </span>
                      Comply
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            {/* Tab Panes */}
            <div className="tab-content">
              <div id="hd-tab-1" className="tab-pane fade show active">
                <div className="row columns">
                  <div className="col-md-4 column">
                    <div className="row gx-4">
                      <div className="col-auto">
                        <span className="ico icon-square-check"></span>
                      </div>
                      <div className="col gutters-end-0 column__cnt">
                        <h5>Intuitive Task Setup</h5>
                        <p>
                          Simplify task management from start to finish.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4 column">
                    <div className="row gx-4">
                      <div className="col-auto">
                        <span className="ico icon-square-check"></span>
                      </div>
                      <div className="col gutters-end-0 column__cnt">
                        <h5>Dynamic Scheduling</h5>
                        <p>
                          Assign and update tasks with ease.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4 column">
                    <div className="row gx-4">
                      <div className="col-auto">
                        <span className="ico icon-square-check"></span>
                      </div>
                      <div className="col gutters-end-0 column__cnt">
                        <h5>Automated Workflows</h5>
                        <p>
                          Streamline assignments to maximize efficiency
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div id="hd-tab-2" className="tab-pane fade">
                <div className="row columns">
                  <div className="col-md-4 column">
                    <div className="row gx-4">
                      <div className="col-auto">
                        <span className="ico icon-square-check"></span>
                      </div>
                      <div className="col gutters-end-0 column__cnt">
                        <h5>Real-Time Monitoring</h5>
                        <p>
                          Keep operations running smoothly at all times.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4 column">
                    <div className="row gx-4">
                      <div className="col-auto">
                        <span className="ico icon-square-check"></span>
                      </div>
                      <div className="col gutters-end-0 column__cnt">
                        <h5>Staff Insights</h5>
                        <p>
                          Track productivity and optimize performance.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4 column">
                    <div className="row gx-4">
                      <div className="col-auto">
                        <span className="ico icon-square-check"></span>
                      </div>
                      <div className="col gutters-end-0 column__cnt">
                        <h5>Analytics in Action</h5>
                        <p>
                          Leverage data to make smart decisions.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div id="hd-tab-3" className="tab-pane fade">
                <div className="row columns">
                  <div className="col-md-4 column">
                    <div className="row gx-4">
                      <div className="col-auto">
                        <span className="ico icon-square-check"></span>
                      </div>
                      <div className="col gutters-end-0 column__cnt">
                        <h5>Compliance, Simplified</h5>
                        <p>
                          Easily comply with CMS and NFPA requirements.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4 column">
                    <div className="row gx-4">
                      <div className="col-auto">
                        <span className="ico icon-square-check"></span>
                      </div>
                      <div className="col gutters-end-0 column__cnt">
                        <h5>Comprehensive Audit Logs</h5>
                        <p>
                          Ensure nothing gets lost or falls through the cracks.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4 column">
                    <div className="row gx-4">
                      <div className="col-auto">
                        <span className="ico icon-square-check"></span>
                      </div>
                      <div className="col gutters-end-0 column__cnt">
                        <h5>Standards Met</h5>
                        <p>
                          Ensure all entries meet regulatory standards.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="hd-section__btmImg fadeInUp">
        <div className="floating-bg">
          <img
            className="w-100 parallaxBlockUp"
            src="/assets/images/bg-vector-1.png"
            alt=""
          />
        </div>
        <div className="container">
          <img className="w-100" src="/assets/images/iMac1.png" alt="" />
        </div>
      </div>
    </div>
  );
};

export default HDSection;
