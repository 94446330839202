import apiClient from "../apiClient";

export const fetchWorkOrderLogs = async (
  page: string,
  pageSize: string,
  filters: object,
  selectedFacility: string
) => {
  try {
    const response = await apiClient.get(
      `/log-book/work-order/list/closed/${selectedFacility}`,
      {
        params: {
          page,
          pageSize,
          filters: JSON.stringify(filters),
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching logs", error);
  }
};

export const fetchLogs = async (
  selectedTab: string,
  filters: object,
  selectedFacility: string
) => {
  try {
    const response = await apiClient.get(`/log-book/list/${selectedFacility}`, {
      params: {
        type: selectedTab,
        filters: JSON.stringify(filters),
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching logs", error);
  }
};

export const fetchLogInstance = async (logId: string) => {
  try {
    const response = await apiClient.get(`/log-book/instance/${logId}`);
    return response.data;
  } catch (error) {
    console.error("Error fetching log instance", error);
  }
};

export const fetchAttachments = async (encodedLink: string) => {
  try {
    await apiClient
      .get("/log/file", {
        params: { encodedLink },
      })
      .then((response) => {
        window.open(response.data.url, "_blank");
      });
  } catch (error) {
    console.error("Error fetching file:", error);
  }
};

export const printLog = async (
  startDate: string,
  endDate: string | null,
  logs: { [key: string]: any },
  selectedTab: string,
  selectedFacility: string,
  exportFormat: "pdf" | "excel" = "pdf"
) => {

  try {
    const response = await apiClient.post(
      "/log-book/log/print",
      {
        startDate,
        endDate,
        logs,
        printState: selectedTab,
        facilityId: selectedFacility,
        exportFormat,
      },
      {
        responseType: "arraybuffer",
      }
    );

    let blob;
    let fileName;

    if (exportFormat === "excel") {
      // Handle Excel format
      blob = new Blob([response.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      fileName = `logs-${startDate}${endDate ? `-${endDate}` : ""}.xlsx`;

      // Download Excel file
      const url = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
      link.remove();
      URL.revokeObjectURL(url);
    } else {
      // Handle PDF format
      blob = new Blob([response.data], {
        type: "application/pdf",
      });

      // Open PDF in new tab
      const pdfUrl = URL.createObjectURL(blob);
      window.open(pdfUrl);
      // Clean up the URL object after window opens
      setTimeout(() => URL.revokeObjectURL(pdfUrl), 100);
    }
  } catch (error) {
    console.error("Error printing logs", error);
    // Add proper error handling here
  }
};
