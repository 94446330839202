import React, {
  createContext,
  useState,
  ReactNode,
  useCallback,
  useEffect,
} from "react";
import { useFacility } from "../hooks/useFacility";
import moment from "moment-timezone";
import useFetchData from "../hooks/useFetchData";
import { WorkOrder } from "../types/WorkOrder";
import { useNavigate } from "react-router-dom";
import {
  fetchRecurringTableData,
  fetchWorkOrderData,
} from "../api/services/HomeService";

interface RecurringTableData {
  complete: number;
  incomplete: number;
  closed: number;
  overdue: number;
}

interface WorkOrderStatusCounts {
  complete: number;
  incomplete: number;
  closed: number;
  unable: number;
}

interface WorkOrderData {
  workOrders: WorkOrder[];
  workOrderStatusCounts: WorkOrderStatusCounts;
}

interface DateParams {
  displayText: string;
  startDate: Date | null;
  endDate: Date | null;
  selectedRange: string;
}

// Define the type for the context values
interface HomeContextType {
  currentRecurringTableData: RecurringTableData;
  currentWorkOrders: WorkOrderData;
  dateParams: DateParams;
  setDateParams: React.Dispatch<React.SetStateAction<DateParams>>;
  handleReset: () => void;
  handleView: (workOrderId: string) => void;
}

// Define the type for the provider props
interface HomeProviderProps {
  children: ReactNode;
}

// Create the context
export const HomeContext = createContext<HomeContextType | undefined>(
  undefined
);

// Create the provider component
export const HomeProvider = ({ children }: HomeProviderProps) => {
  const { selectedFacility } = useFacility();
  const [currentRecurringTableData, setCurrentRecurringTableData] =
    useState<RecurringTableData>({
      complete: 1,
      incomplete: 1,
      closed: 1,
      overdue: 1,
    });

  const [workOrderData, setWorkOrderData] = useState<WorkOrderData>({
    workOrders: [],
    workOrderStatusCounts: { complete: 1, incomplete: 1, closed: 1, unable: 1 },
  });

  //const [showDateFilter, setShowDateFilter] = useState<boolean>(false);
  const [dateParams, setDateParams] = useState<DateParams>({
    displayText: moment().format("MMMM YYYY"),
    startDate: moment().startOf("month").toDate(),
    endDate: moment().endOf("month").toDate(),
    selectedRange: "month",
  });

  const loadTableData = useCallback(async () => {
    try {
      const data = await fetchRecurringTableData(selectedFacility, dateParams);
      setCurrentRecurringTableData(data);
    } catch (error) {}
  }, [selectedFacility, dateParams]);

  const loadWorkOrderData = useCallback(async () => {
    try {
      const data = await fetchWorkOrderData(selectedFacility, dateParams);
      setWorkOrderData(data);
    } catch (error) {
      console.error("Error fetching work order data:", error);
    }
  }, [selectedFacility, dateParams]);

  useEffect(() => {
    if (selectedFacility) {
      loadTableData();
      loadWorkOrderData();
    }
  }, [selectedFacility, loadTableData]);

  const handleReset = () => {
    setDateParams({
      displayText: moment().format("MMMM YYYY"),
      startDate: moment().startOf("month").toDate(),
      endDate: moment().endOf("month").toDate(),
      selectedRange: "month",
    });
  };
  const navigate = useNavigate();
  const handleView = (workOrderId: string) => {
    navigate(
      `/work-orders/instance/${workOrderId}?filters=%7B"urgency"%3A"urgent"%7D`
    );
  };
  return (
    <HomeContext.Provider
      value={{
        currentRecurringTableData,
        currentWorkOrders: workOrderData,
        dateParams,
        setDateParams,
        handleReset,
        handleView,
      }}
    >
      {children}
    </HomeContext.Provider>
  );
};
