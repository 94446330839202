// hooks/useMultiGeneratorForm.js
import { useState } from "react";

const useMultiForm = (totalItems: any[]) => {
  const [currentIndex, setCurrentIndex] = useState(-1);
  const isFirstSection = currentIndex === 0;
  const isLastSection = currentIndex === totalItems.length - 1;

  const goToSection = (index: number) => {
    setCurrentIndex(index);
  };

  const goToNextSection = () => {
    setCurrentIndex((prevIndex) =>
      Math.min(prevIndex + 1, totalItems.length - 1)
    );
  };

  const goToPreviousSection = () => {
    setCurrentIndex((prevIndex) => prevIndex - 1);
  };


  return {
    currentIndex,
    goToSection,
    goToNextSection,
    goToPreviousSection,
    isFirstSection,
    isLastSection
  };
};

export default useMultiForm;
