import React, { useEffect, useState } from "react";
import { useFieldArray, useFormContext, useWatch } from "react-hook-form";
import { OnboardingFormValues } from "./OnboardingSchema";
import Input from "../updatedcomponents/general-ui/Input";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import Button from "../updatedcomponents/general/Button";
import { capitalizeString } from "../utils/FormatFunctions";

const TaskSelectionStep: React.FC<{ handleSave: () => void }> = ({
  handleSave,
}) => {
  const {
    control,
    setValue,
    register,
    formState: { errors },
  } = useFormContext<OnboardingFormValues>();
  const { fields: taskFields } = useFieldArray({ control, name: "tasks" });
  const [showIndex, setShowIndex] = useState<number | null>(null);

  const toggleCollapse = (index: number) => {
    setShowIndex((prev) => (prev === index ? null : index));
  };

  const handleSkip = (index: number, require: boolean) => {
    setValue(`tasks.${index}.skipped`, require);
    handleSave();
  };
  return (
    <div className="animate-fade-in-right">
      {taskFields.map((taskField, taskIndex) => {
        const skipped = useWatch({
          control,
          name: `tasks.${taskIndex}.skipped`,
        });
        return (
          <div
            key={taskField.id}
            className={`flex flex-col border p-4 mb-4 rounded-sm shadow-sm ${
              errors.tasks?.[taskIndex]
                ? "border-reds-500"
                : "border-secondary-1000"
            }`}
          >
            <h3
              className="text-md cursor-pointer flex justify-between items-center"
              onClick={() => toggleCollapse(taskIndex)}
            >
              <div>
                <span>{taskField.title}</span>
                {skipped && (
                  <span className="ml-2 text-secondary-300 bg-secondary-1100 rounded-full px-1.5 py-0.5 text-xs">
                    Skipped
                  </span>
                )}
              </div>
              <button type="button" className="text-secondary-500">
                {showIndex !== taskIndex ? <ExpandMore /> : <ExpandLess />}
              </button>
            </h3>
            {showIndex === taskIndex && (
              <div className="flex flex-col gap-y-2">
                <span className=" font-light text-sm w-3/4">
                  {taskField.details}
                </span>

                <span className="w-fit text-secondary-300 bg-secondary-1100 rounded-full px-1.5 py-0.5 text-xs">
                  {capitalizeString(taskField?.recurrence?.frequency)}
                </span>
                <Input
                  register={register}
                  name={`tasks[${taskIndex}].startDate`}
                  label="Start Date"
                  type="date"
                  errors={errors.tasks?.[taskIndex]?.startDate}
                />
                {skipped ? (
                  <Button
                    type="button"
                    styleString="secondary"
                    children={`Require Task`}
                    icon="Verified"
                    onClick={() => handleSkip(taskIndex, false)}
                  />
                ) : (
                  <Button
                    type="button"
                    styleString="secondary"
                    children={`Skip Task`}
                    icon="Redo"
                    onClick={() => handleSkip(taskIndex, true)}
                  />
                )}
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default TaskSelectionStep;
