import React from "react";
import AssignmentSelector from "../general-ui/AssignmentSelector";
import {
  Delete,
  FileOpenOutlined,
  NoteOutlined,
  UndoOutlined,
} from "@mui/icons-material";
import { capitalizeString, formatDate } from "../../utils/FormatFunctions";
import StatusBadge from "../general-ui/StatusBadges";
import { useAuth } from "../../hooks/useAuth";
import { WorkOrder, WorkOrderStatus } from "../../types/WorkOrder";
import { useWorkOrder } from "../../hooks/useWorkOrder";

const getStatusClasses = (status: WorkOrderStatus) => {
  switch (status) {
    case "complete":
      return "bg-accent-1000 text-accent-200 border-accent-200 ring-accent-1000 focus:ring-accent-700";
    case "incomplete":
      return "bg-secondary-1200 text-secondary-300 border-secondary-900 ring-secondary-1000  focus:ring-secondary-700";
    case "unable":
      return "bg-reds-1100 text-reds-500 border-reds-500 ring-reds-1000  focus:ring-reds-700";
    default:
      return "bg-white text-black";
  }
};

interface DetailsProps {
  dataToRender: {
    title: string;
    dataTag: string;
  }[];
  handleDeleteClick: (workOrder: WorkOrder) => void;
  handleReopenClick: (workOrder: WorkOrder) => void;
  handleWorkOrderStatusChange: (
    e: React.ChangeEvent<HTMLSelectElement>,
    workOrder: WorkOrder,
    status: WorkOrderStatus
  ) => void;
  handleClickClosed: () => void;
}

const Details: React.FC<DetailsProps> = ({
  dataToRender,
  handleDeleteClick,
  handleReopenClick,
  handleWorkOrderStatusChange,
  handleClickClosed,
}) => {
  const { currentUser } = useAuth();
  const permissions = currentUser.access;
  
  const { selectedWorkOrder, handleAssignment, users, updateModalShowState } =
    useWorkOrder();

  if (!selectedWorkOrder) {
    return null;
  }

  return (
    <>
      {dataToRender
        .filter(
          (data) => data.dataTag !== "status" && data.dataTag !== "details"
        )
        .map((data, index) => (
          <div
            key={index}
            className="py-2 grid grid-cols-2 sm:grid-cols-3 sm:gap-4 sm:px-0 text-xs sm:text-sm"
          >
            <dt className="font-medium text-secondary-100">{data.title}</dt>
            <dd className="flex text-secondary-400 sm:col-span-2">
              <span className="flex-grow">
                {data.dataTag !== "dateReported"
                  ? capitalizeString(
                      selectedWorkOrder[
                        data.dataTag as keyof WorkOrder
                      ] as string
                    )
                  : formatDate(
                      selectedWorkOrder[
                        data.dataTag as keyof WorkOrder
                      ] as string,
                      "MMM D, YYYY h:mm a"
                    )}
              </span>
            </dd>
          </div>
        ))}

      {/* Details section */}
      <div className="py-2 grid grid-cols-2 sm:grid-cols-3 sm:gap-4 sm:px-0 text-xs sm:text-sm">
        <dt className="font-medium text-secondary-100">Details</dt>
        <dd className="flex text-secondary-400 sm:col-span-2">
          <span className="flex-grow">{selectedWorkOrder.details}</span>
        </dd>
      </div>
      <div className="text-xs sm:text-sm py-2 grid grid-cols-2 sm:grid-cols-3 sm:gap-4 sm:px-0">
        <dt className=" font-medium text-gray-900">Assigned To</dt>
        <dd className="mt-1 flex text-sm text-secondary-400 sm:col-span-2 sm:mt-0">
          {currentUser.access !== "worker" && users.length > 0 ? (
            <AssignmentSelector
              users={users}
              task={selectedWorkOrder}
              selectedUser={
                selectedWorkOrder.assignedTo
                  ? selectedWorkOrder.assignedTo
                  : null
              }
              handleChange={handleAssignment}
            />
          ) : (
            <span className="text-secondary-400">
              {selectedWorkOrder?.assignedTo?.firstname}{" "}
              {selectedWorkOrder?.assignedTo?.lastname}
            </span>
          )}
        </dd>
      </div>
      {selectedWorkOrder.status !== "closed" ? (
        <div className="text-xs sm:text-sm py-2 grid grid-cols-2 sm:grid-cols-3 sm:gap-4 sm:px-0">
          <dt className=" font-medium text-gray-900">Status</dt>
          <dd className="mt-1 flex text-sm text-secondary-400 sm:col-span-2 sm:mt-0">
            <select
              className={`w-40 sm:w-48 text-xs rounded-sm py-1 font-medium border ring-1 ring-inset focus:z-10 focus:border-transparent ${getStatusClasses(
                selectedWorkOrder.status
              )}`}
              value={selectedWorkOrder.status}
              onChange={(e) => {
                const status = e.target.value;
                switch (status) {
                  case "complete":
                    handleWorkOrderStatusChange(
                      e,
                      selectedWorkOrder,
                      "complete"
                    );
                    break;
                  case "incomplete":
                    handleWorkOrderStatusChange(
                      e,
                      selectedWorkOrder,
                      "incomplete"
                    );
                    break;
                  case "unable":
                    handleWorkOrderStatusChange(e, selectedWorkOrder, "unable");
                    break;
                  default:
                    break;
                }
              }}
            >
              <option value="complete">Complete</option>
              <option value="incomplete">Incomplete</option>
              <option value="unable">Unable</option>
            </select>
          </dd>
        </div>
      ) : (
        <StatusBadge status={selectedWorkOrder.status} />
      )}
      {selectedWorkOrder.status === "complete" &&
        currentUser.access !== "worker" && (
          <div className="text-xs sm:text-sm py-2 grid grid-cols-2 sm:grid-cols-3 sm:gap-4 sm:px-0">
            <dt className=" font-medium text-gray-900">Close out</dt>
            <dd className="mt-1 flex text-sm text-secondary-400 sm:col-span-2 sm:mt-0">
              <button
                className="bg-accent-500 hover:bg-accent-400 text-primary rounded-sm px-2 py-1"
                onClick={() => handleClickClosed()}
              >
                Close Out
              </button>
            </dd>
          </div>
        )}
      <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0 border-b border-secondary-1000">
        <dt className="text-xs sm:text-sm font-medium leading-6 text-gray-900">
          Notes
        </dt>
        <dd className="flex text-secondary-400 sm:col-span-2 ">
          {selectedWorkOrder.notes && selectedWorkOrder?.notes.length > 0 ? (
            <ul className="bg-secondary-1100 rounded-md w-full max-w-md px-1.5 max-h-80 overflow-y-auto scrollbar-thin ">
              {selectedWorkOrder.notes.map((note, index) => (
                <li key={index}>
                  <span className="text-xs text-grey-900">{note.text}</span>
                  {
                    <div className="text-xs text-gray-400">
                      Posted by{" "}
                      {note.userId ? note.userId.firstname : "Unknown user"} at{" "}
                      {formatDate(note.timestamp, "MMM D, YYYY h:mm a")}
                    </div>
                  }
                </li>
              ))}
              <button
                className="bg-secondary-100 text-primary text-xs flex px-2 py-1 my-2 rounded-sm mx-auto items-center gap-2"
                onClick={() => updateModalShowState("showNotes", true)}
              >
                <FileOpenOutlined style={{ fontSize: "1rem" }} /> View notes
              </button>
            </ul>
          ) : (
            <div className="bg-gray-100 rounded-md w-full max-w-md px-1.5 py-2 flex flex-col text-gray-500  justify-center items-center">
              <NoteOutlined />
              <span className="text-xs  text-center">No previous notes</span>
              <button
                className="bg-secondary-100 text-primary text-xs flex px-2 py-1 mt-4 mb-2 rounded-sm mx-auto items-center gap-2"
                onClick={() => updateModalShowState("showNotes", true)}
              >
                <FileOpenOutlined style={{ fontSize: "1rem" }} /> View notes
              </button>
            </div>
          )}
        </dd>
      </div>
      {(permissions === "organization-admin" || permissions === "regional") && (
        <div className="flex w-full flex-col">
          {selectedWorkOrder.status === "closed" && (
            <div className="my-2 flex items-center py-1.5 px-1 rounded-md text-primary">
              <button
                className="flex items-center rounded-sm bg-accent-300 px-2.5 py-1 text-sm font-semibold shadow-sm ring-1 ring-inset ring-accent-200 hover:bg-accent-200 hover:shadow"
                onClick={() => handleReopenClick(selectedWorkOrder)}
              >
                <UndoOutlined style={{ fontSize: "1rem" }} />{" "}
                <span className="ml-2">Reopen task</span>
              </button>
            </div>
          )}

          <div className="my-2 flex items-center py-1.5 px-1 rounded-md text-reds-500 ">
            <button
              className="flex items-center rounded-sm bg-white px-2.5 py-1 text-sm font-semibold text-reds-600 shadow-sm ring-1 ring-inset ring-reds-500 hover:bg-gray-50 hover:shadow"
              onClick={() => handleDeleteClick(selectedWorkOrder)}
            >
              <Delete style={{ fontSize: "1rem" }} />{" "}
              <span className="ml-2">Delete Forever</span>
            </button>
            <p className=" text-sm text-red-700 px-1 ml-3">
              This action can not be undone.
            </p>
          </div>
        </div>
      )}
    </>
  );
};

export default Details;
