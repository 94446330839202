import React from "react";
import { scrollToComponent } from "../utils/helpers";

const HeroBanner: React.FC = () => {
  return (
    <div className="hero-banner">
      {/*  <img
        className="floating-pattern-bg"
        src="/assets/images/favicon-portion-bg.png"
        /> */}
      <div
        className="floating-pattern-bg"
        style={{
          backgroundImage: "url(/assets/images/favicon-portion-bg.png)",
        }}
      ></div>
      <div className="container-fluid">
        <div className="inner-container-fluid d-flex flex-column justify-content-center text-center fadeInUpInitial">
          <div
            className="bgImg heroBannerParallaxImg d-none d-md-block"
            style={{
              backgroundImage: "url(/assets/images/hero-banner-bg-2.jpg)",
            }}
          ></div>
          <div
            className="bgImg heroBannerParallaxImg d-md-none"
            style={{
              marginRight: "-5px",
              backgroundImage:
                "url(/assets/images/hero-banner-bg-2-mobile.jpg)",
            }}
          ></div>

          <div className="content-holder gutters-end-0 fadeInUpInitial">
            <h1>Stay On Track</h1>
            <h4>The future is here.</h4>
            <p>
              Sequra has modernized healthcare facility management, with a
              streamlined platform that seamlessly tracks and oversees all
              aspects of facility safety, compliance, and operational
              efficiency.
            </p>
            <button className="btn btn-gradient" onClick={scrollToComponent}>
              See Sequra In Action
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeroBanner;
