import { WorkOrder } from "../../types/WorkOrder";
import apiClient from "../apiClient";

// Service for creating a new work order
export const createWorkOrder = async (formData: FormData) => {
  try {
    await apiClient.post("/work-order/create", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  } catch (err) {
    console.error("Error creating work order:", err);
  }
};

// Service for fetching all work orders with query and facility
export const fetchWorkOrders = async (
  queryString: string,
  facility: string
): Promise<{ workOrders: WorkOrder[]; totalPages: number } | undefined> => {
  try {
    const response = await apiClient.get(
      `/work-order/list/${facility}?${queryString}`
    );
    const { workOrders, totalPages } = response.data;
    return { workOrders, totalPages };
  } catch (err) {
    console.error("Error fetching work order:", err);
  }
};

// Service for fetching a single work order
export const fetchWorkOrder = async (id: string) => {
  try {
    const response = await apiClient.get(`/work-order/instance/${id}`);

    return response.data;
  } catch (err) {
    console.error("Error fetching work order:", err);
  }
};

// Service for fetching images based on query string
export const getImages = async (queryString: string) => {
  try {
    const response = await apiClient.get(`/work-order/images?${queryString}`);
    return response.data;
  } catch (error) {
    console.error("Error fetching images:", error);
  }
};

// Service for fetching adjacent work order based on query and facility
export const fetchNextWorkOrder = async (
  queryString: string,
  facility: string
) => {
  try {
    const response = await apiClient.get(
      `/work-order/next/${facility}?${queryString}`
    );
    return response.data;
  } catch (err) {
    console.error("Error fetching adjacent work order:", err);
  }
};

// Service for fetching all users with query and facility
export const fetchFacilityUsers = async (selectedFacility: string) => {
  try {
    const response = await apiClient.get(
      `/work-order/users/${selectedFacility}`
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching users:", error);
  }
};

// Service for fetching all kiosks with query and facility
export const fetchFacilityKiosks = async (selectedFacility: string) => {
  try {
    const response = await apiClient.get(
      `/work-order/kiosks/${selectedFacility}`
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching users:", error);
  }
};

// Service for assigning a work order
export const handleWorkOrderAssignment = async (
  workOrderId: string,
  selectedUser: string,
  currentUser: string
) => {
  try {
    await apiClient.put(`/work-order/assign/${workOrderId}`, {
      assignedTo: selectedUser === "unassign" ? null : selectedUser,
      assignedBy: currentUser,
    });
  } catch (error) {
    console.error("Error assigning work order:", error);
    throw new Error("Error assigning work order");
  }
};

// Service for updating a work order status
export const handleWorkOrderStatusUpdate = async (
  workOrderId: string,
  status: string,
  currentUser: string,
  note?: string
) => {
  try {
    const payload = { userId: currentUser, status: status, note: note };

    await apiClient.put(`/work-order/update-status/${workOrderId}`, payload);
  } catch (error) {
    throw new Error("Error updating work order status");
  }
};

// Service for deleting a work order
export const handleWorkOrderDelete = async (workOrderId: string) => {
  try {
    await apiClient.delete(`/work-order/delete/${workOrderId}`);
  } catch (error) {
    throw new Error("Error deleting work order");
  }
};

export const createNote = async (
  workOrderId: string,
  noteText: string,
  userId: string
) => {
  try {
    const postedNote = await apiClient.post(`/work-order/note/${workOrderId}`, {
      text: noteText,
      user: userId,
    });
    return postedNote.data;
  } catch (error) {
    throw new Error("Error adding note");
  }
};
