import { UpdateUserFormData } from "../../pages/ApplicationPages/Settings";
import { ImageFormData } from "../../updatedcomponents/forms/UploadImage";
import apiClient from "../apiClient";

export const updateUser = async (
  userId: string,
  updatedUserData: UpdateUserFormData
) => {
  try {
    await apiClient.put(`/settings/user/${userId}`, updatedUserData);
    return;
  } catch (error) {
    console.error(error);
  }
};

export const updateImage = async (userId: string, formData: FormData) => {
  try {
    const response = await apiClient.put(
      `/settings/upload/avatar/${userId}`,
      formData
    );
    return response.data.uri;
  } catch (error) {
    console.error(error);
    return "";
  }
};

export const removeImage = async () => {
  try {
    await apiClient.delete(`/settings/avatar`);
  } catch (error) {
    console.error(error);
  }
};
