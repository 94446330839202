import {
  SetURLSearchParams,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { User } from "../types/User";
import { createContext, useCallback, useEffect, useState } from "react";
import { Facility } from "../types/Facility";
import { updateSearchParams } from "../utils/ParamUtils";
import { debounce } from "../utils/Helpers";
import { useFacility } from "../hooks/useFacility";
import {
  createUser,
  deleteUser,
  fetchTeam,
  fetchUser,
  fetchUserHistory,
} from "../api/services/UserService";
import { useAuth } from "../hooks/useAuth";
import { fetchWorkOrders } from "../api/services/WorkOrderService";
import { WorkOrder } from "../types/WorkOrder";
import { TaskInstance } from "../types/TaskInstance";

interface PageSizeChangeProps {
  pageSize: string;
  page: number;
}

interface FormValues {
  firstname: string;
  lastname: string;
  email: string;
  phone: string;
  access: string;
  isVerified?: boolean;
  joinDate?: Date;
  facility: string;
}

interface TeamContextType {
  team: User[];
  selectedUser: User | null;
  handleAddUser: () => void;
  createNewUser: (data: FormValues) => void;
  updateModalShowState: (modal: string, value: boolean) => void;
  pageSize: string;
  totalPages: number;
  page: string;
  isLoading: boolean;
  filters: string;
  modalShowState: {
    showDelete: boolean;
    showFilter: boolean;
    showNewUser: boolean;
  };
  handlePageSizeChange: ({ pageSize, page }: PageSizeChangeProps) => void;
  handlePageChange: (page: number) => void;
  facilities: Facility[];
  permissionToNavigate: boolean;
  onUserClick: (user: User) => void;
  userWorkOrders: WorkOrder[];
  userTasks: TaskInstance[];
  workOrderHistory: any;
  handleViewTask: (task: WorkOrder | TaskInstance, type: string) => void;
  handleDeleteUser: (user: User) => void;
}

export const TeamContext = createContext<TeamContextType | undefined>(
  undefined
);

export const TeamProvider = ({ children }: { children: React.ReactNode }) => {
  const { selectedFacility } = useFacility();
  const { currentUser } = useAuth();
  const [searchParams, setSearchParams] = useSearchParams();
  const [team, setTeam] = useState<User[]>([]);
  const [selectedUser, setSelectedUser] = useState<User | null>(null);
  const [facilities, setFacilities] = useState<Facility[]>([]);

  const loadTeam = useCallback(
    debounce(async () => {
      console.log("Loading team...");
      try {
        setIsLoading(true);
        const filterObject = filters ? JSON.parse(filters) : {};

        if (!selectedFacility) {
          return;
        }

        const queryString = new URLSearchParams({
          ...filterObject,
          page,
          pageSize,
        }).toString();

        const result = await fetchTeam(selectedFacility, queryString);

        if (result) {
          const { users, totalPages } = result;
          setTeam(users || []);
          setTotalPages(totalPages);
        } else {
          setTeam([]);
          setTotalPages(1);
        }
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching team:", error);
        setIsLoading(false);
      }
    }, 300),
    [searchParams, selectedFacility]
  );

  const [totalPages, setTotalPages] = useState<number>(1);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const filters = searchParams.get("filters") || "";
  const page = searchParams.get("page") || "1";
  const pageSize = searchParams.get("pageSize") || "10";

  const [modalShowState, setModalShowState] = useState({
    showDelete: false,
    showFilter: false,
    showNewUser: false,
  });

  const handleAddUser = () => {
    updateModalShowState("showNewUser", true);
  };

  const createNewUser = async (data: FormValues) => {
    try {
      await createUser(data);
      updateModalShowState("showNewUser", false);
      loadTeam();
    } catch (error) {
      console.error("Error creating new user:", error);
    }
  };

  const updateModalShowState = (modalName: string, show: boolean) => {
    setModalShowState((prevState) => ({
      ...prevState,
      [modalName]: show,
    }));
  };

  const handlePageSizeChange = ({ pageSize, page }: PageSizeChangeProps) => {
    updateSearchParams(setSearchParams, { pageSize, page: page.toString() });
  };

  const handlePageChange = (newPage: number) => {
    updateSearchParams(setSearchParams, { page: newPage.toString() });
  };

  const permissionToNavigate = [
    "regional",
    "organization-admin",
    "director",
    "administrator",
  ].includes(currentUser?.access);

  const navigate = useNavigate();

  const onUserClick = (user: User) => {
    if (permissionToNavigate) {
      resetData();
      navigate(`/team/${user._id}`);
    }
  };

  const userId = useParams().id;

  const handleViewTask = (task: WorkOrder | TaskInstance, type: string) => {
    let route = "";
    if (type === "Work Orders") {
      route = `/work-orders/instance/${task._id}`;
    } else {
      route = `/tasks`;
    }
    navigate(route);
  };

  const [userWorkOrders, setUserWorkOrders] = useState<WorkOrder[] | []>([]);
  const [workOrderHistory, setWorkOrderHistory] = useState<any>([]);

  const fetchUserData = async () => {
    if (!userId || !selectedFacility) {
      return;
    }
    setIsLoading(true);
    const userData = await fetchUser(userId);
    setSelectedUser(userData);
    // Add string for userId
    const filterString = `assignedTo=${userId}&status=incomplete`;
    const usersWorkOrders = await fetchWorkOrders(
      filterString,
      selectedFacility
    );
    if (usersWorkOrders) {
      setUserWorkOrders(usersWorkOrders.workOrders);
    }
    const history = await fetchUserHistory(selectedFacility, userId);
    setWorkOrderHistory(history);
    setIsLoading(false);
  };

  const handleDeleteUser = async (user: User) => {
    const userToDelete = user._id;
    try {
      await deleteUser(userToDelete);
      updateModalShowState("showDelete", false);
      loadTeam();
      navigate("/team");
    } catch (error) {
      console.log("Error deleting user:", error);
    }
  };

  const resetData = () => {
    setSelectedUser(null);
    setUserWorkOrders([]);
    setWorkOrderHistory([]);
  };

  useEffect(() => {
    fetchUserData();
  }, [userId, selectedFacility]);

  useEffect(() => {
    if (!selectedFacility) return;
    loadTeam();
  }, [searchParams, selectedFacility]);

  return (
    <TeamContext.Provider
      value={{
        team,
        selectedUser,
        handleAddUser,
        createNewUser,
        updateModalShowState,
        pageSize,
        totalPages,
        page,
        isLoading,
        filters,
        modalShowState,
        handlePageSizeChange,
        handlePageChange,
        facilities,
        permissionToNavigate,
        onUserClick,
        userWorkOrders,
        userTasks: [], //TODO:COMPLETE LOGIC FOR FETCHING
        workOrderHistory,
        handleViewTask,
        handleDeleteUser,
      }}
    >
      {children}
    </TeamContext.Provider>
  );
};
