import React, {
  createContext,
  useContext,
  useState,
  ReactNode,
  useEffect,
} from "react";

interface ToastState {
  show: boolean;
  title: string;
  body: string;
  icon: React.ReactNode | null;
}

interface ToastContextValue {
  response: ToastState;
  showResponse: (title: string, body: string, icon?: React.ReactNode) => void;
  hideResponse: () => void;
  timeToShow: number;
}

export const ToastContext = createContext<ToastContextValue | undefined>(
  undefined
);

export const ToastProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [response, setResponse] = useState<ToastState>({
    show: false,
    title: "",
    body: "",
    icon: null,
  });

  const showResponse = (
    title: string,
    body: string,
    icon: React.ReactNode = null
  ) => {
    setResponse({ show: true, title, body, icon });
  };

  const hideResponse = () => {
    setResponse((prev) => ({ ...prev, show: false }));
  };

  const timeToShow = 2500; // 2.5 seconds
  useEffect(() => {
    if (response.show) {
      const timer = setTimeout(() => {
        hideResponse();
      }, timeToShow);

      return () => clearTimeout(timer);
    }
  }, [response.show]);

  return (
    <ToastContext.Provider
      value={{ response, showResponse, hideResponse, timeToShow }}
    >
      {children}
    </ToastContext.Provider>
  );
};
