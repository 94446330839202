import React, {
  createContext,
  useCallback,
  useState,
  ReactNode,
  useEffect,
  useMemo,
} from "react";
import axios from "axios";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { RecurringTask, TaskInputType } from "../types/RecurringTask";
import { TaskInstance } from "../types/TaskInstance";
import apiClient from "../api/apiClient";
import { useFacility } from "../hooks/useFacility";
import useFetchData from "../hooks/useFetchData";
import { fetchTeam } from "../api/services/UserService";
import { User } from "../types/User";
import {
  closeOutTaskInstance,
  fetchTaskInstance,
} from "../api/services/RecurringTaskService";
import { debounce } from "../utils/Helpers";
import { useToast } from "../hooks/useToast";
import {
  CheckBadgeIcon,
  CheckCircleIcon,
  ExclamationCircleIcon,
} from "@heroicons/react/20/solid";

interface RecurringTaskContextProps {
  recurringTasks: TaskInstance[];
  users: User[];
  totalPages: number;
  isLoading: boolean;
  showState: {
    showFilter: boolean;
    showSpecs: boolean;
  };
  updateShowState: (field: string, value: boolean) => void;
  handleResetFilters: () => void;
  applyFilters: (filters: Record<string, string>) => void;
  handlePageSizeChange: ({
    pageSize,
    page,
  }: {
    pageSize: string;
    page: number;
  }) => void;
  handlePageChange: (newPage: number) => void;
  handleLogInstanceView: (logInstance: TaskInstance) => void;
  instanceToEdit: TaskInstance | null;
  setInstanceToEdit: (instance: TaskInstance | null) => void;
  filters: Record<string, string>;
  view: string;
  currentPage: string;
  tasksPerPage: string;
  navigateBack: () => void;
  files: File[];
  setFiles: React.Dispatch<React.SetStateAction<File[]>>;
  handleViewFile: (file: string) => void;
  handleTaskAssignment: (
    task: TaskInstance,
    selectedUser: string | null
  ) => void;
  onSubmit: (data: any) => void;
  handleCloseOut: () => void;
  closeOutValidation: () => void;
  canCloseOut: boolean;
}

export const RecurringTaskContext = createContext<
  RecurringTaskContextProps | undefined
>(undefined);

interface RecurringTaskProviderProps {
  children: ReactNode;
}

export const RecurringTaskProvider: React.FC<RecurringTaskProviderProps> = ({
  children,
}) => {
  const { selectedFacility } = useFacility();
  const [searchParams, setSearchParams] = useSearchParams();
  const { showResponse } = useToast();

  const [recurringTasks, setRecurringTasks] = useState<TaskInstance[]>([]);
  const [users, setUsers] = useState<User[]>([]);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const updateSearchParams = (newParams: Record<string, string>) => {
    setSearchParams(
      {
        ...Object.fromEntries(searchParams.entries()),
        ...newParams,
      },
      { replace: true }
    );
  };

  const fetchRecurringTasks = useCallback(
    debounce(async () => {
      if (!selectedFacility) return;
      try {
        setIsLoading(true);
        const page = searchParams.get("page") || "1";
        const pageSize = searchParams.get("pageSize") || "10";
        const readyToComplete = searchParams.get("readyToComplete") || "true";

        const queryParams = new URLSearchParams({
          ...filterObject,
          page,
          pageSize,
          readyToComplete,
        });

        const response = await apiClient.get(
          `/log/task/list/${selectedFacility}?${queryParams.toString()}`
        );

        if (response.data.tasks === undefined) {
          console.error("No tasks in response");
          return;
        }
        setRecurringTasks(response.data.tasks || []);
        setTotalPages(response.data.pages);
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching recurring tasks:", error);
        setIsLoading(false);
      }
    }, 200),
    [searchParams, selectedFacility]
  );

  const fetchUsers = useCallback(async () => {
    try {
      const userResponse = await fetchTeam(selectedFacility, "");
      setUsers(userResponse.users);
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  }, [selectedFacility]);

  const view = searchParams.get("view") || "list";
  const currentPage = searchParams.get("page") || "1";
  const tasksPerPage = searchParams.get("pageSize") || "10";

  const filterObject = useMemo(() => {
    return searchParams.get("filters")
      ? JSON.parse(searchParams.get("filters")!)
      : {};
  }, [searchParams]);

  const [showState, setShowState] = useState({
    showFilter: false,
    showSpecs: true,
  });

  const updateShowState = (field: string, value: boolean) => {
    setShowState({
      ...showState,
      [field]: value,
    });
  };

  const [instanceToEdit, setInstanceToEdit] = useState<TaskInstance | null>(
    null
  );

  const handleResetFilters = () => {
    updateSearchParams({
      filters: JSON.stringify(""),
      page: "1",
      view,
      readyToComplete: "true",
    });
    updateShowState("showFilter", false);
  };

  const applyFilters = (filters: Record<string, string>) => {
    updateSearchParams({
      filters: JSON.stringify(filters),
      page: "1",
    });
  };
  const handlePageSizeChange = ({
    pageSize,
    page,
  }: {
    pageSize: string;
    page: number;
  }) => {
    updateSearchParams({ pageSize, page: page.toString() });
  };

  const handlePageChange = (newPage: number) => {
    updateSearchParams({ page: newPage.toString() });
  };

  /*   const handleViewChange = (newView: string) => {
    updateSearchParams({ view: newView });
  }; */

  const navigate = useNavigate();

  const handleLogInstanceView = (logInstance: TaskInstance) => {
    navigate(
      `/tasks/${logInstance.parentTask}_${logInstance.date}?${searchParams}`
    );
  };

  const navigateBack = () => {
    setInstanceToEdit(null);
    fetchRecurringTasks();
    navigate(`/tasks?${searchParams}`);
  };

  useEffect(() => {
    if (!selectedFacility) return;
    fetchUsers();
    fetchRecurringTasks();
  }, [searchParams, selectedFacility]);

  const { id } = useParams();

  const fetchTaskInstanceData = async (parentTask: string, date: string) => {
    try {
      setIsLoading(true);
      const taskInstance = await fetchTaskInstance(parentTask, date);
      setInstanceToEdit(taskInstance);
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching task :", error);
    }
  };
  useEffect(() => {
    if (id) {
      // Split id to get parentTask and date
      const [parentTask, date] = id.split("_");

      fetchTaskInstanceData(parentTask, date);
    }
  }, [id]);

  const [files, setFiles] = useState<File[]>([]);
  const handleViewFile = async (link: string) => {
    const file = files.find((file) => file.name === link);

    if (file) {
      // If the file is in the files state, create a blob URL and open it
      const fileUrl = URL.createObjectURL(file);
      window.open(fileUrl, "_blank");
    } else {
      try {
        const encodedLink = encodeURIComponent(link);
        await apiClient
          .get("/recurring-tasks/file", {
            params: { encodedLink },
          })
          .then((response) => {
            window.open(response.data.url, "_blank");
          })
          .catch((error) => {
            console.error("Error fetching file:", error);
          });
      } catch (error) {
        console.error("Error fetching file:", error);
      }
    }
  };

  const [canCloseOut, setCanCloseOut] = useState<boolean>(false);

  const handleTaskAssignment = async (
    task: TaskInstance,
    selectedUser: string | null
  ) => {
    if (!task.childId) {
      task.childId = "createId";
    }

    try {
      await apiClient.put(`/recurring-tasks/assign`, {
        assignedTo:
          typeof selectedUser === "string" && selectedUser === "unassign"
            ? null
            : selectedUser,
        taskInfo: task,
        facility: selectedFacility,
      });

      fetchTaskInstanceData(task.parentTask._id, task.date);
      console.log("Task assigned");
      showResponse(
        "Task assigned",
        "Task has been successfully assigned",
        <CheckCircleIcon className="h-6 w-6 text-accent-500" />
      );
    } catch (error) {
      showResponse(
        "Error",
        "There was an error assigning the task",
        <ExclamationCircleIcon className="h-6 w-6 text-reds-500" />
      );
      console.error("Error assigning task:", error);
    }
  };

  const validateDataForFail = (formData: Record<string, any>): boolean => {
    if (!formData) {
      return false;
    }

    for (const [fieldId, value] of Object.entries(formData)) {
      if (fieldId === "ID" || fieldId === "Notes") continue;

      if (value === "Fail") {
        return false;
      }
    }
    return true;
  };

  const validateForCompletion = (formData: Record<string, any>): boolean => {
    if (!formData || !instanceToEdit?.parentTask?.inputs) {
      console.log("No form data or inputs");
      return false;
    }

    for (const field of instanceToEdit.parentTask.inputs) {
      if (field.validator.isRequired && !formData[field._id]) {
        return false;
      }
    }
    return true;
  };

  const validateEntireDataSet = (): boolean => {
    if (!instanceToEdit?.sectionStatus) {
      return false;
    }

    const mapKeys = instanceToEdit.parentTask.map.fields;
    const sectionStatus = instanceToEdit.sectionStatus;

    if (Object.keys(sectionStatus).length !== mapKeys.length) {
      setCanCloseOut(false);
      return false;
    }
    for (const status of Object.values(sectionStatus)) {
      if (status !== "complete") {
        setCanCloseOut(false);
        return false;
      }
    }
    setCanCloseOut(true);
    return true;
  };

  const onSubmit = async (data: any) => {
    if (!instanceToEdit) return;

    try {
      const isNotFailed = validateDataForFail(data);
      const isNotIncomplete = validateForCompletion(data);
      const updatedStatus = isNotFailed
        ? isNotIncomplete
          ? "complete"
          : "incomplete"
        : "failed";
      const formattedData = data;
      const uploadForm = new FormData();
      uploadForm.append("facility", selectedFacility);
      uploadForm.append("status", updatedStatus);
      uploadForm.append("sectionStatus", updatedStatus);
      uploadForm.append(
        "sectionsLength",
        instanceToEdit.parentTask.map.fields.length.toString()
      );
      uploadForm.append("customInput", JSON.stringify(formattedData));
      uploadForm.append("parentDate", instanceToEdit.date);

      files.forEach((file) => {
        uploadForm.append("files", file);
      });

      const response = await apiClient.post(
        `/recurring-tasks/update/${instanceToEdit.parentTask._id}/${
          instanceToEdit.childId || "createId"
        }`,
        uploadForm,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      setInstanceToEdit(response.data);
      showResponse(
        "Task Saved",
        "Data has been successfully saved",
        <CheckCircleIcon className="h-6 w-6 text-accent-500" />
      );
    } catch (error) {
      showResponse(
        "Error",
        "There was an error updating the task",
        <ExclamationCircleIcon className="h-6 w-6 text-reds-500" />
      );
      console.error("Error updating task:", error);
    }
  };

  const handleCloseOut = async () => {
    try {
      if (!instanceToEdit) return;
      const parentId = instanceToEdit.parentTask._id;
      const childId = instanceToEdit.childId;
      if (!parentId || !childId) return;
      await closeOutTaskInstance(parentId, childId);

      fetchRecurringTasks();
      navigate(`/tasks?${searchParams}`);
      setInstanceToEdit(null);
      showResponse(
        "Task Closed",
        "Task has been successfully closed",
        <CheckBadgeIcon className="h-6 w-6 text-accent-500" />
      );
    } catch (error) {
      showResponse(
        "Error",
        "There was an error closing out the task",
        <ExclamationCircleIcon className="h-6 w-6 text-reds-500" />
      );
      console.error("Error closing out task:", error);
    }
  };

  return (
    <RecurringTaskContext.Provider
      value={{
        recurringTasks,
        users,
        totalPages,
        isLoading,
        showState,
        updateShowState,
        filters: filterObject,
        handleResetFilters,
        applyFilters,
        handlePageSizeChange,
        handlePageChange,
        handleLogInstanceView,
        instanceToEdit,
        setInstanceToEdit,
        view,
        currentPage,
        tasksPerPage,
        navigateBack,
        files,
        setFiles,
        handleViewFile,
        handleTaskAssignment,
        onSubmit,
        handleCloseOut,
        closeOutValidation: validateEntireDataSet,
        canCloseOut,
      }}
    >
      {children}
    </RecurringTaskContext.Provider>
  );
};
