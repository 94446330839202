import React, { useEffect, useState } from "react";
import { useFormContext, useFieldArray, useWatch } from "react-hook-form";
import Input from "../updatedcomponents/general-ui/Input";
import { OnboardingFormValues } from "./OnboardingSchema";
import Button from "../updatedcomponents/general/Button";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import {
  AtSymbolIcon,
  EnvelopeIcon,
  KeyIcon,
  PhoneIcon,
} from "@heroicons/react/24/outline";

export type DataType =
  | "text"
  | "number"
  | "email"
  | "password"
  | "date"
  | "textarea"
  | "select"
  | "radio"
  | "radio-na"
  | "checkbox"
  | "switch"
  | "file";

type InputType = {
  id: string;
  label: string;
  type: DataType;
  placeholder: string;
  options?: { value: string; label: string }[];
};

const UserInformationStep: React.FC = () => {
  const inputs: InputType[] = [
    {
      id: "firstname",
      label: "First Name",
      type: "text",
      placeholder: "First Name",
    },
    {
      id: "lastname",
      label: "Last Name",
      type: "text",
      placeholder: "Last Name",
    },
    {
      id: "email",
      label: "Email",
      type: "text",
      placeholder: "email@example.com",
    },
    {
      id: "phone",
      label: "Phone",
      type: "text",
      placeholder: "(123) 456-7890",
    },
    {
      id: "access",
      label: "Access Level",
      type: "select",
      placeholder: "Select Access Level",
      options: [
        { value: "worker", label: "Worker" },
        { value: "regional", label: "Regional" },
        { value: "director", label: "Director" },
        { value: "administrator", label: "Administrator" },
      ],
    },
  ];

  const {
    control,
    register,
    formState: { errors },
  } = useFormContext<OnboardingFormValues>();

  const { fields, append, remove } = useFieldArray({
    control,
    name: "users", // Name of the dynamic field array in the form
  });

  const [showIndex, setShowIndex] = useState<number | null>(0);

  const toggleCollapse = (index: number) => {
    setShowIndex((prev) => (prev === index ? null : index));
  };
  const userFields = useWatch({
    control,
    name: `users`,
  });

  return (
    <div className="animate-fade-in-right">
      {errors.users && <p className="text-reds-500">{errors.users.message}</p>}
      {fields.map((field, index) => {
        return (
          <div
            key={field.id}
            className={`border ${
              errors.users?.[index]
                ? "border-reds-500"
                : "border-secondary-1000"
            } p-4 mb-4 rounded-md shadow-sm`}
          >
            <h3 className="text-md flex justify-between items-center">
              {showIndex !== index ? (
                <div className="flex flex-col">
                  <span className="text-md text-secondary-100">
                    {userFields[index]?.firstname
                      ? `${userFields[index]?.firstname} ${userFields[index]?.lastname}`
                      : "New User"}
                  </span>
                  <span className="text-secondary-500 text-sm flex items center gap-2">
                    <EnvelopeIcon className="h-4 w-4" />
                    {userFields[index]?.email
                      ? `${userFields[index]?.email}`
                      : ""}
                  </span>
                  <span className="text-secondary-500 text-sm flex items center gap-2">
                    <PhoneIcon className="h-4 w-4" />
                    {userFields[index]?.phone
                      ? `${userFields[index]?.phone}`
                      : ""}
                  </span>
                  <span className="text-secondary-500 text-sm flex items center gap-2">
                    <KeyIcon className="h-4 w-4" />
                    {userFields[index]?.access
                      ? `${userFields[index]?.access}`
                      : ""}
                  </span>
                </div>
              ) : (
                <div></div>
              )}
              <button
                type="button"
                onClick={() => toggleCollapse(index)}
                className="text-secondary-500"
              >
                {showIndex !== index ? <ExpandMore /> : <ExpandLess />}
              </button>
            </h3>
            {showIndex === index && (
              <>
                {inputs.map((input) => (
                  <div key={input.id} className="mt-2">
                    <Input
                      key={input.id}
                      type={input.type}
                      showLabel={false}
                      label={input.label}
                      register={register}
                      name={`users.${index}.${input.id}`}
                      options={input.options}
                      placeholder={input.placeholder}
                      errors={
                        errors.users?.[index]?.[
                          input.id as keyof (typeof errors.users)[number]
                        ] as unknown as string
                      }
                    />
                  </div>
                ))}
                <div className="mt-2">
                  <Button
                    type="button"
                    onClick={() => remove(index)}
                    styleString="danger"
                    children="Remove"
                    icon="Remove"
                  />
                </div>
              </>
            )}
          </div>
        );
      })}
      <Button
        type="button"
        onClick={() => {
          append({
            firstname: "",
            lastname: "",
            email: "",
            phone: "",
            access: "",
          });
          setShowIndex(fields.length);
        }}
        styleString="minimal"
        children="Add User"
        icon="Add"
      />
    </div>
  );
};

export default UserInformationStep;
