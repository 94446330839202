import React, { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import Input from "../updatedcomponents/general-ui/Input";
import { OnboardingFormValues } from "./OnboardingSchema";

const FacilityInformationStep: React.FC = () => {
  const {
    register,
    formState: { errors },
  } = useFormContext<OnboardingFormValues>();

  return (
    <>
      <Input
        type="text"
        label="Facility Name"
        register={register}
        name="facility.name"
        placeholder="Enter Facility Name"
        errors={errors.facility?.name}
      />
      <Input
        type="text"
        label="Facility Address"
        register={register}
        name="facility.address"
        placeholder="Enter Address"
        errors={errors.facility?.address}
      />
    </>
  );
};

export default FacilityInformationStep;
