import React from "react";
import PageHeader from "../../updatedcomponents/general-ui/PageHeader";
import Button from "../../updatedcomponents/general/Button";
import { formatDate } from "../../utils/FormatFunctions";
import { useNavigate } from "react-router-dom";
import { useManageOnboarding } from "../../hooks/useManageOnboarding";

const ManageOnboarding = () => {
  const { forms, handleNavigateBack, handleViewForm, handleNewForm } =
    useManageOnboarding();
  const navigate = useNavigate();

  return (
    <div className="flex flex-col">
      <PageHeader
        title="Manage Onboarding"
        buttons={
          <Button children="Create Form" onClick={handleNewForm} />
        }
      />
      {forms.map((form) => (
        <div
          key={form._id}
          className="flex justify-between forms-center p-4 border-b border-secondary-1000 hover:bg-secondary-1200 cursor-pointer"
          onClick={() => handleViewForm(form._id)}
        >
          <div className="flex flex-col">
            <span className="font-light text-sm">{form._id}</span>
            <span>{form.facility.name || "Not Yet Entered"}</span>
          </div>
          <div className="flex flex-col text-sm text-secondary-500 text-end">
            <span className="font-medium">
              {form.progress.completedSections.length}/6
            </span>
            <span>
              {formatDate(form.progress.lastSavedAt, "YYYY-MM-DD hh:mm a")}
            </span>
          </div>
        </div>
      ))}
    </div>
  );
};

export default ManageOnboarding;
