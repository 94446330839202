import React, { useEffect, useRef, useState } from "react";
import {
  FormProvider,
  useForm,
  SubmitHandler,
  set,
  useWatch,
} from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import FacilityInformationStep from "./FacilityInformation";
import UserInformationStep from "./UserInformation";
import KioskInformationStep from "./KioskInformation";
import MapInformationStep from "./MapInformation";
import TaskSelectionStep from "./TaskSelection";
import ProgressIndicator from "./ProgressIndicator";
import {
  OnboardingFormValues,
  welcomeSchema,
  facilitySchema,
  usersSchema,
  kiosksSchema,
  mapsSchema,
  tasksSchema,
  finalSchema,
} from "./OnboardingSchema";
import Button from "../updatedcomponents/general/Button";
import { TaskTemplate } from "../types/TaskTemplate";
import { fetchTemplates } from "../api/services/RecurringTaskService";
import Welcome from "./Welcome";
import Complete from "./Complete";
import { useParams } from "react-router-dom";
// import useAutosave from './hooks/useAutosave';
import apiClient from "../api/apiClient";
import { debounce } from "../utils/Helpers";
import { formatDate } from "../utils/FormatFunctions";
import Summary from "./Summary";
import { Warning } from "@mui/icons-material";

const saveSection = async (
  sessionId: string,
  section: string,
  data: any,
  isComplete: boolean = false
): Promise<void> => {
  try {
    const response = await apiClient.post(
      `/onboarding/update/section/${sessionId}`,
      {
        section,
        data: JSON.stringify(data),
        isComplete,
      }
    );
    console.log("Section saved successfully:", response.data);
  } catch (error) {
    console.error("Error saving section:", error);
  }
};

const completeForm = async (sessionId: string) => {
  try {
    const response = await apiClient.post(`/onboarding/complete/${sessionId}`);
    console.log("Form completed successfully:", response.data);
  } catch (error) {
    console.error("Error completing form:", error);
  }
};

const fetchExistingFormData = async (sessionId: string) => {
  try {
    const response = await apiClient.get(`/onboarding/session/${sessionId}`);
    return response.data;
  } catch (error) {
    console.error("Error fetching existing form data:", error);
  }
};

interface Step {
  title: string;
  subtitle: string;
  time: string;
  key: string;
  completed: boolean;
}

const OnboardingForm: React.FC = () => {
  const { sessionId } = useParams();

  if (!sessionId) {
    return (
      <div className="bg-secondary-1100 h-screen flex items-center justify-center">
        <div className="bg-primary h-48 rounded p-4 flex flex-col text-center justify-center items-center">
          <Warning className="text-accent-500" fontSize="large" />
          <h2 className="text-lg">Session ID not found</h2>
          <p className="font-light text-sm">Please contact support!</p>
        </div>
      </div>
    );
  }

  const [currentStep, setCurrentStep] = useState(0);
  const [saveState, setSaveState] = useState<{
    lastSaved: null | string;
    saving: boolean;
  }>({
    lastSaved: null,
    saving: false,
  });

  const [steps, setSteps] = useState<Step[]>([
    {
      title: "Facility Information",
      subtitle: "Please provide the following information about the facility.",
      time: "2 min.",
      key: "facility",
      completed: false,
    },
    {
      title: "User Information",
      subtitle: "Please enter the information for each user below.",
      time: "2 min.",
      key: "users",
      completed: false,
    },
    {
      title: "Kiosk Information",
      subtitle:
        "Please enter all locations you would like to accept work order requests from. Kiosks are typically placed in offices, nursing stations, rehabs,and other common areas where staff can easily access them.",
      time: "2 min.",
      key: "kiosks",
      completed: false,
    },
    {
      title: "Maps",
      subtitle:
        "Please enter the location or a unique identifier for each item included in the maps below. If you do not have more than one item in the map you can skip the map item.",
      time: "2 min.",
      key: "maps",
      completed: false,
    },
    {
      title: "Task Selection",
      subtitle: "Please select a start date for each task below.",
      time: "2 min.",
      key: "tasks",
      completed: false,
    },
    {
      title: "Data Review",
      subtitle: "Review and verify all information below.",
      time: "",
      key: "review",
      completed: false,
    },
  ]);

  const schemas = [
    welcomeSchema,
    facilitySchema,
    usersSchema,
    kiosksSchema,
    mapsSchema,
    tasksSchema,
    finalSchema,
  ];

  const methods = useForm<OnboardingFormValues>({
    resolver: yupResolver(schemas[currentStep] as any),
    defaultValues: {
      facility: { name: "", address: "" },
      users: [
        {
          firstname: "",
          lastname: "",
          email: "",
          phone: "",
          access: "",
        },
      ],
      kiosks: [
        {
          location: "",
        },
      ],
      maps: [],
      tasks: [],
    },
    mode: "onChange",
  });
  const {
    watch,
    handleSubmit,
    getValues,
    reset,
    control,
    formState: { errors },
  } = methods;

  useEffect(() => {
    const fetchExistingData = async () => {
      if (!sessionId) {
        return;
      }

      const existingData = await fetchExistingFormData(sessionId);
      if (existingData) {
        reset(existingData.session);

        const completedSteps = existingData.session.progress.completedSections;

        setSteps((prevSteps) => {
          return prevSteps.map((step) => ({
            ...step,
            completed: completedSteps.includes(step.key),
          }));
        });

        const lastSaved = existingData.session.progress.lastSavedAt;
        setSaveState({
          lastSaved,
          saving: false,
        });
        const lastActiveSection =
          existingData.session.progress.lastActiveSection;

        if (lastActiveSection) {
          const currentStepIndex = steps.findIndex(
            (step) => step.key === lastActiveSection
          );
          console.log("currentStepIndex", currentStepIndex);
          setCurrentStep(currentStepIndex + 1);
        }
      }
    };

    fetchExistingData();
  }, [sessionId]);

  // Autosave Integration
  const isInitialRender = useRef(true);
  useEffect(() => {
    const debouncedSave = debounce(
      async (sectionData: any, section: string, sessionId: string) => {
        if (sessionId && section) {
          await saveSection(sessionId, section, sectionData);
          setSaveState({
            lastSaved: new Date().toISOString(),
            saving: false,
          });
        }
      },
      500
    );

    const subscription = watch((data, { type }) => {
      if (isInitialRender.current) {
        // Skip saving on the initial render
        isInitialRender.current = false;
        return;
      }

      if (type !== "change") return;

      const currentSection = ["facility", "users", "kiosks", "maps", "tasks"][
        currentStep - 1
      ];

      if (currentSection) {
        setSaveState({
          lastSaved: null,
          saving: true,
        });

        debouncedSave(
          (data as OnboardingFormValues)[
            currentSection as keyof OnboardingFormValues
          ],
          currentSection,
          sessionId || ""
        );
      }
    });

    return () => subscription.unsubscribe();
  }, [currentStep, sessionId]);

  // Manual save
  const handleManualSave = async () => {
    const currentSectionData = getValues();
    const currentSection = ["facility", "users", "kiosks", "maps", "tasks"][
      currentStep - 1
    ];

    if (sessionId && currentSection) {
      setSaveState({
        lastSaved: null,
        saving: true,
      });
      await saveSection(
        sessionId,
        currentSection,
        currentSectionData[currentSection as keyof OnboardingFormValues]
      );

      setSaveState({
        lastSaved: new Date().toISOString(),
        saving: false,
      });
    }
  };

  // Navigation Logic
  const goToNextStep = async () => {
    const isValid = await methods.trigger();
    if (isValid) {
      const currentSectionData = getValues();
      const currentSection = ["facility", "users", "kiosks", "maps", "tasks"][
        currentStep - 1
      ];

      // Save immediately before navigating
      if (sessionId && currentSection) {
        await saveSection(
          sessionId,
          currentSection,
          currentSectionData[currentSection as keyof OnboardingFormValues],
          true
        );

        // Update the progress indicator
        setSteps((prevSteps) => {
          return prevSteps.map((step, index) => {
            if (index === currentStep - 1) {
              return {
                ...step,
                completed: true,
              };
            }
            return step;
          });
        });
      }
      setCurrentStep((prev) => prev + 1);
    }
  };

  const goToPreviousStep = async () => {
    setCurrentStep((prev) => Math.max(prev - 1, 0));
  };

  const jumpToStep = (index: number) => {
    setCurrentStep(index);
  };

  const onSubmit: SubmitHandler<OnboardingFormValues> = async () => {
    await completeForm(sessionId || "");
    setCurrentStep(currentStep + 1);
  };

  const facility = useWatch({
    control,
    name: `facility`,
  });

  const containerRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.scrollTop = 0;
    }
  }, [currentStep]);

  const [errorSteps, setErrorSteps] = useState<number[] | null>(null);
  useEffect(() => {
    const errorSteps = Object.keys(errors).map((key) => {
      return steps.findIndex((step) => step.key === key) + 1;
    });
    setErrorSteps(errorSteps);
    console.log("errorSteps", errorSteps);
  }, [errors]);

  return (
    <FormProvider {...methods}>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="flex flex-col items-center h-screen bg-secondary-1100"
      >
        <img
          src="/sequra_logo__primary_black.png"
          alt="logo"
          className="w-auto h-8 my-4"
        />
        {currentStep > 0 && currentStep <= 6 && (
          <ProgressIndicator
            steps={steps}
            currentStep={currentStep}
            onStepClick={jumpToStep}
            errorSteps={errorSteps}
          />
        )}
        <div
          ref={containerRef}
          className="relative bg-primary px-4 pb-4 rounded w-full max-w-sm  sm:max-w-2xl mt-4 mb-12 overflow-y-auto overflow-x-hidden scrollbar-thin"
        >
          {currentStep > 0 && currentStep <= 6 && (
            <>
              <h2 className="sticky bg-primary top-0 text-lg flex flex-col sm:flex-row w-full justify-between border-b py-2">
                <div>
                  {steps[currentStep - 1].title}
                  {facility && (
                    <p className="text-sm font-light">{facility.name}</p>
                  )}
                </div>
                {saveState.lastSaved ? (
                  <span className="text-xs text-secondary-600">
                    Last saved:{" "}
                    {formatDate(saveState.lastSaved, "MMM dd, yyyy h:mm a")}
                  </span>
                ) : (
                  <>
                    {/* Spinning saving icon */}
                    <span className="text-xs text-secondary-600 flex items-center">
                      <svg
                        className="animate-spin h-4 w-4 mr-2 text-secondary-600"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <circle
                          className="opacity-25"
                          cx="12"
                          cy="12"
                          r="10"
                          stroke="currentColor"
                          strokeWidth="4"
                        ></circle>
                        <path
                          className="opacity-75"
                          fill="currentColor"
                          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                        ></path>
                      </svg>
                      Saving...
                    </span>
                  </>
                )}
              </h2>
              <p className="text-sm font-light pb-4">
                {steps[currentStep - 1].subtitle}
              </p>
            </>
          )}
          {currentStep === 0 && <Welcome />}
          {currentStep === 1 && <FacilityInformationStep />}
          {currentStep === 2 && <UserInformationStep />}
          {currentStep === 3 && <KioskInformationStep />}
          {currentStep === 4 && (
            <MapInformationStep handleSave={handleManualSave} />
          )}
          {currentStep === 5 && (
            <TaskSelectionStep handleSave={handleManualSave} />
          )}
          {currentStep === 6 && <Summary navigate={jumpToStep} />}
          {currentStep === 7 && <Complete />}
          <div className="flex w-full justify-center gap-2 mt-6">
            {currentStep > 1 && currentStep < steps.length && (
              <Button
                type="button"
                styleString="secondary"
                onClick={goToPreviousStep}
                disabled={currentStep === 1}
                children="Previous"
                icon="ArrowBackIos"
              />
            )}
            {currentStep < steps.length && (
              <Button
                type="button"
                styleString="primary"
                onClick={goToNextStep}
                children={currentStep !== 0 ? "Complete" : "Begin"}
                icon={currentStep === 0 ? "ArrowForwardIos" : "ArrowForwardIos"}
                reverse={true}
              />
            )}
            {currentStep === steps.length && (
              <Button type="submit" styleString="bold" children="Submit" />
            )}
          </div>
        </div>
      </form>
    </FormProvider>
  );
};

export default OnboardingForm;
