import {
  createContext,
  useCallback,
  useState,
  ReactNode,
  useEffect,
} from "react";
import {
  SetURLSearchParams,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { WorkOrder, WorkOrderStatus } from "../types/WorkOrder";
import { User } from "../types/User";
import { useFacility } from "../hooks/useFacility";
import { updateSearchParams } from "../utils/ParamUtils";
import { useAuth } from "../hooks/useAuth";
import { Kiosk } from "../types/Kiosk";
import {
  createNote,
  createWorkOrder,
  fetchFacilityKiosks,
  fetchFacilityUsers,
  fetchNextWorkOrder,
  fetchWorkOrder,
  fetchWorkOrders,
  getImages,
  handleWorkOrderAssignment,
  handleWorkOrderDelete,
  handleWorkOrderStatusUpdate,
} from "../api/services/WorkOrderService";
import { debounce } from "../utils/Helpers";
import { CheckCircleIcon } from "@heroicons/react/20/solid";
import { useToast } from "../hooks/useToast";
import { ExclamationCircleIcon } from "@heroicons/react/24/outline";

interface PageSizeChangeProps {
  pageSize: string;
  page: number;
}
interface UnableNotes {
  note: string;
}
// Define the type for the context values
interface WorkOrderContextType {
  workOrders: WorkOrder[];
  totalPages: number;
  isLoading: boolean;
  searchParams: URLSearchParams;
  updateSearchParams: (
    setSearchParams: SetURLSearchParams,
    params: object
  ) => void;
  selectedWorkOrder: WorkOrder | null;
  setSelectedWorkOrder: React.Dispatch<React.SetStateAction<WorkOrder | null>>;
  updateWorkOrderInContext: (updatedWorkOrder: WorkOrder) => void;
  fetchAdjacentWorkOrder: (direction: string) => Promise<void>;
  createWorkOrder: (formData: FormData) => void;
  handleAssignment: (workOrder: WorkOrder, selectedUser: string | null) => void;
  handleWorkOrderStatusChange: (
    event: React.FormEvent | null,
    workOrder: WorkOrder | null,
    status: WorkOrderStatus
  ) => void;
  handleMarkUnable: (data: UnableNotes) => void;
  handleAddNotes: (data: { noteText: string }) => void;
  handleDelete: () => void;
  handleReopen: () => void;
  pageSize: string;
  page: string;
  handlePageSizeChange: ({ pageSize, page }: PageSizeChangeProps) => void;
  handlePageChange: (newPage: number) => void;
  handleView: (workOrderId: string) => void;
  filters: string;
  applyFilters: (filters: Record<string, string>) => void;
  resetFilters: () => void;
  users: User[];
  kiosks: Kiosk[];
  images: string[];
  handleSort: (column: string) => void;
  sortColumn: string;
  sortOrder: string;
  modalShowState: {
    showUnable: boolean;
    showCloseout: boolean;
    showReopen: boolean;
    showDelete: boolean;
    showNewWorkOrder: boolean;
    showPrintWorkOrders: boolean;
    showFilterModal: boolean;
    showNotes: boolean;
  };
  updateModalShowState: (modalName: string, show: boolean) => void;
}

// Define the type for the provider props
interface WorkOrderProviderProps {
  children: ReactNode;
}

// Create the context
export const WorkOrderContext = createContext<WorkOrderContextType | undefined>(
  undefined
);

// Create the provider component
export const WorkOrderProvider = ({ children }: WorkOrderProviderProps) => {
  const { selectedFacility } = useFacility();
  const { currentUser } = useAuth();
  const { showResponse } = useToast();
  const [searchParams, setSearchParams] = useSearchParams();

  const filters = searchParams.get("filters") || "";
  const page = searchParams.get("page") || "1";
  const pageSize = searchParams.get("pageSize") || "10";
  const sortColumn = searchParams.get("sortColumn") || "dateReported";
  const sortOrder = searchParams.get("sortOrder") || "1";

  const [workOrders, setWorkOrders] = useState<WorkOrder[]>([]);
  const [selectedWorkOrder, setSelectedWorkOrder] = useState<WorkOrder | null>(
    null
  );

  const [modalShowState, setModalShowState] = useState({
    showUnable: false,
    showCloseout: false,
    showReopen: false,
    showDelete: false,
    showNewWorkOrder: false,
    showPrintWorkOrders: false,
    showFilterModal: false,
    showNotes: false,
  });
  const updateModalShowState = (modalName: string, show: boolean) => {
    setModalShowState((prevState) => ({
      ...prevState,
      [modalName]: show,
    }));
  };

  const [totalPages, setTotalPages] = useState<number>(1);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const loadWorkOrders = useCallback(
    debounce(async () => {
      try {
        setIsLoading(true);
        const filterObject = filters ? JSON.parse(filters) : {};

        if (!selectedFacility) {
          return;
        }

        const queryString = new URLSearchParams({
          ...filterObject,
          page,
          pageSize,
          sortColumn,
          sortOrder,
        }).toString();
        const result = await fetchWorkOrders(queryString, selectedFacility);

        if (result) {
          const { workOrders, totalPages } = result;
          setWorkOrders(workOrders || []);
          setTotalPages(totalPages);
        } else {
          setWorkOrders([]);
          setTotalPages(1);
        }
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching work orders:", error);
        setIsLoading(false);
      }
    }, 300),
    [searchParams, selectedFacility]
  );

  const [users, setUsers] = useState([]);

  const fetchUsers = useCallback(async () => {
    if (!selectedFacility) return;
    const returnedUsers = await fetchFacilityUsers(selectedFacility);
    setUsers(returnedUsers);
  }, [selectedFacility]);

  const [kiosks, setKiosks] = useState([]);
  const fetchKiosks = useCallback(async () => {
    if (!selectedFacility) return;
    const returnedKiosks = await fetchFacilityKiosks(selectedFacility);
    setKiosks(returnedKiosks);
  }, [selectedFacility]);

  const fetchAdjacentWorkOrder = useCallback(
    async (direction: string) => {
      if (!selectedWorkOrder) return;
      const workOrderId = selectedWorkOrder._id;
      try {
        const filterObject = filters ? JSON.parse(filters) : {};
        const queryString = new URLSearchParams({
          workOrderId,
          direction,
          sortColumn,
          sortOrder,
          ...filterObject,
        }).toString();
        const adjacentWorkOrder = await fetchNextWorkOrder(
          queryString,
          selectedFacility
        );
        if (adjacentWorkOrder) {
          // setSelectedWorkOrder(adjacentWorkOrder);
          setIsLoading(false);
          navigate(
            `/work-orders/instance/${adjacentWorkOrder._id}?${searchParams}`
          );
        } else {
          return;
        }
      } catch (error: any) {
        console.error("Error loading work order", error.response);
      }
    },
    [selectedWorkOrder]
  );

  const onCreateWorkOrder = async (formData: FormData) => {
    await createWorkOrder(formData);
    loadWorkOrders();
    updateModalShowState("showNewWorkOrder", false);
    showResponse(
      "Work Order Created",
      "Work order has been successfully created",
      <CheckCircleIcon className="h-6 w-6 text-accent-500" />
    );
  };

  const handleAssignment = async (
    workOrder: WorkOrder,
    selectedUser: string | null
  ) => {
    const workOrderId = workOrder._id;
    const userObject = users.find((user: User) => user._id === selectedUser);
    try {
      await handleWorkOrderAssignment(
        workOrderId,
        selectedUser ? selectedUser : "unassign",
        currentUser._id
      );

      const updatedWorkOrder = {
        ...workOrder,
        assignedTo: userObject,
      };

      updateWorkOrderInContext(updatedWorkOrder);
      showResponse(
        "Work Order Assigned",
        "Work order has been successfully assigned",
        <CheckCircleIcon className="h-6 w-6 text-accent-500" />
      );
    } catch (error) {
      showResponse(
        "Error",
        "There was an error assigning the work order",
        <ExclamationCircleIcon className="h-6 w-6 text-reds-500" />
      );
      console.error("Error assigning work order:", error);
    }
  };

  const handleAddNotes = async (data: { noteText: string }) => {
    const { noteText } = data;
    if (!selectedWorkOrder) {
      return;
    }
    try {
      const postedNote = await createNote(
        selectedWorkOrder._id,
        noteText,
        currentUser._id
      );
      setSelectedWorkOrder((prevWorkOrder) => {
        if (!prevWorkOrder) return null;
        return {
          ...prevWorkOrder,
          notes: [...prevWorkOrder.notes, postedNote],
        };
      });
      updateModalShowState("showNotes", false);
      showResponse(
        "Note Added",
        "Note has been successfully added",
        <CheckCircleIcon className="h-6 w-6 text-accent-500" />
      );
    } catch (error) {
      showResponse(
        "Error",
        "There was an error adding the note",
        <ExclamationCircleIcon className="h-6 w-6 text-reds-500" />
      );
      console.error("Error adding note:", error);
    }
  };

  const updateWorkOrderInContext = (updatedWorkOrder: WorkOrder) => {
    setWorkOrders((prevWorkOrders) =>
      prevWorkOrders.map((wo) =>
        wo._id === updatedWorkOrder._id ? updatedWorkOrder : wo
      )
    );
    setSelectedWorkOrder(updatedWorkOrder);
  };

  const handlePageSizeChange = ({ pageSize, page }: PageSizeChangeProps) => {
    updateSearchParams(setSearchParams, { pageSize, page: page.toString() });
  };

  const handlePageChange = (newPage: number) => {
    updateSearchParams(setSearchParams, { page: newPage.toString() });
  };

  // Function to handle sorting
  const handleSort = (column: string) => {
    if (column === sortColumn) {
      // If the same column is clicked again, toggle the sort direction
      updateSearchParams(setSearchParams, {
        sortOrder: -sortOrder,
        sortColumn: column,
      });
    } else {
      // If a different column is clicked, set it as the new sort column and default to ascending order
      updateSearchParams(setSearchParams, { sortOrder: 1, sortColumn: column });
    }
  };

  const navigate = useNavigate();

  const handleView = async (workOrderId: string) => {
    if (workOrderId) {
      setIsLoading(true);
      const returnedWorkOrder = await fetchWorkOrder(workOrderId);
      setSelectedWorkOrder(returnedWorkOrder);
      setIsLoading(false);
      navigate(`/work-orders/instance/${workOrderId}?${searchParams}`);
    } else {
      console.error("Error viewing work order: work order not, ", workOrderId);
    }
  };
  const { id } = useParams();
  useEffect(() => {
    if (id) {
      handleView(id);
    }
  }, [id]);

  const handleWorkOrderStatusChange = async (
    event: React.FormEvent | null,
    workOrder: WorkOrder | null,
    status: WorkOrderStatus
  ) => {
    if (event) {
      event.preventDefault();
    }

    if (!workOrder) {
      console.error("No work order selected");
      return;
    }

    const workOrderId = workOrder._id;

    try {
      if (status === "unable") {
        updateModalShowState("showUnable", true);
      } else {
        console.log("Marking work order as", status);
        await handleWorkOrderStatusUpdate(workOrderId, status, currentUser._id);
        const updatedWorkOrder = {
          ...workOrder,
          status: status,
          history: [
            ...workOrder.history,
            {
              completedBy: currentUser,
              timestamp: new Date(),
              action: status,
            },
          ],
        };
        updateWorkOrderInContext(updatedWorkOrder);
        setModalShowState({
          showUnable: false,
          showCloseout: false,
          showReopen: false,
          showDelete: false,
          showNewWorkOrder: false,
          showPrintWorkOrders: false,
          showFilterModal: false,
          showNotes: false,
        });
        loadWorkOrders();
        showResponse(
          "Work Order Updated",
          "Work order has been successfully updated",
          <CheckCircleIcon className="h-6 w-6 text-accent-500" />
        );
      }
    } catch (error) {
      showResponse(
        "Error",
        "There was an error updating the work order",
        <ExclamationCircleIcon className="h-6 w-6 text-reds-500" />
      );
      console.error(`Error marking work order as ${status}:`, error);
    }
  };

  const handleMarkUnable = async (data: UnableNotes) => {
    const { note } = data;
    try {
      if (!selectedWorkOrder) {
        console.error("No work order selected");
        return;
      }
      const workOrderId = selectedWorkOrder._id;
      await handleWorkOrderStatusUpdate(
        workOrderId,
        "unable",
        currentUser._id,
        note
      );
      const updatedWorkOrder = {
        ...selectedWorkOrder,
        status: "unable" as WorkOrderStatus,
        history: [
          ...selectedWorkOrder.history,
          {
            completedBy: currentUser,
            timestamp: new Date(),
            action: "unable",
          },
        ],
        notes: [
          ...selectedWorkOrder.notes,
          { text: note, userId: currentUser, timestamp: new Date() },
        ],
      };
      updateWorkOrderInContext(updatedWorkOrder);
      updateModalShowState("showUnable", false);
      showResponse(
        "Work Order Updated",
        "Work order has been successfully updated",
        <CheckCircleIcon className="h-6 w-6 text-accent-500" />
      );
    } catch (error) {
      showResponse(
        "Error",
        "There was an error updating the work order",
        <ExclamationCircleIcon className="h-6 w-6 text-reds-500" />
      );
      console.error("Error marking work order as unable:", error);
    }
  };

  const handleDelete = async () => {
    if (!selectedWorkOrder) {
      return;
    }
    try {
      const workOrderId = selectedWorkOrder._id;
      await handleWorkOrderDelete(workOrderId);
      const updatedWorkOrders = workOrders.filter(
        (workOrder) => workOrder._id !== workOrderId
      );
      setWorkOrders(updatedWorkOrders);
      navigate(`/work-orders${searchParams}`);
      showResponse(
        "Work Order Deleted",
        "Work order has been successfully deleted",
        <CheckCircleIcon className="h-6 w-6 text-reds-500" />
      );
    } catch (error) {
      showResponse(
        "Error",
        "There was an error deleting the work order",
        <ExclamationCircleIcon className="h-6 w-6 text-reds-500" />
      );
      console.error("Error deleting work order:", error);
    }
  };

  const applyFilters = (filters: Record<string, string>) => {
    updateSearchParams(setSearchParams, {
      filters: JSON.stringify(filters),
      page: "1",
    });
  };

  const resetFilters = () => {
    updateSearchParams(setSearchParams, {
      filters: "",
      page: "1",
    });
  };

  const filterObject = searchParams.get("filters")
    ? JSON.parse(searchParams.get("filters")!)
    : {};

  const [images, setImages] = useState([]);

  useEffect(() => {
    const keys = selectedWorkOrder?.attachments?.map((url) =>
      new URL(url).pathname.substring(1)
    );
    async function fetchImages(keys: string[]) {
      try {
        if (!keys) {
          setImages([]);
          return;
        }
        const queryString = new URLSearchParams({
          keys: keys.join(","),
        }).toString();

        const imageResponse = await getImages(queryString);

        setImages(imageResponse.map((image: any) => image.url));
      } catch (error) {
        console.error("Error fetching image URLs:", error);
      }
    }
    if (keys && keys.length > 0) {
      fetchImages(keys);
    } else {
      setImages([]);
    }
  }, [selectedWorkOrder]);

  // Initialize data
  useEffect(() => {
    if (!selectedFacility) return;
    loadWorkOrders();
    fetchUsers();
    fetchKiosks();
  }, [searchParams, selectedFacility]);

  return (
    <WorkOrderContext.Provider
      value={{
        workOrders,
        totalPages,
        isLoading,
        searchParams,
        updateSearchParams,
        selectedWorkOrder,
        setSelectedWorkOrder,
        updateWorkOrderInContext,
        fetchAdjacentWorkOrder,
        createWorkOrder: onCreateWorkOrder,
        handleAssignment,
        handleAddNotes,
        handleWorkOrderStatusChange,
        handleDelete,
        handleMarkUnable,
        handleReopen: () =>
          handleWorkOrderStatusChange(null, selectedWorkOrder, "incomplete"),
        pageSize,
        page,
        handlePageSizeChange,
        handlePageChange,
        handleView,
        filters: filterObject,
        applyFilters,
        resetFilters,
        images,
        users,
        kiosks,
        handleSort,
        sortColumn,
        sortOrder,
        modalShowState,
        updateModalShowState,
      }}
    >
      {children}
    </WorkOrderContext.Provider>
  );
};
