import apiClient from "../apiClient";

// Service for fetching all vendors
export const fetchVendors = async (facilityId: string, queryString: string) => {
  try {
    const response = await apiClient.get(
      `/vendor/list/${facilityId}?${queryString}`
    );
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const createVendor = async (data: object, selectedFacility: string) => {
  try {
    const response = await apiClient.post(
      `/vendor/create/${selectedFacility}`,
      data
    );
    console.log(response.data);
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const deleteVendor = async (vendorId: string) => {
  try {
    const response = await apiClient.delete(`/vendor/${vendorId}`);
    return response.data;
  } catch (error) {
    console.error(error);
  }
};
